<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-row :gutter="20">
      <el-col :md="12" class="flex items-center flex-wrap">
        <!-- product Name and Custom ID -->
        <el-form-item class="mb-0 flex-grow" prop="id">
          <th-input-title
            v-if="isLast"
            for="id"
            :title="
              $t(
                'pages.products.edit.form.sections.standard.product_name.label'
              )
            "
          />
          <remote-search-select
            id="id"
            class="w-full min-w-field"
            popper-append-to-body
            resource="products"
            :computed-fields="['custom_id', 'name']"
            :model-value="form.id"
            :clearable="false"
            :placeholder="
              $t('pages.products.edit.form.sections.linked.product.placeholder')
            "
            :modify-query="
              (q) => ({
                query: {
                  limit: 50,
                  deleted: false,
                  q,
                  exclude_system_products: true,
                  type: [
                    'product',
                    'composed_product',
                    'voucher',
                    'linked',
                    'linked_product',
                    'variant',
                    'variant_product'
                  ]
                }
              })
            "
            :result-filter="resultFilter"
            @resource-set="selectProductFromList"
          />
        </el-form-item>
      </el-col>

      <!-- quantity -->
      <el-col :md="4" :sm="12" :xs="12">
        <el-form-item class="mb-0" prop="qty">
          <th-input-title
            v-if="isLast"
            for="qty"
            :title="
              $t('pages.products.edit.form.sections.linked.quantity.label')
            "
          />
          <th-number-input
            id="qty"
            ref="thNumberInput"
            v-model="form.qty"
            :locale="$i18n.locale"
            :precision="3"
            :placeholder="
              $t('pages.products.edit.form.sections.linked.quantity.label')
            "
          />
        </el-form-item>
      </el-col>

      <el-col :md="4" :sm="12" :xs="12">
        <div class="flex items-center" :class="{ 'mt-th-input-title': isLast }">
          <!-- Delete -->
          <el-button
            plain
            icon="Delete"
            class="-ml-2 el-button--text-icon"
            :title="$t('common.interactions.buttons.remove')"
            @click="$emit('delete', index)"
          />

          <!-- Add -->
          <el-button
            v-if="isLast"
            plain
            class="el-button--primary-icon"
            icon="Plus"
            :title="$t('common.interactions.buttons.add')"
            @click="$emit('add')"
          />
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      required: true
    },
    isLast: {
      type: Boolean,
      required: false
    },
    usedProductIds: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isSubmitting: false,
      rules: {
        id: [
          {
            validator: (rule, value, callback) => {
              if (!this.isSubmitting) return callback()

              if (!value) {
                callback(
                  new Error(
                    this.$t('common.forms.rules.field_warnings.required')
                  )
                )
              } else {
                callback()
              }
            }
          }
        ],
        qty: [{ validator: this.minQtyValidator, trigger: 'change' }]
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },
  methods: {
    resultFilter(results) {
      // filter all used items from results, except the current selected item
      const usedProducts = this.usedProductIds.filter(
        (id) => id !== this.form.id
      )
      return results.filter(({ id }) => !usedProducts.includes(id))
    },

    minQtyValidator(_, value, cb) {
      if (!this.form.id) return cb()
      const errorMessage = this.$t(
        'pages.products.edit.form.sections.linked.validation.valid_quantity'
      )
      if (!value || (Number.isFinite(value) && Number(value) < 1)) {
        return cb(new Error(errorMessage))
      }
      cb()
    },

    async selectProductFromList(product) {
      const isDifferentProductSelected = this.form.id !== product?.id
      if (isDifferentProductSelected) {
        this.form = { ...this.form, id: product?.id }
      }
    },

    async validate() {
      // since it's a form inside a form, we want to validate the linked product form only before submitting, and not while interacting with the fields.
      this.isSubmitting = true
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
        this.isSubmitting = false
      })
    }
  }
}
</script>
