<template>
  <th-wrapper
    :title="$t('pages.products.edit.form.sections.composed.title')"
    :info="$t('pages.products.edit.form.sections.composed.help')"
    collapsable
    @collapsed-changed="handleCollapsedChanged"
  >
    <!-- header -->
    <el-row :gutter="20">
      <!-- Items -->
      <el-col :md="8">
        <th-input-title
          :title="$t('components.th_datatable.pagination.items')"
        />
      </el-col>

      <!-- Quantity -->
      <el-col :md="4" :sm="12" :xs="12">
        <th-input-title :title="$t('pages.audits.all.table.quantity')" />
      </el-col>

      <!-- Purchase price -->
      <el-col :lg="4" :md="4" :sm="12" :xs="12">
        <th-input-title
          :title="
            $t('pages.products.edit.form.pricing.common.headers.purchase_price')
          "
        />
      </el-col>

      <!-- Gross price -->
      <el-col :lg="4" :md="4" :sm="12" :xs="12">
        <th-input-title
          :title="$t('pages.products.edit.form.pricing.common.headers.gross')"
        />
      </el-col>
    </el-row>

    <!-- Rows -->
    <div
      v-for="(item, index) in form.components"
      :key="index"
      class="mb-4 last:mb-0"
    >
      <composed-row
        :ref="'form-' + index"
        :model-value="item"
        :index="index"
        :is-last="index === form.components.length - 1"
        @add="handleAdd"
        @delete="handleDelete(index)"
        @recalculate="recalculate"
      />
    </div>
  </th-wrapper>
</template>

<script>
import ComposedRow from './composed/composed-row'

export default {
  components: {
    ComposedRow
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },

  watch: {
    'form.components'() {
      this.recalculate()
    }
  },

  mounted() {
    if (!this.form.components) this.form.components = []
    if (!this.form.components.length) this.handleAdd()
    this.recalculate()
  },

  methods: {
    recalculate() {
      // Sum total purchase prices
      const totalPurchasePrice = this.form.components
        .map((v) => v.total_purchase_price)
        .reduce((a, b) => a + b)
      // Sum total gross prices
      const totalGrossPrice = this.form.components
        .map((v) => v.total_gross_price)
        .reduce((a, b) => a + b)
      // Emit to parent
      this.$emit('computed-product-totals', {
        totalPurchasePrice,
        totalGrossPrice
      })
    },

    handleAdd() {
      this.form.components.push({ product: null, quantity: null })
    },

    handleDelete(index) {
      this.form.components.splice(index, 1)
      if (!this.form.components.length) this.handleAdd()
    },

    async validate() {
      // Validate all composed items rows
      let valid = true
      for (const key in this.$refs) {
        if (key.includes('form') && this.$refs[key].length > 0) {
          if (!(await this.$refs[key][0].validate())) valid = false
        }
      }
      return valid
    },
    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.composed.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>
