<template>
  <th-wrapper
    collapsable
    collapsed-by-default
    data-testid="price-books"
    :title="$t('pages.products.edit.form.sections.price-books.title')"
    @collapsed-changed="handleCollapsedChanged"
  >
    <template #subtitle>
      {{ $t('pages.products.edit.form.sections.price-books.subtitle') }}
      <a
        href="/products/pricebooks"
        target="_blank"
        class="text-th-secondary"
        >{{ $t('pages.products.edit.form.sections.price-books.link') }}</a
      >
    </template>

    <price-books-row
      v-for="(entry, index) in localForm"
      ref="entries"
      :key="entry.id"
      :model-value="entry"
      :index="index"
      :used-price-book-ids="usedPriceBookIds"
      :is-last="index === localForm.length - 1"
      :is-new="!isExistingEntry(entry)"
      :price-book-resource="priceBookResource"
      @update:modelValue="updateEntry(index, $event)"
      @add="addNewEntry"
      @delete="removeEntry"
    />
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import cloneDeep from 'clone-deep'
import pick from 'just-pick'
import { sortBy } from '@/utils/strings'
import { getDefaultCurrency } from '@/utils/general'
import PriceBooksRow from './price-books/price-books-row'

function generateDefault() {
  return {
    product: null,
    price_book: null,
    amount_gross: null,
    currency: getDefaultCurrency(),
    tax: null
  }
}

export default {
  components: { PriceBooksRow },

  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    fullProduct: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      localForm: [],
      deletedEntries: []
    }
  },

  computed: {
    priceBookResource() {
      return sortBy(this.resources?.price_books || [], 'name')
    },
    usedPriceBookIds() {
      return this.localForm.map(({ price_book }) => price_book)
    },
    fields() {
      return Object.keys(generateDefault())
    }
  },

  watch: {
    modelValue() {
      this.localForm = cloneDeep(this.modelValue)
      //if localForm is empty array, we add a new entry placeholder
      if (!this.localForm.length) {
        this.addNewEntry()
      }
    }
  },
  mounted() {
    this.addNewEntry()
  },
  methods: {
    isExistingEntry(entry) {
      return !!this.modelValue.find(
        ({ price_book }) => price_book === entry.price_book
      )
    },
    addNewEntry() {
      this.localForm.push(generateDefault())
    },
    async removeEntry(index) {
      const entry = this.localForm[index]
      if (this.isExistingEntry(entry)) {
        //if Entry needs to be deleted via the API, we save it temporary in deleteEntries until execution
        this.deletedEntries.push(entry)
      }
      this.localForm.splice(index, 1)

      //if localForm is empty array, we add a new entry placeholder
      if (!this.localForm.length) {
        this.addNewEntry()
      }
    },
    async save(productId) {
      //delete entries
      const deletedEntries = this.deletedEntries.map(this.deleteEntry)
      const savedEntries = this.localForm.map(async (entry) => {
        if (!entry.price_book) {
          return
        }
        //update entries
        if (this.isExistingEntry(entry)) {
          return this.updateEntry(entry)
        }
        //create entries
        return this.createEntry(productId || entry.product, entry)
      })

      return Promise.all([...deletedEntries, ...savedEntries])
    },
    async createEntry(productId, entry) {
      try {
        return await th
          .products()
          .pricebookEntries()
          .create({ ...entry, product: productId, tax: this.fullProduct.tax })
      } catch (error) {
        this.$logException(error)
      }
    },
    async updateEntry(entry) {
      try {
        return await th
          .products()
          .pricebookEntries()
          .put(entry.id, {
            ...pick(entry, this.fields),
            product: this.fullProduct.id
          })
      } catch (error) {
        this.$logException(error)
      }
    },
    async deleteEntry(entry) {
      try {
        return await th.products().pricebookEntries().delete(entry.id)
      } catch (error) {
        this.$logException(error)
      }
    },
    async validate() {
      const results = await Promise.all(
        this.$refs.entries.map((entry) => entry.validate())
      )
      return !results.some((item) => item === false)
    },
    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.price-books.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>
