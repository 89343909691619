// generate default form JSON structure base on the debug mode.
const defaultValues = () => ({
  active: true,
  stockable: true,
  type: 'product',
  name: null,
  account: null,
  takeaway_account: null,
  tax: null,
  takeaway_tax: null,
  custom_id: null,
  barcodes: null,
  brand: null,
  locations: null,
  branch_groups: null,
  product_group: null,
  images: {},
  linked_products: null,
  is_service: false,
  service_questions: null,
  prices: {
    default_prices: [],
    branch_prices: [],
    scaled_prices: [],
    time_based_prices: []
  },
  configuration: {
    pricing: {
      request_input: false,
      zero_input_allowed: false
    }
  },
  _initialStock: null,
  // Variants
  children: [],
  options: [{ name: '', values: [] }],
  parent: null,
  // Additional information
  summary: null,
  external_reference_id: null,
  default_tile_color: null,
  description: null,
  manufacturer: {
    iln: null
  },
  supplier: {
    sku: null
  },
  tags: [],
  sku: null,
  discountable: true,
  attributes: [],
  // Composed product
  components: undefined,
  price_book_entries: [],
  // Addon groups
  addon_groups: []
})

const globalDataModeValues = () => ({
  audiences: null,
  categories: null,
  category: null,
  client_id: null,
  condition: null,
  custom_ids: null,
  custom_properties: null,
  delegatable_to: null,
  delegatable: false,
  delegated_from: null,
  delegated_to: null,
  deleted: false,
  disclaimer: null,
  external_ids: null,
  keywords: null,
  loyalty_values: null,
  manufacturers: null,
  metadata: null,
  parent: null,
  produced_at: null,
  purchasable: true,
  purchased_at: null,
  refund_policy: null,
  related_to: null,
  released_at: null,
  season: null,
  seasons: null,
  sellable: true,
  serial_number_input_required: false,
  service_question_groups: null,
  similar_to: null,
  stock_maximum: null,
  taxes_options: null,
  vat_class: null,
  warranty_notice: null
})

export function generateDefault(hasGlobalDataMode) {
  //if hasGlobalDataMode the return json is extended
  return hasGlobalDataMode
    ? { ...defaultValues(), ...globalDataModeValues() }
    : defaultValues()
}
