<template>
  <el-form :model="form" class="-mb-4">
    <el-row>
      <el-col :lg="12">
        <div class="flex items-center">
          <div class="symbol">(</div>

          <!-- Purchase price -->
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.pricing.common.headers.purchase_price'
              )
            "
            prop="purchase_price"
            class="flex-grow"
          >
            <th-currency-input
              id="purchase_price"
              v-model="form.purchase_price"
              clearable-is-left
              :class="{ flashing: flashing.purchase_price }"
              :currency="form.currency"
              :disabled="isServiceProduct"
              @update:modelValue="handleChange('purchase_price', $event)"
            />
          </el-form-item>

          <div class="symbol">
            <el-icon><Plus /></el-icon>
          </div>

          <!-- Cost -->
          <el-form-item
            :label="$t('pages.products.edit.form.pricing.common.headers.cost')"
            prop="cost"
            class="flex-grow"
          >
            <th-currency-input
              id="cost"
              clearable-is-left
              :class="{ flashing: flashing.cost }"
              :currency="form.currency"
              :model-value="form.cost"
              @update:modelValue="handleChange('cost', $event)"
            />
          </el-form-item>

          <div class="symbol pr-0">)</div>
          <div class="symbol">
            <el-icon><Close /></el-icon>
          </div>

          <!-- Margin -->
          <el-form-item
            :label="
              $t('pages.products.edit.form.pricing.common.headers.margin')
            "
            prop="margin"
            class="flex-grow"
          >
            <el-tooltip
              :content="
                $t(
                  'pages.products.edit.form.pricing.calculation_modal.margin_disabled_tooltip'
                )
              "
              :disabled="!netFieldDisabled"
              :tabindex="-1"
            >
              <th-number-input
                id="margin"
                percent
                clearable-is-left
                placeholder="0.0%"
                :model-value="form.margin"
                :locale="$i18n.locale"
                :disabled="netFieldDisabled"
                :precision="2"
                :class="{ flashing: flashing.margin }"
                @update:modelValue="handleChange('margin', $event)"
              />
            </el-tooltip>
          </el-form-item>
        </div>
      </el-col>

      <el-col :lg="8">
        <div class="flex items-center">
          <!-- = -->
          <div class="symbol px-4">=</div>

          <!-- Net price -->
          <el-form-item
            :label="$t('pages.products.edit.form.pricing.common.headers.net')"
            prop="net"
            class="flex-grow"
          >
            <el-tooltip
              :content="
                $t(
                  'pages.products.edit.form.pricing.calculation_modal.margin_disabled_tooltip'
                )
              "
              :disabled="!netFieldDisabled"
              :tabindex="-1"
            >
              <th-currency-input
                id="net"
                clearable-is-left
                :class="{ flashing: flashing.net }"
                :currency="form.currency"
                :disabled="netFieldDisabled"
                :model-value="form.net"
                @update:modelValue="handleChange('net', $event)"
              />
            </el-tooltip>
          </el-form-item>

          <!-- x -->
          <div class="symbol">
            <el-icon><Close /></el-icon>
          </div>

          <!-- Tax -->
          <el-form-item
            :label="$t('pages.products.edit.form.sections.standard.tax.label')"
            prop="tax"
          >
            <el-select
              id="tax"
              v-model="form.tax"
              v-cancel-read-only
              filterable
              clearable
              class="w-full"
              :placeholder="$t('common.interactions.buttons.select')"
              :disabled="!canChangeTax"
              @change="handleChange('tax', $event)"
            >
              <el-option
                v-for="item in taxes"
                :key="item.id"
                :label="item.name"
                :value="item.rate"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-col>

      <el-col :lg="4">
        <div class="flex items-center">
          <div class="symbol px-4">=</div>

          <!-- Gross price -->
          <el-form-item
            :label="$t('pages.products.edit.form.pricing.common.headers.gross')"
            prop="gross"
            class="flex-grow"
          >
            <th-currency-input
              id="gross"
              data-testid="gross-price"
              :model-value="form.gross"
              :currency="form.currency"
              clearable-is-left
              :class="{ flashing: flashing.gross }"
              @update:modelValue="handleChange('gross', $event)"
            />
          </el-form-item>
        </div>
      </el-col>
    </el-row>
    <button type="submit" class="hidden" />
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import * as PriceHelper from '@/components/prices/price.js'

export default {
  props: {
    canChangeTax: {
      type: Boolean,
      default: false
    },

    taxRate: {
      type: Number,
      default: null
    },

    value: {
      type: Object,
      default: () => ({})
    },

    taxes: {
      type: Array,
      default: () => []
    },

    isServiceProduct: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      flashing: {
        purchase_price: false,
        gross: false,
        net: false,
        margin: false,
        cost: false
      },
      form: {
        net: 0,
        gross: 0,
        margin: 0,
        purchase_price: 0,
        cost: 0,
        currency: 'EUR',
        tax: null
      }
    }
  },

  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    netFieldDisabled() {
      return this.form.purchase_price + this.form.cost <= 0
    }
  },

  watch: {
    value() {
      this.setForm()
    }
  },

  async mounted() {
    this.form.currency = this.defaultCurrency
    this.setForm()
  },

  methods: {
    setForm() {
      this.form = { ...this.form, ...this.value }
      if (this.form.amount) {
        this.form.net = this.form.amount.net
        this.form.gross = this.form.amount.gross
        delete this.form.amount
      }

      if (this.taxRate !== null) {
        this.form.tax = this.taxRate
      } else {
        this.form.tax = this.taxes[0]?.rate
      }
    },
    flashFields(fields) {
      fields.forEach((item) => {
        this.flashing[item] = true
        setTimeout(() => {
          this.flashing[item] = false
        }, 300)
      })
    },
    handleChange(field, value) {
      // Update field value
      this.form[field] = value

      // Calculate
      let newPrice = {
        net: null,
        gross: null,
        margin: null,
        cost: null,
        purchase_price: null
      }

      try {
        // Gross
        if (field === 'gross') {
          newPrice = PriceHelper.onGross(
            this.form.gross,
            this.form,
            this.form.tax
          )
        }
        // Net
        else if (field === 'net') {
          newPrice = PriceHelper.onNet(this.form.net, this.form, this.form.tax)
        }
        // Margin
        else if (field === 'margin') {
          newPrice = PriceHelper.onMargin(
            this.form.margin,
            this.form,
            this.form.tax
          )
        }
        // Cost
        else if (field === 'cost') {
          newPrice = PriceHelper.onCost(
            this.form.cost,
            this.form,
            this.form.tax
          )
        }
        // Purchase price
        else if (field === 'purchase_price') {
          newPrice = PriceHelper.onPurchasePrice(
            this.form.purchase_price,
            this.form,
            this.form.tax
          )
        }
        // Tax
        else if (field === 'tax') {
          if (this.form.gross) {
            newPrice = PriceHelper.onGross(
              this.form.gross,
              this.form,
              this.form.tax
            )
          } else if (this.form.net) {
            newPrice = PriceHelper.onGross(
              this.form.net,
              this.form,
              this.form.tax
            )
          } else {
            return
          }
        }

        // User feedback
        this.flashFields(Object.keys(newPrice))
        // Clean
        if (!isNaN(newPrice.purchasePrice)) {
          newPrice.purchase_price = newPrice.purchasePrice
          delete newPrice.purchasePrice
        }

        // Set new price
        this.form = { ...this.form, ...newPrice }
        this.$emit('update', this.form)
      } catch (error) {
        this.$message({
          type: 'error',
          message: error
        })
      }
    }
  }
}
</script>

<style scoped>
.symbol {
  padding: 0 0.5rem;
  font-size: 1.5em;
  display: flex;
}

@keyframes flashing {
  0% {
    background-color: rgba(169, 217, 251, 0.1);
  }
  100% {
    background-color: unset;
  }
}

.calculation-modal :deep(.flashing .el-input__inner) {
  animation: flashing 300ms 1 ease-in-out;
}
</style>
