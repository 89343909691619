<template>
  <div class="scaled-price">
    <el-form
      v-for="(price, index) in form"
      :key="'scaled-prices-price-' + index"
      @submit.prevent="addPrice"
    >
      <price-row
        :ref="'price-' + index"
        :price="price"
        :tax="tax"
        :is-last="false"
        :is-first="index === 0"
        :is-service-product="isServiceProduct"
        :used-currencies="usedCurrencies"
        :can-add="noCurrencies"
        @delete="handleDelete(index)"
        @update="(v) => (form[index] = v)"
        @submit="addPrice"
      />
    </el-form>
  </div>
</template>

<script>
import { computed } from 'vue'
import PriceRow from './price-row'
import currenciesMixin from './../../mixins/currencies'

export default {
  components: {
    PriceRow
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },
    tax: {
      type: Object,
      required: true
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const form = computed({
      get() {
        return props.modelValue
      },
      set(modelValue) {
        emit('update:modelValue', modelValue)
      }
    })
    const {
      defaultCurrency,
      noCurrencies,
      availableCurrencies,
      usedCurrencies
    } = currenciesMixin(form)
    return {
      defaultCurrency,
      noCurrencies,
      availableCurrencies,
      usedCurrencies,
      form
    }
  },

  methods: {
    handleDelete(index) {
      this.form.splice(index, 1)
      this.$emit('on-price-delete')
    },

    addPrice() {
      if (!this.noCurrencies) return

      // Create empty price
      this.form.push({
        amount: {
          net: null,
          gross: null
        },
        margin: null,
        currency:
          this.form.length === 0
            ? this.defaultCurrency
            : this.availableCurrencies[0]
      })
    }
  }
}
</script>
