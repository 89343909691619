<template>
  <th-wrapper
    body-class="p-0"
    collapsable
    header-class="bg-th-primary-light pb-5 pt-5"
    collapsed-by-default
    @collapsed-changed="handleCollapsedChanged"
  >
    <!-- Title -->
    <template #title>
      <div class="flex items-center">
        <span>
          <span>
            {{ $t('pages.products.edit.form.sections.addon_groups.title') }}
          </span>
          <span v-if="addonGroupsLength" class="ml-1">
            ({{ addonGroupsLength }})
          </span>
        </span>
      </div>
    </template>

    <!-- Body -->
    <div class="th-table-responsive shadow-th-light">
      <table class="th-table">
        <thead>
          <tr class="h-12">
            <th class="font-bold">
              <label for="addon-groups-select">
                {{ $t('pages.products.edit.form.sections.addon_groups.label') }}
              </label>
            </th>

            <th class="w-0" />
          </tr>
        </thead>

        <tbody>
          <tr class="border-b align-middle">
            <!-- Addon group -->
            <td class="pr-3">
              <el-form-item class="mb-0">
                <el-select
                  id="addon-groups-select"
                  value=""
                  class="w-full"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.addon_groups.placeholder'
                    )
                  "
                  @change="add"
                >
                  <el-option
                    v-for="{ name, id } in availableAddonGroups"
                    :key="id"
                    :label="name"
                    :value="id"
                  />
                </el-select>
              </el-form-item>
            </td>
          </tr>
          <tr
            v-for="(addonGroup, index) of addonGroups"
            :key="index"
            class="border-b align-middle"
          >
            <td>
              <span>{{ addonGroup.name }}</span>
            </td>
            <td class="w-0">
              <el-button
                :aria-label="
                  $t(
                    'pages.products.edit.form.sections.addon_groups.remove_button'
                  )
                "
                plain
                icon="Delete"
                class="el-button--text-icon"
                @click="remove(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </th-wrapper>
</template>

<script>
export default {
  name: 'EditProductAddonGroups',

  props: {
    modelValue: {
      type: [Array, null],
      default: () => []
    },

    resources: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    addonGroups: {
      get() {
        return this.modelValue || []
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    availableAddonGroups() {
      return this.resources.productAddonGroups?.filter(
        (addonGroup) => !this.addonGroups.find((a) => a.id === addonGroup.id)
      )
    },

    addonGroupsLength() {
      return this.addonGroups?.length
    }
  },

  methods: {
    add(id) {
      if (!id) return
      this.addonGroups.push(this.availableAddonGroups.find((a) => a.id === id))
    },

    remove(index) {
      this.addonGroups.splice(index, 1)
    },

    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.addon_groups.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>
