<template>
  <th-wrapper
    class="product-stock"
    collapsable
    collapsed-by-default
    data-testid="stock-box"
    @collapsed-changed="handleCollapsedChanged"
  >
    <!-- Title -->
    <template #title>
      <div>
        {{ $t('pages.products.edit.form.sections.stock.title') }}
      </div>
    </template>

    <!-- Center actions -->
    <template #center />

    <!-- Body -->
    <div
      v-for="(stockConfig, index) in stockConfigurationLocation"
      :key="index"
    >
      <el-row :gutter="20">
        <!-- Location -->
        <el-col :md="4">
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.sections.stock.configuration.location.label'
              )
            "
            :rules="locationRules"
            :for="'location-' + index"
            :prop="'location-' + index"
          >
            <el-select
              :id="'location-' + index"
              v-model="stockConfig.location"
              class="w-full"
              clearable
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.stock.configuration.location.placeholder'
                )
              "
              @change="form.stockable = true"
            >
              <el-option
                v-for="item in [
                  ...getCurrentLocationInArray(stockConfig.location),
                  ...filteredLocations
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- Remove -->
        <el-col :md="2">
          <el-form-item class="pt-5">
            <el-button
              icon="Delete"
              plain
              class="el-button--text-icon"
              data-testid="remove-stock-location"
              @click="removeItem(index)"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col v-if="isNew" :md="4">
          <!-- Initial stock -->
          <el-form-item
            prop="initial_stock"
            :label="
              $t(
                'pages.products.edit.form.sections.stock.configuration.initial_stock.label'
              )
            "
          >
            <el-input-number
              id="initial_stock"
              v-model="stockConfig.initial_stock"
              :min="0"
              class="w-full"
              data-testid="initial-stock-input"
              @change="form.stockable = true"
            />
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <!-- Reorder at -->
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.sections.stock.configuration.reorder_at.label'
              )
            "
          >
            <th-number-input
              v-model="stockConfig.reorder_point"
              class="w-full"
              :locale="$i18n.locale"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.stock.configuration.input.number.placeholder'
                )
              "
              @change="form.stockable = true"
            />
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <!-- Quantity at Reorder -->
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.sections.stock.configuration.quantity_at_reorder.label'
              )
            "
          >
            <th-number-input
              v-model="stockConfig.reorder_qty"
              :locale="$i18n.locale"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.stock.configuration.input.number.placeholder'
                )
              "
              class="w-full"
              @change="form.stockable = true"
            />
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <!-- Stock Minimum -->
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.sections.stock.configuration.stock_minimum.label'
              )
            "
          >
            <th-number-input
              v-model="stockConfig.stock_minimum"
              :locale="$i18n.locale"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.stock.configuration.input.number.placeholder'
                )
              "
              class="w-full"
              @change="form.stockable = true"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <hr class="mb-6 mt-3" />
    </div>

    <!-- Add -->
    <el-button
      v-if="showAddBranch"
      text
      class="block -mt-2"
      plain
      @click="addNew()"
    >
      <el-icon><Plus /></el-icon>
      <span>
        {{
          $t('pages.products.edit.form.sections.stock.configuration.input.add')
        }}
      </span>
    </el-button>
  </th-wrapper>
</template>

<script>
function genInitialStockConfig(isNew) {
  const result = {
    location: null,
    reorder_point: null,
    reorder_qty: null,
    stock_minimum: null
  }
  if (isNew) {
    result.initial_stock = null
  }
  return result
}
export default {
  components: {},

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    isNew: {
      type: Boolean,
      default: false
    },
    resources: {
      type: Object,
      required: true
    },
    stockConfigLoaded: {
      type: [Array, null],
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      stockConfigurationLocation: [genInitialStockConfig(this.isNew)],
      locations: [],
      locationRules: [{ validator: this.validateRequired, trigger: 'blur' }]
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    usedLocations() {
      return this.stockConfigurationLocation.map((config) => config.location)
    },
    filteredLocations() {
      return this.locations.filter(
        (location) => !this.usedLocations.includes(location.value)
      )
    },
    showAddBranch() {
      return this.locations.length > this.stockConfigurationLocation.length
    },
    hasOneBranch() {
      return this.resources.branches?.length === 1
    }
  },
  watch: {
    stockConfigLoaded: {
      deep: true,
      handler() {
        if (this.stockConfigLoaded) {
          this.stockConfigurationLocation = this.$deepClone(
            this.stockConfigLoaded
          )
        }
      }
    },
    'modelValue._initialStock': function () {
      this.updateInitialStockOneBranch()
    },
    'modelValue.stockable': function () {
      this.updateInitialStockOneBranch()
    },
    'resources.locations': function () {
      this.initiateStockConfig()
    },
    stockConfigurationLocation: {
      deep: true,
      handler() {
        this.handleStockConfigurationLocationChange()
      }
    }
  },
  created() {
    this.initiateStockConfig()
  },
  methods: {
    addNew() {
      this.stockConfigurationLocation.push(genInitialStockConfig(this.isNew))
    },
    removeItem(index) {
      this.stockConfigurationLocation.splice(index, 1)
      if (this.stockConfigurationLocation.length === 0) this.addNew()
    },
    getCurrentLocationInArray(current) {
      return this.locations.filter(({ value }) => value === current)
    },
    initiateStockConfig() {
      this.resources?.locations?.forEach((location) => {
        this.locations.push({
          value: location.id,
          label: location.name
        })
      })
    },
    validateRequired(rule, value, callback) {
      // Validate location required if initial stock is set
      const index = rule?.field?.split('-')[1] || 0
      if (
        !this.stockConfigurationLocation[index].location &&
        !!this.stockConfigurationLocation[index].initial_stock
      ) {
        callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      } else {
        callback()
      }
    },
    updateInitialStockOneBranch() {
      // This value is available in case there is just one branch
      if (
        this.modelValue._initialStock &&
        this.modelValue._initialStock !==
          this.stockConfigurationLocation[0].initial_stock &&
        this.hasOneBranch
      ) {
        this.stockConfigurationLocation[0].initial_stock = this.modelValue._initialStock
        this.stockConfigurationLocation[0].location = this.resources?.branches[0]?.id
      }
    },
    handleStockConfigurationLocationChange() {
      // Send the form back in case the location was selected
      if (this.usedLocations.length) {
        let newInput = {
          ...this.modelValue,
          stock_configuration_location: this.stockConfigurationLocation
        }
        if (this.hasOneBranch) {
          newInput._initialStock = this.stockConfigurationLocation[0].initial_stock
        }
        this.$emit('update:modelValue', newInput)
      }
    },
    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.stock.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>

<style scoped>
.product-stock :deep(.el-form-item) {
  margin-bottom: 1rem;
}
</style>
