<template>
  <div class="default-prices" data-testid="default-prices">
    <!-- No prices -->
    <div
      v-if="form.length === 0"
      class="mb-4 text-sm flex items-center"
      :class="{ italic: !isWhiteLabel }"
    >
      <el-button type="primary" plain class="mr-2" @click="add">
        {{ $t('pages.products.edit.form.sections.prices.common.add_price') }}
      </el-button>

      <span class="opacity-50">
        {{ $t('pages.products.edit.form.sections.prices.common.no_prices') }}
      </span>
    </div>

    <!-- Title -->
    <div
      class="font-bold text-sm mb-8"
      v-text="
        $t(
          'pages.products.edit.form.sections.prices.configuration.standard_price'
        )
      "
    />
    <!-- Prices -->
    <el-form v-for="(price, index) in form" :key="index" @submit.prevent="add">
      <price-row
        :ref="'price-' + index"
        :price="price"
        :tax="tax"
        :used-currencies="usedCurrencies"
        :is-last="index === form.length - 1"
        :is-first="index === 0"
        :disable-delete="form.length === 1"
        :can-add="noCurrencies"
        :is-service-product="isServiceProduct"
        @delete="handleDelete(index)"
        @add="add"
        @update="(v) => (form[index] = v)"
      />
    </el-form>
  </div>
</template>

<script>
import { computed } from 'vue'
import PriceRow from './price-row'
import currenciesMixin from './../../mixins/currencies'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    PriceRow
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },
    tax: {
      type: Object,
      required: true
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const form = computed({
      get() {
        return props.modelValue
      },
      set(modelValue) {
        emit('update:modelValue', modelValue)
      }
    })
    const {
      defaultCurrency,
      noCurrencies,
      availableCurrencies,
      usedCurrencies
    } = currenciesMixin(form)
    return {
      defaultCurrency,
      noCurrencies,
      availableCurrencies,
      usedCurrencies,
      form
    }
  },

  computed: {
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  mounted() {
    // Make sure there is always one default price
    if (this.form.length === 0) {
      this.form.push({
        amount: {
          net: null,
          gross: null
        },
        margin: null,
        currency: this.defaultCurrency
      })
    }
  },

  methods: {
    handleDelete(index) {
      this.form.splice(index, 1)
    },

    add() {
      // Skip add if no currency avaliable
      if (!this.noCurrencies) return

      // Push next price with currency
      this.form.push({
        amount: {
          net: null,
          gross: null
        },
        margin: null,
        currency: this.availableCurrencies[0] || null
      })
    }
  }
}
</script>
