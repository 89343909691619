import Decimal from 'decimal.js-light'
import { getRoundedNumber } from '@/utils/numbers'

// Gross = (Purchase Prise + cost) * ( 1 + Margin ) * ( 1 + TaxRate )
const calcGross = ({ purchasePrice, cost, margin, taxRate }) => {
  return purchasePrice
    .add(cost)
    .mul(new Decimal(1).add(margin))
    .mul(new Decimal(1).add(taxRate))
}

// Gross = net * ( 1 + TaxRate )
const calcGrossFromNet = ({ net, taxRate }) => {
  return net.mul(new Decimal(1).add(taxRate))
}

// Net = Gross Price / (1 + TaxRate)
const calcNet = ({ gross, taxRate }) => {
  return gross.div(new Decimal(1).plus(taxRate))
}

// Margin = (Gross Price / ((Purchase Price + Cost) * (1 + TaxRate))) -1
// If (Purchase Price + Cost) === 0 return 0, to prevent division by zero.
const calcMargin = ({ gross, purchasePrice, cost, taxRate }) => {
  const totalCostWithTax = purchasePrice
    .add(cost)
    .mul(new Decimal(1).add(taxRate))
  if (totalCostWithTax.isZero()) {
    return new Decimal(0)
  }
  return gross.div(totalCostWithTax).minus(new Decimal(1))
}

/**
 * calculate price from incoming margin
 * @param {Number} margin
 * @param {Object} price
 * @param {Number} taxRate
 */
export function onMargin(margin, price, taxRate) {
  const _margin = new Decimal(margin || 0)

  const _gross = calcGross({
    purchasePrice: new Decimal(price.purchase_price || 0),
    cost: new Decimal(price.cost || 0),
    margin: _margin,
    taxRate
  })

  const _net = calcNet({ gross: _gross, taxRate })

  return {
    margin: getRoundedNumber(_margin, 4),
    net: getRoundedNumber(_net),
    gross: getRoundedNumber(_gross)
  }
}

/**
 * calculate price from incoming purchase price
 * @param {Number} purchasePrice
 * @param {Object} price
 * @param {Number} taxRate
 */
export function onPurchasePrice(purchasePrice, price, taxRate) {
  const _purchasePrice = new Decimal(purchasePrice || 0)

  const _margin = calcMargin({
    cost: new Decimal(price.cost || 0),
    purchasePrice: _purchasePrice,
    gross: new Decimal(price.gross || price.amount?.gross || 0),
    taxRate
  })

  return {
    purchasePrice: getRoundedNumber(_purchasePrice),
    margin: getRoundedNumber(_margin, 4)
  }
}

/**
 * calculate price from incoming cost
 * @param {Number} cost
 * @param {Object} price
 * @param {Number} taxRate
 */
export function onCost(cost, price, taxRate) {
  const _cost = new Decimal(cost || 0)

  const _margin = calcMargin({
    cost: _cost,
    purchasePrice: new Decimal(price.purchase_price || 0),
    gross: new Decimal(price.gross || price.amount?.gross || 0),
    taxRate
  })

  return {
    cost: getRoundedNumber(_cost),
    margin: getRoundedNumber(_margin, 4)
  }
}

/**
 * calculate price from incoming net
 * @param {Number} net
 * @param {Object} price
 * @param {Number} taxRate
 */
export function onNet(net, price, taxRate) {
  const _net = new Decimal(net || 0)

  const _gross = calcGrossFromNet({ net: _net, taxRate })

  const _margin = calcMargin({
    gross: _gross,
    purchasePrice: new Decimal(price.purchase_price || 0),
    cost: new Decimal(price.cost || 0),
    taxRate
  })

  return {
    margin: getRoundedNumber(_margin, 4),
    net: getRoundedNumber(_net),
    gross: getRoundedNumber(_gross)
  }
}

/**
 * calculate price from incoming gross
 * @param {Number} gross
 * @param {Object} price
 * @param {Number} taxRate
 */
export function onGross(gross, price, taxRate) {
  const _gross = new Decimal(gross || 0)

  const _net = calcNet({ gross: _gross, taxRate })

  const _margin = calcMargin({
    cost: new Decimal(price.cost || 0),
    purchasePrice: new Decimal(price.purchase_price || 0),
    gross: _gross,
    taxRate
  })

  return {
    margin: getRoundedNumber(_margin, 4),
    net: getRoundedNumber(_net),
    gross: getRoundedNumber(_gross)
  }
}
