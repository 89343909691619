<template>
  <el-form ref="form" :model="form" :rules="rules">
    <div class="flex items-center">
      <div class="flex items-center mr-2">
        <!-- price book id -->
        <el-form-item
          class="mr-4"
          prop="price_book"
          :label="
            $t('pages.products.edit.form.sections.price-books.form.pricebooks')
          "
        >
          <el-select
            id="price_book"
            v-model="form.price_book"
            v-cancel-read-only
            clearable
            filterable
            autocorrect="off"
            autocapitalize="off"
            autocomplete="nope"
            spellcheck="false"
            :placeholder="$t('common.inputs.placeholders.select')"
            :disabled="!isNew"
          >
            <el-option
              v-for="priceBook in availablePriceBooks"
              :key="priceBook.id"
              :label="priceBook.name"
              :value="priceBook.id"
            >
              <div class="flex justify-between">
                <div class="mr-4">{{ priceBook.name }}</div>
                <div
                  :class="[
                    priceBook.id === form.id
                      ? 'text-th-secondary'
                      : 'text-th-light-gray'
                  ]"
                >
                  {{ getPriceBookLabel(priceBook) }}
                </div>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <!-- Book price -->
        <el-form-item
          v-if="form.price_book"
          class="mr-4"
          prop="amount_gross"
          :label="
            $t(
              'pages.products.edit.form.sections.price-books.form.gross_book_price'
            )
          "
        >
          <th-currency-input
            id="amount_gross"
            v-model="form.amount_gross"
            clearable-is-left
          />
        </el-form-item>
      </div>
      <div v-if="form.price_book" class="flex items-center">
        <!-- Delete -->
        <el-button
          plain
          icon="Delete"
          data-testid="price-book-delete"
          class="-ml-4 el-button--text-icon"
          :title="$t('common.interactions.buttons.remove')"
          @click="$emit('delete', index)"
        />

        <!-- Add -->
        <el-button
          v-show="isLast && availablePriceBooks.length > 1"
          plain
          class="el-button--primary-icon"
          icon="Plus"
          :title="$t('common.interactions.buttons.add')"
          @click="$emit('add')"
        />
      </div>
    </div>
    <div
      v-if="selectedPriceBookEntry"
      class="inline-block w-full truncate text-th-secondary text-sm"
    >
      <a
        target="_blank"
        :href="selectedPriceBookEntryLabel.href"
        v-text="selectedPriceBookEntryLabel.text"
      />
    </div>
    <hr v-show="!isLast" class="my-6" />
  </el-form>
</template>

<script>
import safeGet from 'just-safe-get'
import { createLabel } from '@/utils/strings'
import { getPriceBookValidityMessage, getDateRange } from './helpers'

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    usedPriceBookIds: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    },
    isLast: {
      type: Boolean,
      required: false
    },
    isNew: {
      type: Boolean,
      default: true
    },
    priceBookResource: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rules: {
        amount_gross: [
          {
            validator: this.validateGrossBookPrice,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    selectedPriceBookEntry() {
      return this.priceBookResource.find(
        ({ id }) => id === this.form.price_book
      )
    },
    selectedPriceBookEntryLabel() {
      return {
        href: `/products/pricebooks/${this.selectedPriceBookEntry.id}/edit`,
        text: this.getPriceBookValidity(this.selectedPriceBookEntry)
      }
    },
    availablePriceBooks() {
      return this.priceBookResource.filter(({ id }) => {
        return (
          !this.usedPriceBookIds.includes(id) || id === this.form.price_book
        )
      })
    },
    dayTranslations() {
      return {
        monday: this.$t('common.days.short.monday'),
        tuesday: this.$t('common.days.short.tuesday'),
        wednesday: this.$t('common.days.short.wednesday'),
        thursday: this.$t('common.days.short.thursday'),
        friday: this.$t('common.days.short.friday'),
        saturday: this.$t('common.days.short.saturday'),
        sunday: this.$t('common.days.short.sunday')
      }
    }
  },
  methods: {
    getPriceBookLabel(priceBook) {
      const isVarious = safeGet(
        priceBook,
        'constraints.time.scheduled.day_of_week.enabled',
        {}
      )
      return isVarious
        ? this.$t('pages.products.edit.form.sections.price-books.form.various')
        : getDateRange(priceBook, this.$date.formatDateWithTimezone)
    },
    getPriceBookValidity(pricebook) {
      const { dateRange, hourRange } = getPriceBookValidityMessage(
        pricebook,
        this.$date.formatDateWithTimezone
      )
      const hourRangeArray = hourRange.map(({ day, timeSlots }) =>
        createLabel([this.dayTranslations[day], ...timeSlots], ', ')
      )
      return this.$t(
        'pages.products.edit.form.sections.price-books.form.validity',
        {
          dates: createLabel([dateRange, ...hourRangeArray], ', ')
        }
      )
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },
    validateGrossBookPrice(_, value, callback) {
      const hasPriceBookId = !!this.form.price_book
      if (hasPriceBookId && Number.isFinite(value)) return callback()
      callback(new Error(this.$t('common.forms.rules.field_warnings.required')))
    }
  }
}
</script>
