import typeOf from 'just-typeof'

/**
 * Converts attributes object to array of objects
 * @param {Object} attributes - {Size: "Large", Color: "Red"}
 * @returns {Array} - [{"key":"Size", "value":"Large"}, {"key":"Color", "value":"Red"}]
 */
export function decodeAttributes(attributes) {
  const attr = []
  if (typeOf(attributes) === 'object') {
    Object.entries(attributes).forEach((item) => {
      attr.push({ key: item[0], value: item[1] })
    })
  }
  if (attr.length === 0) attr.push({ key: '', value: '' })
  return attr
}

/**
 * Converts attributes array of objects to object
 * @param {Array} attributes - [{"key":"Size", "value":"Large"}, {"key":"Color", "value":"Red"}]
 * @returns {Object} - {Size: "Large", Color: "Red"}
 */
export function encodeAttributes(attributes) {
  const attr = {}
  if (typeOf(attributes) === 'array') {
    attributes.forEach((item) => {
      if (!item || !item.value) return
      attr[item.key] = item.value
    })
  }
  return attr
}

/**
 * Converts options array
 * @param {Array} options - [{ Color: ['Blue', 'Green', 'Red'] },{ Fit: ['Baggy', 'Fit', 'Slim'] }]
 * @returns {Array} - [{ name: 'Color', values: ['Blue', 'Green', 'Red'] },{ name: 'Fit', values: ['Baggy', 'Fit', 'Slim'] }]
 */
export function decodeOptions(options) {
  if (!options) return [{ name: '', values: [] }]
  return options.map((o) => {
    return {
      name: Object.keys(o)[0],
      values: Object.values(o)[0].filter((v) => v !== null)
    }
  })
}

/**
 * Converts options array
 * @param {Array} options - [{ name: 'Color', values: ['Blue', 'Green', 'Red'] },{ name: 'Fit', values: ['Baggy', 'Fit', 'Slim'] }]
 * @return {Array} - [{ Color: ['Blue', 'Green', 'Red'] },{ Fit: ['Baggy', 'Fit', 'Slim'] }]
 */
export function encodeOptions(options) {
  return options.map((value) => ({
    [value.name]: value.values
  }))
}

/**
 * Create options permutations
 * @param {Array} options - [{ name: 'Size', values: ['Large', 'Small'] }, { name: 'Color', values: ['Red', 'Green'] }]
 * @return {Array} - [{ Size: 'Large', Color: 'Red' }, { Size: 'Large', Color: 'Green' }, { Size: 'Small', Color: 'Red' }, { Size: 'Small', Color: 'Green' }]
 */
export function makeOptionsPermutations(options) {
  // FROM
  // [
  //   { name: 'Size', values: ['Large', 'Small'] },
  //   { name: 'Color', values: ['Red', 'Green'] },
  // ]
  // TO
  // [
  //   { name: 'Size', value: 'Large' },
  //   { name: 'Size', value: 'Small' },
  //   { name: 'Color', value: 'Red' },
  //   { name: 'Color', value: 'Green' },
  // ]
  const optionsChanged = options.map((option) => {
    return option.values.map((value) => {
      return {
        name: option.name,
        value
      }
    })
  })

  // FROM
  // [
  //   { name: 'Size', value: 'Large' },
  //   { name: 'Size', value: 'Small' },
  //   { name: 'Color', value: 'Red' },
  //   { name: 'Color', value: 'Green' },
  // ]
  // TO
  // [
  //   [
  //     { name: 'Size', value: 'Large' },
  //     { name: 'Color', value: 'Red' }
  //   ],
  //   [
  //     { name: 'Size', value: 'Large' },
  //     { name: 'Color', value: 'Green' }
  //   ],
  //   [
  //     { name: 'Size', value: 'Small' },
  //     { name: 'Color', value: 'Red' }
  //   ],
  //   [
  //     { name: 'Size', value: 'Small' },
  //     { name: 'Color', value: 'Green' }
  //   ]
  // ]
  function combos(list, n = 0, result = [], current = []) {
    if (n === list.length) {
      result.push(current)
    } else {
      if (list[n].length === 0) {
        combos(list, n + 1, result, current)
      } else {
        list[n].forEach((item) =>
          combos(list, n + 1, result, [...current, item])
        )
      }
    }
    return result
  }
  const combinations = combos(optionsChanged)

  // FROM
  // [
  //   [
  //     { name: 'Size', value: 'Large' },
  //     { name: 'Color', value: 'Red' }
  //   ],
  //   [
  //     { name: 'Size', value: 'Large' },
  //     { name: 'Color', value: 'Green' }
  //   ],
  //   [
  //     { name: 'Size', value: 'Small' },
  //     { name: 'Color', value: 'Red' }
  //   ],
  //   [
  //     { name: 'Size', value: 'Small' },
  //     { name: 'Color', value: 'Green' }
  //   ]
  // ]
  // TO
  // [
  //   { Size: 'Large', Color: 'Red' },
  //   { Size: 'Large', Color: 'Green' },
  //   { Size: 'Small', Color: 'Red' },
  //   { Size: 'Small', Color: 'Green' }
  // ]
  const combinationsObjects = combinations.map((c) => {
    const a = {}
    c.forEach((b) => {
      a[b.name] = b.value
    })
    return a
  })

  return combinationsObjects
}

/**
 * Map of avaliable product types
 */
export const productType = {
  VARIANT: 'variant',
  VARIANT_PRODUCT: 'variant_product',
  LINKED: 'linked',
  LINKED_PRODUCT: 'linked_product',
  PRODUCT: 'product',
  PURCHASE_ITEM: 'purchase_item',
  COMPOSED_PRODUCT: 'composed_product'
}

/**
 * Detect if product type is purchase item
 */
export function isPurchaseItem(product) {
  return (
    product.type === productType.PRODUCT &&
    product.purchasable &&
    !product.sellable &&
    !product.is_service
  )
}
