<template>
  <div>
    <!-- Supplier -->
    <el-form-item
      prop="supplier"
      :label="$t('pages.products.edit.form.sections.additional.supplier.label')"
    >
      <el-input
        id="supplier"
        v-model="supplier"
        :placeholder="
          $t(
            'pages.products.edit.form.sections.additional.supplier.placeholder'
          )
        "
      />
    </el-form-item>

    <!-- Purchase price -->
    <el-form-item
      prop="purchase_price"
      :label="
        $t('pages.products.edit.form.sections.standard.purchase_price.label')
      "
    >
      <th-currency-input
        id="purchase_price"
        v-model="defaultPrice.purchase_price"
        clearable-is-left
        data-testid="purchase-price"
        @update:modelValue="handlePurchasePrice"
      />
    </el-form-item>

    <!-- Initial stock -->
    <el-form-item
      v-if="shouldDisplayInitialStock"
      :label="
        $t('pages.products.edit.form.sections.standard.initial_stock.label')
      "
    >
      <el-input-number
        v-model="form._initialStock"
        data-testid="initial_stock"
        :min="0"
        :disabled="isServiceProduct"
        :placeholder="
          $t(
            'pages.products.edit.form.sections.standard.initial_stock.placeholder'
          )
        "
        class="w-full"
      />
    </el-form-item>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    shouldDisplayInitialStock: {
      type: Boolean,
      default: false
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      cleanPrice: {
        amount: {
          net: null,
          gross: null
        },
        margin: null,
        currency: this.defaultCurrency
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },

      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    supplier: {
      get() {
        return safeGet(this.form, 'supplier.sku')
      },
      set(value) {
        this.form.supplier = { ...this.form.supplier, sku: value || null }
      }
    },

    defaultPrice() {
      return this.form.prices?.default_prices[0] || this.cleanPrice
    },

    defaultCurrency() {
      return this.$store.getters['Config/getCurrentDefaultCurrency']
    },

    isNew() {
      return !this.$route.params.id
    }
  },

  mounted() {
    this.cleanPrice.currency = this.defaultCurrency
  },

  methods: {
    handlePurchasePrice() {
      this.defaultPrice.amount = {
        net: null,
        gross: null
      }
      this.defaultPrice.margin = null
    }
  }
}
</script>
