<template>
  <el-form-item
    :label="valuesLabel"
    :class="{ 'hidden-label': form.length === 0 }"
  >
    <div
      class="tag-input flex w-full items-start justify-start gap-2 flex-start"
    >
      <!-- Input -->
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        @submit.prevent="handleInputConfirm"
      >
        <el-form-item prop="inputValue">
          <el-input
            ref="newTagInput"
            v-model="model.inputValue"
            class="w-40"
            :disabled="disabled"
            :placeholder="
              $t(
                'pages.products.edit.form.sections.variants.values.placeholder'
              )
            "
            data-testid="attribute-values-input"
          >
            <template #append>
              <el-button
                data-testid="attribute-values-button"
                class="px-3"
                icon="Right"
                native-type="submit"
              />
            </template>
          </el-input>
        </el-form-item>
      </el-form>

      <!-- Values -->
      <th-tags-single-line
        :model-value="form"
        :delete-tooltip="
          $t('pages.products.edit.form.sections.variants.attribute.delete')
        "
        :modal-header="
          $t(
            'pages.products.edit.form.sections.variants.attribute.edit_attribute',
            { attribute: attributeName ? `'${attributeName}'` : '' }
          )
        "
        editable
        @update:modelValue="form = $event"
        @deleteAll="$emit('delete')"
      />
    </div>
  </el-form-item>
</template>

<script>
import ThTagsSingleLine from '@components/tags/tags-single-line.vue'

export default {
  components: { ThTagsSingleLine },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    attributeName: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      model: {
        inputValue: ''
      },
      rules: {
        inputValue: [
          {
            validator: (rule, value, callback) => {
              if (this.form.includes(value)) {
                callback(
                  new Error(
                    this.$t(
                      'pages.products.edit.form.sections.variants.values.already_exists'
                    )
                  )
                )
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.$emit('change', value)
      }
    },

    isNew() {
      return !this.$route.params.id
    },

    valuesLabel() {
      return (
        this.$t('pages.products.edit.form.sections.variants.values.label') +
        (this.form.length ? ` (${this.form.length})` : '')
      )
    }
  },

  methods: {
    handleInputConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const value = this.model.inputValue
          if (value) {
            const temp = this.form
            temp.push(value)
            this.form = temp
          }
          this.inputVisible = false
          this.model.inputValue = ''
        } else {
          return false
        }
      })
    }
  }
}
</script>
