<template>
  <el-form
    ref="form"
    :key="renderIndex"
    :model="product"
    :rules="rules"
    :validate-on-rule-change="false"
  >
    <variants-operation-dialog :child-opertation="childOpertation" />

    <!-- Type selector -->
    <product-type-selector
      v-if="isNew"
      v-model="product.type"
      @update:modelValue="changeProductType"
    />

    <!-- Standard -->
    <edit-product-standard
      v-model="product"
      v-loading="loading"
      :is-new="isNew"
      :resources="resources"
      :auto-generate-id="autoGenerateId"
      :is-service-product="isServiceProduct"
      :computed-product-totals="computedProductTotals"
    />

    <!-- Variant stock -->
    <edit-product-variant-stock
      v-if="product && showSection.stock_table"
      v-loading="loading"
      :product="product"
    />

    <!-- Variants -->
    <edit-product-variants
      v-if="
        resources.product_templates && isMainProduct && showSection.variants
      "
      ref="variantProducts"
      v-model="product"
      v-loading="loading"
      :is-new="isNew"
      :resources="resources"
      :auto-generate-id="autoGenerateId"
      :is-service-product="isServiceProduct"
      @update-variant="markVariantChange"
    />

    <!-- Takeaway -->
    <edit-takeaway
      v-if="isTakeawayEnabled"
      v-model="product"
      v-loading="loading"
      :resources="resources"
    />

    <!-- Linked -->
    <edit-product-linked
      v-if="isMainProduct && showSection.linked_products"
      ref="linkedProducts"
      v-model="product.linked_products"
      v-loading="loading"
    />

    <!-- Composed -->
    <edit-product-composed
      v-if="showSection.composed_product"
      ref="productComposed"
      v-model="product"
      v-loading="loading"
      @computed-product-totals="(v) => (computedProductTotals = v)"
    />

    <!-- Checkout Questions -->
    <edit-product-checkout-questions
      v-if="showSection.checkout_questions"
      v-model="product.service_questions"
      v-loading="loading"
      :resources="resources"
    />

    <!-- Additional information -->
    <edit-product-additional-information
      v-if="resources && resources.tags"
      ref="productAdditionalInformation"
      v-model="product"
      v-loading="loading"
      :product-id="productId"
      :resources="resources"
      :propagate-to-variants="propagateToVariants"
      @new-tags-created="reFetchTags"
    />

    <!-- Product branch customizations -->
    <edit-product-branch-customizations
      ref="productBranchCustomizations"
      v-model="productBranchCustomizations"
      v-loading="loading"
      :resources="resources"
    />

    <!-- Prices -->
    <edit-product-prices
      v-if="isProductPricesEnabled"
      ref="productPrices"
      v-model="product"
      v-loading="loading"
      :is-new="isNew"
      :resources="resources"
      :is-service-product="isServiceProduct"
    />

    <!-- Addon groups -->
    <edit-product-addon-groups
      v-if="isAddOnEnabled"
      v-model="product.addon_groups"
      v-loading="loading"
      :resources="resources"
    />

    <!-- Price Books -->
    <edit-product-price-books
      v-if="isProductPriceBooksEnabled"
      ref="productPriceBooks"
      v-model="priceBooksEntities"
      v-loading="loading"
      :full-product="payload"
      :resources="resources"
    />

    <!-- Stock -->
    <edit-product-stock
      v-if="showStock && showSection.stock"
      v-model="product"
      v-loading="loading"
      :is-new="isNew"
      :resources="resources"
      :stock-config-loaded="stockConfigLoaded"
    />

    <!-- JSON Editor -->
    <modal v-model="isModalOpen" name="json-editor" adaptive :min-height="800">
      <div class="jsonWrapper">
        <json-editor
          v-if="hasGlobalDataMode"
          :json-input="payload"
          :show-close="true"
          @jsonParsed="handleItem"
          @close-json-editor="isModalOpen = false"
        />
      </div>
    </modal>
  </el-form>
</template>

<script>
import EditProductStandard from './edit-product-standard'
import EditProductVariants from './edit-product-variants'
import EditProductPrices from './edit-product-prices'
import EditProductPriceBooks from './edit-product-price-books'
import EditProductStock from './edit-product-stock'
import EditProductVariantStock from './edit-product-variant-stock'
import EditProductAdditionalInformation from './edit-product-additional-information.vue'
import EditProductBranchCustomizations from './edit-product-branch-customizations.vue'
import EditProductLinked from './edit-product-linked'
import EditProductCheckoutQuestions from './edit-product-checkout-questions'
import EditProductComposed from './edit-product-composed'
import EditProductAddonGroups from './edit-product-addon-groups'
import EditTakeaway from './edit-takeaway'
import VariantsOperationDialog from './variants/variants-operation-dialog'
import ProductTypeSelector from './common/product-type-selector'
import JsonEditor from '@/components/json-editor'
import { generateDefault } from '../helpers/generate-default'
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import pAll from 'p-all'
import omit from 'just-omit'
import pRetry from 'p-retry'
import pLimit from 'p-limit'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import compare from 'just-compare'
import cloneDeep from 'clone-deep'
import { diff } from 'just-diff'
import { mapGetters } from 'vuex'
import {
  encodeAttributes,
  decodeAttributes,
  decodeOptions,
  encodeOptions,
  productType,
  isPurchaseItem
} from '@/utils/products'
import * as priceHelper from '@/components/prices/price.js'
import { replaceEmptyStringPropsWithNull } from '@/utils/strings'
import { getProductTypeNamesMap } from '@/views/products/v2/components/common/product-type-names-map'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'
import { useProductsStore } from '@/store/products'
import { useMessagesStore } from '@/store/messages'
import backButton from '@/mixins/back-button'

export default {
  components: {
    EditProductStandard,
    EditProductVariants,
    EditProductPrices,
    EditProductPriceBooks,
    EditProductStock,
    EditProductLinked,
    EditProductCheckoutQuestions,
    EditProductAdditionalInformation,
    EditProductBranchCustomizations,
    EditProductComposed,
    EditProductAddonGroups,
    EditTakeaway,
    EditProductVariantStock,
    VariantsOperationDialog,
    JsonEditor,
    ProductTypeSelector
  },

  props: {
    createAnother: Boolean,
    propagateToVariants: Boolean
  },

  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    const { goBack } = backButton({ name: 'products-manager' })

    const productsStore = useProductsStore()

    return { featureConfig, productsStore, goBack }
  },

  data() {
    return {
      renderIndex: 1,
      isModalOpen: false,
      autoGenerateId: !!safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        'products.generate_product_id'
      ), // Get default value from configuration
      isStrictMode: !!safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        'products.strict_mode'
      ), // Get default value from configuration
      loading: true,
      pristineForm: null,
      initialProduct: null,
      resources: {},
      payload: {},
      ommitables: [
        '_initialStock',
        'children',
        'options',
        'price_book_entries'
      ],
      childOpertation: {
        showProgress: false,
        inProgress: false,
        progress: 0,
        progressStatus: null,
        errors: []
      },
      initialStocks: {},
      stockConfigLoaded: null,
      product: {},
      updatedVariants: {},
      computedProductTotals: null, // Comes from edit-product-composed
      // Enable or disable product sections with product type
      productTypeSections: {
        product: {
          stock_table: true,
          linked_products: true,
          checkout_questions: true,
          addon_groups: true,
          price: true,
          stock: true
        },
        composed_product: {
          stock_table: true,
          composed_product: true,
          addon_groups: true,
          price: true
        },
        purchase_item: {
          stock: true,
          stock_table: true
        },
        voucher: {
          stock_table: true,
          linked_products: true,
          checkout_questions: true,
          addon_groups: true,
          price: true,
          stock: true
        },
        linked: {
          stock_table: true,
          linked_products: true,
          checkout_questions: true,
          addon_groups: true,
          price: true,
          stock: true
        },
        linked_product: {
          stock_table: true,
          linked_products: true,
          checkout_questions: true,
          addon_groups: true,
          price: true,
          stock: true
        },
        variant: {
          stock_table: true,
          checkout_questions: true,
          addon_groups: false,
          price: true
        },
        variant_product: {
          variants: true,
          checkout_questions: true,
          addon_groups: false,
          price: true,
          stock: true
        }
      },
      typeNamesMap: {
        ...getProductTypeNamesMap(),
        linked: 'pages.products.edit.form.types.linked',
        linked_product: 'pages.products.edit.form.types.linked_product',
        purchase_product: 'pages.products.edit.form.types.purchase_product',
        variant: 'pages.products.edit.form.types.variant',
        voucher: 'pages.products.edit.form.types.voucher'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      navigationAfterCreation: 'Config/getNavigationAfterCreation',
      hasGlobalDataMode: 'Config/hasGlobalDataMode',
      isGastro: 'Auth/isGastro',
      isLitePos: 'Auth/isLitePos'
    }),

    isDirty() {
      // Show warning message only if variants were changed
      return !compare(
        safeGet(this.pristineForm, 'children', []),
        safeGet(this.product, 'children', [])
      )
    },

    isNew() {
      return !this.$route.params.id
    },

    productId() {
      return this.$route.params.id
    },

    isMainProduct() {
      return this.product.type !== 'variant'
    },

    isServiceProduct() {
      return this.product.is_service
    },

    showSection() {
      return (
        this.productTypeSections[this.product.type] ||
        this.productTypeSections.product
      )
    },
    isProductPricesEnabled() {
      return this.resources?.taxes && this.showSection.price
    },
    isProductPriceBooksEnabled() {
      return (
        this.isProductPricesEnabled &&
        safeGet(this.featureConfig, 'products.pricebooks') //feature flag for pricebooks
      )
    },
    isAddOnEnabled() {
      return this.showSection.addon_groups && (this.isGastro || this.isLitePos)
    },
    priceBooksEntities() {
      const priceBookIds = this.resources.price_books?.map(({ id }) => id) || []
      return (this.product?.price_book_entries || []).filter(({ price_book }) =>
        priceBookIds.includes(price_book)
      )
    },
    productBranchCustomizations() {
      return this.resources.product_branch_customizations || []
    },
    rules() {
      return {
        name: [
          {
            max: 512,
            message: this.$t('common.forms.rules.max_length', { length: 512 })
          },
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        tax: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        account: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        custom_id: [
          {
            required: !this.autoGenerateId,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        product_group: [
          {
            required: this.isStrictMode,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        // Additional information
        summary: [
          {
            max: 1024,
            message: this.$t('common.forms.rules.max_length', { length: 1024 })
          }
        ],
        description: [
          {
            max: 16384,
            message: this.$t('common.forms.rules.max_length', { length: 16384 })
          }
        ]
      }
    },

    hasOneBranch() {
      return this.resources?.branches?.length === 1
    },

    showStock() {
      return (
        !this.product?.children?.length &&
        this.product.type !== productType.VARIANT_PRODUCT &&
        !this.isServiceProduct
      )
    },
    isTakeawayEnabled() {
      return this.$isFeatureEnabled('takeaway') && this.isGastro
    },
    productTypeName() {
      const typeKey = this.typeNamesMap[this.product.type]
      return typeKey ? this.$t(typeKey, 'en') : 'Unknown product type'
    }
  },
  watch: {
    isServiceProduct(isService) {
      isService && this.resetServiceRelatedValues()
    }
  },
  async mounted() {
    // Make sure product id is not null
    if (this.productId === 'null') {
      return this.$router.push({ name: 'products-manager' })
    }

    this.product = generateDefault(this.hasGlobalDataMode)
    // Fetch branches, taxes, accounts and groups
    try {
      await this.fetchResources()
    } catch (error) {
      this.$logException(error, { trackError: false })
    }

    // Fetch product
    if (this.productId) {
      await this.fetchProduct(this.productId)
    } else {
      this.loading = false
    }
    this.syncPristineForm()
  },
  methods: {
    // ----------------- syncing pristineForm with product -----------------
    syncPristineForm() {
      this.pristineForm = cloneDeep(this.product)
    },

    // ----------------- Change product type -----------------
    changeProductType(type) {
      // Set purchasable
      this.product.purchasable = type === productType.PURCHASE_ITEM
      if (type === productType.PURCHASE_ITEM) {
        this.product.sellable = false
        this.product.is_service = false
      }

      // Set composed product default
      if (type === productType.COMPOSED_PRODUCT) {
        this.product.sellable = true
        this.product.purchasable = false
        this.product.stockable = false
      }
    },

    // ----------------- Fetch resources -----------------
    async fetchResources() {
      this.loading = true

      const resourcesToFetch = [
        'branchesV1',
        'product_groups',
        'productServiceQuestions',
        'productAddonGroups',
        'taxes',
        'branchGroups',
        'tags',
        {
          resource: 'accounts',
          handler: () => th.accounts().getAll({ type: 'revenue' })
        },
        {
          resource: 'product_templates',
          handler: () =>
            th.productTemplates().getAll({ deleted: false, active: true })
        },
        {
          resource: 'locations',
          handler: () =>
            th
              .stocks()
              .getLocations({ query: { deleted: false, active: true } })
        },
        {
          resource: 'price_books',
          handler: () =>
            th
              .products()
              .pricebooks()
              .getAll({
                query: {
                  deleted: false,
                  active: true,
                  branch: this.currentLocation
                }
              })
        } /*,
        {
          resource: 'suppliers',
          errorHandler: (error) => {
            this.$logException(error, {
              trackError: false,
              message: this.$t('common.error.action.read.single', {
                resource: this.$t('common.resource.supplier.plural')
              })
            })
          },
          fallback: () => ({ data: [] })
        }*/
      ]

      if (!this.isNew) {
        resourcesToFetch.push({
          resource: 'product_branch_customizations',
          handler: () =>
            th.productBranchCustomizations().getAll({
              query: {
                deleted: false,
                active: true,
                product: this.productId
              }
            })
        })
      }

      this.resources = await this.$resourceFetch(...resourcesToFetch)
      this.resources.branches = this.resources.branchesV1

      // Create computed name for accounts
      this.resources.accounts = this.resources.accounts.map((item) => {
        return {
          ...item,
          computed_name: `${item.fa_account_number || ''} - ${item.name}`.trim()
        }
      })
      this.resources.accounts = this.resources.accounts.filter(
        (tax) => tax.deleted === false
      )

      // Create computed name for taxes
      this.resources.taxes = this.resources.taxes.map((item) => {
        return {
          ...item,
          computed_name: `${item.fa_account_number || ''} - ${item.name}`.trim()
        }
      })
      this.resources.taxes = this.resources.taxes.filter(
        (tax) => tax.deleted === false
      )

      // Create computed name for product groups
      this.resources.product_groups = this.resources.product_groups.map(
        (item) => {
          return {
            ...item,
            computed_name: `${item.product_group_id || ''} - ${
              item.name
            }`.trim()
          }
        }
      )
      this.resources.product_groups = this.resources.product_groups.filter(
        (i) => i.deleted === false
      )

      return this.resources
    },

    async reFetchTags() {
      this.loading = true
      try {
        const { tags } = await this.$resourceFetch('tags')
        this.resources.tags = tags
      } catch (error) {
        this.$logException(error, { trackError: false })
      }
      this.loading = false
    },

    async reFetchBranchCustomizations() {
      this.loading = true
      try {
        const { data = [] } = await th.productBranchCustomizations().getAll({
          query: {
            deleted: false,
            active: true,
            product: this.productId
          }
        })
        this.resources.product_branch_customizations = data
      } catch (error) {
        this.$logException(error, { trackError: false })
      }
      this.loading = false
    },

    // ----------------- Fetch product -----------------
    async fetchProduct(id) {
      this.loading = true
      try {
        this.$log.debug('products-edit: will fetch resource')
        const { data = {} } = await th.products().get(id)
        const variants = await th.products().getChildrenDetails(id, true)
        this.$log.debug('products-edit: have fetched resource')

        if (data.id) {
          data.children = variants.data //replacing variants with full variants data
          // Send to the parent to show the checkbox of propagate to variants
          this.$emit('variants', !!data?.children?.length)
          this.handleItem(data)
          this.initialProduct = this.$deepClone(data)
          this.$log.debug('products-edit: have handled form data after fetch')
        }
      } catch (err) {
        const errorMessage = this.$t(
          'pages.products.edit.form.errors.fetch.code_XXX.content'
        )
        this.$logException(err, {
          message: errorMessage,
          trackError: false
        })
        // Navigate to safety
        this.$router.push({ name: 'products-manager' })
      } finally {
        this.loading = false
      }
    },

    // ----------------- Handle cancel -----------------
    handleCancel() {
      this.$ampli.eventWithBaseProps('productBackButtonClick')
      this.goBack()
    },

    // ----------------- Handle reset -----------------
    handleReset() {
      this.product = generateDefault(this.hasGlobalDataMode)
    },

    // ----------------- Handle delete -----------------
    async fetchLinkedServices(id) {
      try {
        const inst = th.products()
        const { data = {} } = await inst.getAll({
          query: {
            is_reservations_service: true,
            deleted: false,
            linked_product_id: id
          }
        })

        return data.length
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    async handleDelete() {
      const linkedServices = await this.fetchLinkedServices(this.productId)
      const linkedServicesWarning =
        linkedServices > 0
          ? this.$t('pages.products.edit.form.delete.warning.linked_services', {
              resource: linkedServices
            })
          : ''

      const displayValue = this.product.name || this.product.id
      let append = ''
      if (this.product.type === productType.VARIANT_PRODUCT) {
        append = this.$t('pages.products.edit.warning.delete.variants.text')
      }
      const confirm = await this.$askToDelete(
        displayValue,
        null,
        append + ' ' + linkedServicesWarning
      )
      if (confirm) this.delete()
    },

    // ----------------- Delete -----------------
    async delete() {
      this.$ampli.eventWithBaseProps('productSingleDeleted', {
        product_type: this.productTypeName
      })
      this.loading = true
      try {
        // API call
        await th.products().delete(this.productId, {
          delete_dependencies:
            this.product.type === productType.VARIANT_PRODUCT || undefined
        })
        // Update product count
        this.productsStore.checkProductsCount()
        // Redirect to products
        this.$router.push({ name: 'products-manager' })
      } catch (err) {
        // Show error
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.product.singular')
        })
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    // ----------------- Prepare payload -----------------
    preparePayload(form) {
      let payload = {
        ...form,
        stockable: this.product.stockable || false,
        attributes: encodeAttributes(form.attributes), // Encode attributes
        options: encodeOptions(form.options) // Encode options
      }

      // Handle addon groups
      payload.addon_groups = payload.addon_groups.map(
        (addonGroup) => addonGroup.id
      )

      if (!payload.configuration.allow_zero_prices) {
        // Default prices
        // Remove null prices
        payload.prices.default_prices = payload.prices.default_prices.filter(
          (p) =>
            p.amount.gross !== null ||
            [undefined, null].includes(p.purchase_price) === false
        )

        // Scaled prices
        // Remove null prices
        for (const index in payload.prices.scaled_prices) {
          let scaledPrice = payload.prices.scaled_prices[index]
          scaledPrice.prices = scaledPrice.prices.filter(
            (p) => p.amount.gross !== null
          )
        }
        payload.prices.scaled_prices = payload.prices.scaled_prices.filter(
          (p) => p.prices.length !== 0
        )
      }

      // Handle stock
      payload.stock_configuration_location = this.makeHandleableStockConfig(
        form.stock_configuration_location
      )

      const filteredLinkedProducts =
        payload.linked_products?.filter(({ id }) => !!id) || []

      // Set product type to standard product if it was a product with linked products
      // but we don't have anymore linked products
      if (
        payload.type === productType.LINKED_PRODUCT &&
        filteredLinkedProducts.length === 0
      ) {
        payload.type = productType.PRODUCT
        this.showSection.linked_products = false
      }

      if (!this.showSection.linked_products) {
        payload.linked_products = null
      } else if (Array.isArray(payload.linked_products)) {
        //remove empty linked products items
        payload.linked_products = filteredLinkedProducts

        // Set product type if has linked products
        if (
          payload.type === productType.PRODUCT &&
          payload.linked_products.length > 0
        ) {
          payload.type = productType.LINKED_PRODUCT
        }
      }

      if (!this.showSection.checkout_questions) {
        payload.service_questions = null
      }

      if (!this.showSection.stock) {
        this.initialStocks = {}
      }

      if (!this.showSection.composed_product) {
        payload.components = undefined
      } else {
        // Remove empty composed products
        payload.components = payload.components.filter(
          (c) => c.product !== null && c.quantity !== null
        )
        // Clean helpers
        payload.components = payload.components.map(({ product, quantity }) => {
          return {
            product,
            quantity
          }
        })
      }

      // Handle purchase item
      if (payload.type === productType.PURCHASE_ITEM) {
        payload.type = productType.PRODUCT
        payload.purchasable = true
      }

      payload = replaceEmptyStringPropsWithNull(omit(payload, this.ommitables))
      return payload
    },

    // ----------------- Handle item -----------------
    handleItem(item) {
      this.payload = { ...item }

      const cleanedPayload = pick(item, Object.keys(this.product))
      const attributes = decodeAttributes(item.attributes) // Decode attributes from object to array
      let options = decodeOptions(item.options) // Decode options

      // Add missing attributes to children
      if (!cleanedPayload.children) cleanedPayload.children = []
      const opts = options.map((a) => a.name)
      cleanedPayload.children.forEach((c) => {
        const attrs = c.attributes
        c.attributes = {}
        opts.forEach((o) => {
          if (attrs[o]) c.attributes[o] = attrs[o]
        })
      })

      // Clear options for edit
      options = [{ name: '', values: [] }]

      // Handle purchasable
      if (isPurchaseItem(item)) {
        cleanedPayload.type = productType.PURCHASE_ITEM
      }

      // Composed product
      if (cleanedPayload.components) {
        cleanedPayload.components.forEach((item) => {
          item.product = item.product.id
        })
      }

      // Clean prices
      const cleanedPrices = {
        ...this.product.prices,
        ...cleanedPayload.prices
      }
      // FIX - Set empty array if price array is null
      // https://sentry.io/organizations/tillhub/issues/2604421118/?project=251620
      for (const key in cleanedPrices) {
        if (!cleanedPrices[key]) cleanedPrices[key] = []
      }

      // Set product
      this.product = {
        ...this.product,
        ...cleanedPayload,
        configuration: {
          ...this.product.configuration,
          ...cleanedPayload.configuration
        },
        prices: cleanedPrices,
        attributes,
        options
      }

      if (this.product.is_service) {
        this.resetServiceRelatedValues()
      }

      // Refresh the stock when the product is loaded
      this.stockConfigLoaded = this.$deepClone(
        item.stock_configuration_location
      )

      // Fix empty addon groups
      if (!this.product.addon_groups) this.product.addon_groups = []

      this.syncPristineForm()
    },

    // ----------------- Validate -----------------
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },

    // ----------------- Handle submit -----------------
    // If you use async validation function waitFor is required in tests
    async handleSubmit() {
      this.$ampli.eventWithBaseProps(
        this.isNew ? 'productCreateButtonClick' : 'productSaveButtonClick',
        {
          product_type: this.productTypeName
        }
      )

      const productValid = await this.validate() // Validate main form
      const productPricesValid = this.$refs.productPrices
        ? await this.$refs.productPrices.validate() // Validate product prices
        : true // Skip validation if no prices section

      const productPriceBooksValid = this.$refs.productPriceBooks
        ? await this.$refs.productPriceBooks.validate() // Validate product price books
        : true // Skip validation if no price books section

      const productBranchCustomizationsValid = this.$refs
        .productBranchCustomizations
        ? await this.$refs.productBranchCustomizations.validate()
        : true // Skip validation if no price books section

      const productComposedValid = this.$refs.productComposed
        ? await this.$refs.productComposed.validate() // Validate product composed
        : true // Skip validation if no composed section

      const variantProductValid = this.$refs.variantProducts
        ? await this.$refs.variantProducts.validate() // Validate variant products
        : true // Skip validation if no variant product

      const linkedProductValid =
        this.product.linked_products?.length && this.$refs.linkedProducts
          ? await this.$refs.linkedProducts.validate() // Validate linked products
          : true // Skip validation if no linked product

      const productAdditionalInformationValid = this.$refs
        .productAdditionalInformation
        ? await this.$refs.productAdditionalInformation.validate() // Validate additional information
        : true // Skip validation if no additional information

      //Creating new product tags before saving the product
      const tagsCreatedSuccessfully = this.$refs.productAdditionalInformation
        ?.canCreateTags
        ? await this.$refs.productAdditionalInformation.createNewTags()
        : true

      if (
        productValid &&
        productPricesValid &&
        productPriceBooksValid &&
        productBranchCustomizationsValid &&
        productComposedValid &&
        variantProductValid &&
        linkedProductValid &&
        productAdditionalInformationValid &&
        tagsCreatedSuccessfully
      ) {
        return this.isNew ? this.create() : this.update()
      } else {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'pages.products.edit.form.warnings.invalid_inputs.contents'
          )
        })
      }
    },

    // ----------------- Handle stock body -----------------
    makeHandleableStockConfig(data) {
      // In case of empty array, discard it
      if (!data || !data.length || !Array.isArray(data)) return null
      // In case we have just the initial object, discard it
      if (data.length === 1 && !data[0].location) return null
      // In case the product is parent of variants, it doesnt have stock
      if (this.product?.children?.length) return null
      // The filter cleans all the empty stock configs
      return data
        .filter((stockConfig) => stockConfig.location)
        .map((stockConfig) => {
          if (stockConfig.location && stockConfig.initial_stock > 0) {
            this.initialStocks[stockConfig.location] = stockConfig.initial_stock
          }

          return {
            ...omit(stockConfig, 'initial_stock')
          }
        })
    },

    // ----------------- Create -----------------
    async create() {
      this.loading = true

      // Bleach payload
      const payload = this.preparePayload(this.product)

      // Query
      const createQuery = {
        query: {
          // Automatic product number generate according to settings
          generate_product_id: this.autoGenerateId
        }
      }

      try {
        // API call
        const { data = {}, errors = [] } = await th
          .products()
          .create(payload, createQuery)

        // Log success
        this.$log.debug('products-edit: have created resource')

        if (data.id) {
          //save priceBooks entities after product created
          await this.savePriceBooksEntities(data.id)

          //save suppliers after product updated
          await this.saveLinkedSupplier(data.id)

          // Show success
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.product.singular')
            })
          })

          //save branchCustomizations entities after product created
          await this.saveBranchCustomizationsEntities(data.id)
          // Show success
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.product.singular')
            })
          })

          // Update product count
          if (!this.productsStore.productsCount) {
            this.productsStore.checkProductsCount()
          }

          // Create initial stock
          if (this.product.stockable) {
            this.createInitialStock(data.id)
          }

          // Create variants
          if (
            this.product.type === productType.VARIANT_PRODUCT &&
            this.product.children.length > 0
          ) {
            try {
              const createVariantOperations = this.createChildren(
                data,
                this.product.children
              )
              await this.showProgressBar(createVariantOperations)
              this.syncPristineForm() //after the saving was successful the pristineForm is set to the saved product
            } catch (err) {
              const errorMessage = this.$t(
                'common.error.action.create.multiple',
                {
                  resources: this.$t('common.resource.product.plural')
                }
              )
              this.$logException(err, {
                message: errorMessage
              })
            }
          }

          // Handle redirect
          if (this.createAnother) {
            const tempProductType = this.$deepClone(this.product.type) // Save product type
            this.handleReset()
            this.renderIndex++

            // Set previous product type
            this.product.type = tempProductType
            this.changeProductType(this.product.type)
            // Wait for data and elements to settle before synching pristine form.
            this.$nextTick(() => {
              this.syncPristineForm()
            })
          } else if (this.navigationAfterCreation === 'edit') {
            this.syncPristineForm()
            this.$router.push({
              name: 'products-edit',
              params: { id: data.id }
            })
          } else {
            this.handleReset()
            this.syncPristineForm()
            this.$router.push({ name: 'products-manager' })
          }
        }

        // Handle response errors
        if (errors.length) {
          this.$log.debug(
            'products-create: errors on success',
            JSON.stringify(errors, null, 2)
          )
          errors.forEach((errorObj) => {
            useMessagesStore().setLocalMessage({
              id: errorObj.id,
              label: errorObj.label,
              operation: 'local_message',
              payload: errorObj.errorDetails
            })
          })
        }
      } catch (err) {
        const errorMessage = this.$t(
          'pages.products.edit.form.errors.post.code_XXX.content'
        )
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    // ----------------- Update -----------------
    async update() {
      this.loading = true
      try {
        // Bleach payload
        const payload = this.preparePayload(this.product)
        //create or update variants
        await this.saveChildren()

        // API call
        const { data = {} } = await th.products().put(this.productId, payload)

        if (data.id) {
          //save priceBooks entities after product updated
          await this.savePriceBooksEntities(data.id)

          //save priceBooks entities after product updated
          await this.saveBranchCustomizationsEntities(data.id)

          //save suppliers after product updated
          await this.saveLinkedSupplier(data.id)

          // Show success
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.product.singular')
            })
          })
        }
        await this.fetchProduct(this.productId) // Change current data with the new
      } catch (err) {
        // Show error
        const errorMessage = this.$t('common.error.action.update.single', {
          resource: this.$t('common.resource.product.singular')
        })
        this.$logException(err, {
          message: errorMessage,
          trackError: false
        })
      } finally {
        this.loading = false
      }
    },

    async saveLinkedSupplier(productId) {
      await this.$refs.productAdditionalInformation.saveLinkedSupplier(
        productId
      )
    },

    // ----------------- Create initial stock -----------------
    async createInitialStock(productId) {
      const actions = []

      Object.keys(this.initialStocks).forEach((locationId) => {
        actions.push(() =>
          th.stocks().create({
            product: productId,
            location: locationId,
            location_type: null,
            qty: this.initialStocks[locationId]
          })
        )
      })

      if (!actions || !actions.length) return
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.stock.singular')
      })
      const successMessage = this.$t('common.success.action.create.single', {
        resource: this.$t('common.resource.stock.singular')
      })

      try {
        // API call
        await pAll(actions, { concurrency: 5 })

        // Show success
        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.initialStocks = {}
      }
    },

    // ----------------- handle PriceBooks entities ------
    async savePriceBooksEntities(productId) {
      if (this.$refs.productPriceBooks) {
        await this.$refs.productPriceBooks.save(productId)
      }
    },

    // ----------------- handle BranchCustomizations entities ------
    async saveBranchCustomizationsEntities(productId) {
      if (this.$refs.productBranchCustomizations) {
        await this.$refs.productBranchCustomizations.save(productId)
        await this.reFetchBranchCustomizations()
      }
    },

    // ----------------- Save variants -----------------
    async saveChildren() {
      if (this.product.type === productType.VARIANT_PRODUCT) {
        try {
          const createVariantOperations = this.createChildren(
            { ...this.product, id: this.productId },
            this.product.children
          )
          const propagateVariantOperations = this.propagateToVariants
            ? this.propagateToChildren({ ...this.product, id: this.productId })
            : []
          const updateVariantOperations = this.updateChildren(
            Object.values(this.updatedVariants)
          )

          //show progressbar for combined operations (create and update)
          await this.showProgressBar([
            ...createVariantOperations,
            ...propagateVariantOperations,
            ...updateVariantOperations
          ])
        } catch (err) {
          const errorMessage = this.$t(
            'pages.products.edit.form.errors.create.variant_product'
          )
          this.$logException(err, {
            message: errorMessage
          })
        }
      }
    },

    // ----------------- Create variants -----------------
    createChildren(parent, variants) {
      const inst = th.products()
      const operations = variants
        // Create only new variants
        .filter((v) => v.id === undefined)
        // Base value inheritance stage
        .map((item, index) => {
          const createQuery = {
            query: {
              generate_product_id: false
            }
          }

          // Strip out empty value attributes
          const attributes = item.attributes
          Object.keys(attributes)
            .filter((key) => attributes[key] === '')
            .forEach((key) => delete attributes[key])

          return async () => {
            item.custom_id = parent.custom_id + '-' + (index + 1)

            // Create child payload
            const obj = {
              type: 'variant',
              parent: parent.id,
              active: parent.active,
              stockable: parent.stockable,
              is_service: parent.is_service,
              images: parent.images,
              summary: parent.summary,
              description: parent.description,
              external_reference_id: parent.external_reference_id,
              manufacturer: parent.manufacturer,
              sku: parent.sku,
              supplier: parent.supplier,
              tags: parent.tags,
              brand: parent.brand,
              locations: parent.locations,
              branch_groups: parent.branch_groups,
              service_questions: parent.service_questions,
              discountable: parent.discountable,
              default_tile_color: parent.default_tile_color,
              attributes: attributes,
              custom_id: item.custom_id,
              product_group: item.product_group || parent.product_group,
              account: item.account || parent.account,
              tax: item.tax || parent.tax,
              name: item.name,
              prices: {
                default_prices: item.prices.default_prices,
                branch_prices: [],
                scaled_prices: parent.prices.scaled_prices,
                time_based_prices: []
              }
            }

            // Add stock_configuration_location
            if (parent.stockable && this.hasOneBranch) {
              obj.stock_configuration_location = [
                {
                  location: this.resources.branches[0].id,
                  reorder_point: null,
                  reorder_qty: null,
                  stock_minimum: null
                }
              ]
            }

            // Add barcodes
            if (item.barcodes) {
              obj.barcodes = item.barcodes
            }

            // Calculate price
            const price = item.prices.default_prices[0]
            if (typeof price?.amount?.gross === 'number') {
              const { gross, net, margin } = priceHelper.onGross(
                price.amount.gross,
                {},
                this.getTaxRate(item.tax || parent.tax)
              )
              const purchasePrice = obj.prices.default_prices[0]
              purchasePrice.amount = { gross, net }
              purchasePrice.margin = margin
              purchasePrice.currency = this.defaultCurrency
            }

            // Create child product
            try {
              const childResponse = await inst.create(obj, createQuery)

              // Create child initial stock if we have just one branch and the parent is stockable
              if (
                parent.stockable &&
                item.initial_stock !== undefined &&
                this.hasOneBranch
              ) {
                await th.stocks().create({
                  product: childResponse.data.id,
                  location: this.resources.branches[0].id,
                  location_type: 'branch',
                  qty: item.initial_stock
                })
              }
            } catch (err) {
              // Show error
              const errorMessage = this.$t(
                'pages.products.edit.form.errors.create.variant_product.stock'
              )
              this.$logException(err, {
                message: errorMessage
              })
            }
          }
        })

      return operations
    },

    // ----------------- Create variants -----------------
    updateChildren(variants) {
      const inst = th.products()
      const operations = variants.map((item) => {
        return async () => {
          const { id, name, prices, barcodes } = item
          // Update child product
          try {
            const payload = {
              name,
              prices,
              barcodes
            }

            const defaultPrice = payload.prices?.default_prices?.[0]
            if (defaultPrice) {
              // re-calculate price object
              const { gross, net, margin } = priceHelper.onGross(
                defaultPrice.amount.gross,
                {},
                this.getTaxRate(item.tax)
              )
              defaultPrice.amount = { gross, net }
              defaultPrice.margin = margin
            }

            await inst.put(id, payload)
          } catch (err) {
            // Show error
            const errorMessage = this.$t(
              'pages.products.edit.form.errors.create.variant_product.stock'
            )
            this.$logException(err, {
              message: errorMessage
            })
          }
        }
      })
      return operations
    },

    // ----------------- Propagate parent changes to variants -----------------
    propagateToChildren(modifiedParent) {
      const parentChanges = this.getParentDiff(
        this.initialProduct,
        modifiedParent
      )
      const payload = {}
      parentChanges.forEach((diff) => {
        // In case any part of the prices were changed, change the full prices object
        if (diff.path.includes('prices')) {
          payload.prices = modifiedParent.prices
        } else if (diff.path.includes('tags')) {
          // In case any part of the tags were changed, change the full tags array
          payload.tags = modifiedParent.tags
        } else {
          safeSet(payload, diff.path, diff.value)
        }
      })
      if (parentChanges.length && Object.keys(payload)?.length) {
        const inst = th.products()
        const operations = this.initialProduct.children.map((item) => {
          return async () => {
            try {
              await inst.put(item.id, payload)
            } catch (err) {
              // Show error
              const errorMessage = this.$t(
                'pages.products.edit.form.errors.edit.variant_product.propagate'
              )
              this.$logException(err, {
                message: errorMessage
              })
            }
          }
        })
        return operations
      } else {
        return []
      }
    },

    getParentDiff(oldParent, newParent) {
      const fieldToCheck = [
        'active',
        'stockable',
        'is_service',
        'images',
        'summary',
        'description',
        'external_reference_id',
        'manufacturer',
        'sku',
        'supplier',
        'tags',
        'brand',
        'locations',
        'branch_groups',
        'service_questions',
        'discountable',
        'default_tile_color',
        'product_group',
        'account',
        'tax',
        'prices'
      ]
      const oldParentPick = pick(oldParent, fieldToCheck)
      const newParentPick = pick(newParent, fieldToCheck)
      return diff(oldParentPick, newParentPick)
    },

    getTaxRate(taxId) {
      const tax = this.resources.taxes.find(({ id }) => id === taxId)
      return tax?.rate
    },

    // ---------- Show variant saving progress -----------
    async showProgressBar(operations) {
      if (!operations.length) return
      this.handleProgressStart()
      const limit = pLimit(1)

      let counter = 0
      const ops = operations.map((fn) => {
        // handle concurrency
        return limit(async () => {
          // handle random and specfic errors in a retry
          const run = async () => {
            // fn is the create op
            const response = await fn.call()
            return response
          }
          try {
            await pRetry(run, {
              retries: 3,
              minTimeout: 100,
              maxTimeout: 1000,
              randomize: true
            })
          } catch (err) {
            this.childOpertation.progressStatus = 'exception'
            this.childOpertation.errors.push({
              product: {},
              err
            })
            this.$logException(err)
          }
          counter++
          this.childOpertation.progress = counter / ops.length
        })
      })
      try {
        const result = await Promise.all(ops)
        this.handleProgressEnd('success')
        return result
      } catch (err) {
        this.handleProgressEnd('exception')
        throw err
      }
    },

    // ---------- Handle product is a service -----------
    resetServiceRelatedValues() {
      //reset purchase price and recalculate margin
      const defaultPrice =
        safeGet(this.product, ['prices', 'default_prices', 0]) || {}
      if (this.product.tax) {
        const { purchasePrice, margin } = priceHelper.onPurchasePrice(
          0,
          defaultPrice,
          this.getTaxRate(this.product.tax)
        )
        defaultPrice.purchase_price = purchasePrice
        defaultPrice.margin = margin
      } else {
        // Skip recalculation if tax is not selected
        defaultPrice.purchase_price = 0
      }

      //reset track stock to false
      this.product.stockable = false
    },

    handleProgressStart() {
      this.childOpertation.showProgress = true
      this.childOpertation.inProgress = true
      this.childOpertation.progress = 0
      this.childOpertation.progressStatus = null
    },

    handleProgressEnd(progressStatus) {
      this.childOpertation.progressStatus = progressStatus
      this.childOpertation.inProgress = false
      this.childOpertation.progress = 1
      setTimeout(() => {
        this.childOpertation.showProgress = false
      }, 2000)
    },

    markVariantChange(variant) {
      if (!variant.id) return //if variant isNew we will use create instead of update
      this.updatedVariants[variant.id] = variant
    },

    showJsonEditor() {
      if (!this.hasGlobalDataMode) return
      this.isModalOpen = true
    }
  }
}
</script>
<style scoped>
.jsonWrapper {
  height: 650px;
  width: 100%;
  max-width: 800px;
}
</style>
