<template>
  <th-page-wrapper
    :key="$route.params.id"
    class="product-edit"
    color="var(--font-color)"
  >
    <product-form
      ref="form"
      :create-another="createAnother"
      :propagate-to-variants="propagateToVariants"
      @variants="setHasVariants"
    />

    <!-- Actions -->
    <template #actions>
      <div class="actions">
        <!-- Delete -->
        <el-button
          v-if="!isNew"
          v-permissions="{
            scopes: ['products:delete']
          }"
          class="edit-actions-delete"
          type="danger"
          plain
          @click="handleDelete"
        >
          {{ $t('common.interactions.buttons.delete') }}
        </el-button>
        <div class="flex-grow" />

        <!-- Propagate from parent to variants on edition -->
        <el-checkbox
          v-if="!isNew && hasVariants"
          v-model="propagateToVariants"
          class="edit-actions-propagate-to-variants mx-3"
        >
          {{ $t('common.interactions.checkboxes.propagate_to_variants') }}
        </el-checkbox>

        <!-- Create another one -->
        <el-checkbox
          v-if="isNew"
          v-model="createAnother"
          class="edit-actions-create-another mx-3"
        >
          {{ $t('common.interactions.checkboxes.create_another') }}
        </el-checkbox>

        <!-- Cancel -->
        <el-button class="xs:hidden" @click="handleCancel">
          {{ $t('common.interactions.buttons.back') }}
        </el-button>

        <!-- Edit JSON -->
        <el-button v-if="hasGlobalDataMode" @click="handleOpenJsonEditor">
          {{ $t('common.interactions.buttons.edit_json') }}
        </el-button>

        <!-- Update -->
        <el-button
          v-if="!isNew"
          v-permissions="{ scopes: ['products:update'] }"
          type="primary"
          @click="handleSubmit"
        >
          {{ $t('common.interactions.buttons.save') }}
        </el-button>

        <!-- Create -->
        <el-button
          v-else
          v-permissions="{ scopes: ['products:create'] }"
          type="primary"
          @click="handleSubmit"
        >
          {{ $t('common.interactions.buttons.create') }}
        </el-button>
      </div>
    </template>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductForm from './components/form'

export default {
  components: {
    ProductForm
  },

  // ----------------- Before route leave -----------------
  beforeRouteLeave(to, from, next) {
    this.checkLeave((_, canLeave) => {
      if (canLeave) return next()
      return next(false)
    })
  },

  data() {
    return {
      createAnother: false,
      propagateToVariants: false,
      hasVariants: false
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },
    ...mapGetters({
      hasGlobalDataMode: 'Config/hasGlobalDataMode'
    })
  },

  methods: {
    // ----------------- Handle cancel -----------------
    handleCancel() {
      this.$refs.form.handleCancel()
    },

    // ----------------- Handle delete -----------------
    handleDelete() {
      this.$refs.form.handleDelete()
    },

    // ----------------- Handle submit -----------------
    handleSubmit() {
      this.$refs.form.handleSubmit()
    },

    // ----------------- Check leave -----------------
    checkLeave(cb) {
      if (!this.$refs.form.isDirty) return cb(null, true)
      this.$confirm(
        this.$t('pages.settings.component.warnings.unsaved.message'),
        this.$t('pages.settings.component.warnings.unsaved.title'),
        {
          confirmButtonText: this.$t('common.interactions.buttons.ok'),
          cancelButtonText: this.$t('common.interactions.buttons.cancel'),
          type: 'warning'
        }
      )
        .then(() => {
          return cb(null, true)
        })
        .catch((err) => {
          return cb(err, false)
        })
    },
    handleOpenJsonEditor() {
      this.$refs.form.showJsonEditor()
    },
    setHasVariants(hasVariants) {
      this.hasVariants = hasVariants
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0px 2rem;
  background: white;
}

.product-edit :deep(.content) {
  padding: 2rem;
}

@media (max-width: 640px) {
  .product-edit :deep(.content) {
    padding: 0.5rem;
  }
}
</style>
