<template>
  <div class="scaled-prices" data-testid="scaled-prices">
    <hr class="mt-6 mb-10" />

    <div class="flex mb-8">
      <!-- Help -->
      <th-popover class="mr-2" placement="top-start">
        <div
          class="max-w-sm text-th-primary break-normal"
          :class="{ italic: !isWhiteLabel }"
          v-text="
            $t(
              'pages.products.edit.form.sections.prices.configuration.scaled_price.help'
            )
          "
        />
      </th-popover>

      <!-- Title -->
      <div
        class="font-bold text-sm"
        v-text="
          $t(
            'pages.products.edit.form.sections.prices.configuration.scaled_price.title'
          )
        "
      />
    </div>

    <!-- Empty -->
    <div
      v-if="form.length === 0"
      class="mb-4 text-sm"
      :class="{ italic: !isWhiteLabel }"
    >
      {{ $t('pages.products.edit.form.sections.prices.common.no_prices') }}
    </div>

    <!-- Prices -->
    <el-form
      v-for="(scaledPrice, scaledPriceIndex) in form"
      ref="form"
      :key="'scaled-prices-' + scaledPriceIndex"
      :rules="rules"
      :model="scaledPrice"
    >
      <el-row>
        <el-col :md="6" :xl="3">
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.sections.prices.configuration.scaled_price.qty.title'
              )
            "
            prop="qty"
          >
            <th-number-input
              v-model="scaledPrice.qty"
              :precision="3"
              :clearable="false"
              :locale="$i18n.locale"
              size="large"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <scaled-price-prices
        :ref="'scaled-price-prices-' + scaledPriceIndex"
        v-model="scaledPrice.prices"
        :tax="tax"
        :is-service-product="isServiceProduct"
        @on-price-delete="handleDelete(scaledPriceIndex)"
      />

      <hr class="mb-6 mt-3" />
    </el-form>

    <!-- Add -->
    <el-button text class="block -mt-2" @click="add">
      <el-icon><Plus /></el-icon>
      <span>
        {{
          $t(
            'pages.products.edit.form.sections.prices.configuration.scaled_price.add'
          )
        }}
      </span>
    </el-button>
  </div>
</template>

<script>
import ScaledPricePrices from './scaled-price-prices'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    ScaledPricePrices
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },
    tax: {
      type: Object,
      required: true
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      rules: {
        qty: [
          {
            validator: (rule, value, callback) => {
              if (Number(value) <= 1) {
                callback(
                  new Error(
                    this.$t(
                      'pages.products.edit.form.sections.prices.configuration.scaled_price.qty.error'
                    )
                  )
                )
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  methods: {
    handleDelete(index) {
      // Remove when last price is deleted
      if (this.form[index]?.prices.length === 0) {
        this.form.splice(index, 1)
      }
    },

    add() {
      // Add new scaled price
      this.form.push({
        qty: 2,
        prices: []
      })
      // Add first price to it
      this.$nextTick(() => {
        const pricesElement = this.$refs[
          'scaled-price-prices-' + (this.form.length - 1)
        ]
        pricesElement[0].addPrice()
      })
    },

    async validateForm(form) {
      return new Promise((resolve) => {
        form.validate(resolve)
      })
    },

    async validate() {
      if (this.$refs.form) {
        for (const form of this.$refs.form) {
          if (!(await this.validateForm(form))) return false
        }
      }
      return true
    }
  }
}
</script>
