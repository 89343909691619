<template>
  <th-wrapper
    collapsable
    collapsed-by-default
    :title="$t('pages.products.edit.form.sections.takeaway.title')"
    @collapsed-changed="handleCollapsedChanged"
  >
    <!-- Body -->
    <el-row :gutter="20">
      <el-col :md="6">
        <el-form-item
          :label="
            $t('pages.product_groups.edit.form.properties.takeaway.tax.label')
          "
          prop="takeaway_tax"
        >
          <el-select
            id="takeaway_tax"
            v-model="form.takeaway_tax"
            v-cancel-read-only
            filterable
            clearable
            class="w-full"
            :placeholder="$t('common.interactions.buttons.select')"
          >
            <el-option
              v-for="item in resources.taxes"
              :key="item.id"
              :label="item.computed_name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :md="6">
        <el-form-item
          :label="
            $t(
              'pages.product_groups.edit.form.properties.takeaway.account.label'
            )
          "
          prop="takeaway_account"
        >
          <el-select
            id="takeaway_account"
            v-model="form.takeaway_account"
            v-cancel-read-only
            filterable
            clearable
            class="w-full"
            :placeholder="$t('common.interactions.buttons.select')"
          >
            <el-option
              v-for="item in resources.accounts"
              :key="item.id"
              :label="item.computed_name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },

  methods: {
    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.takeaway.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>
