import isEmpty from 'just-is-empty'

const regexMapping = {
  IS_WAITING_FOR_INPUT: /(,|\s)$/, //string ends with space or comma
  ALL_CHARS_EXCEPT_ALLOWED: /[^a-zA-Z0-9\-,]/g, //filter all characters except alphanumeric, commas or dashes
  ALL_CHARS_EXCEPT_ALLOWED_AND_SPACE: /[^a-zA-Z0-9\-,\s]/g //filter all characters except alphanumeric, commas, dashes or spaces
}

//if last character is space or comma, return true
export const isWaitingForInput = (value = '') =>
  regexMapping.IS_WAITING_FOR_INPUT.test(value)

//remove all characters except alphanumeric, commas, dashes or spaces
export const clearBarcodeString = (value = '') =>
  value.replace(regexMapping.ALL_CHARS_EXCEPT_ALLOWED_AND_SPACE, '')

//Parse barcodes string into an array
export const parseBarcodeAsArray = (value = '') =>
  value
    .replace(regexMapping.ALL_CHARS_EXCEPT_ALLOWED, '') //remove all characters except alphanumeric, commas and dashes
    .split(',') //split string to array
    .filter((v) => !isEmpty(v)) //remove empty barcodes
