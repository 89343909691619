<template>
  <el-input
    :id="id"
    :model-value="formatedBarcodes"
    :placeholder="
      $t('pages.products.edit.form.sections.standard.barcodes.placeholder')
    "
    @update:modelValue="setBarcodes"
  />
</template>

<script>
import {
  isWaitingForInput,
  clearBarcodeString,
  parseBarcodeAsArray
} from './helpers'

export default {
  name: 'ThBarcodesInput',
  props: {
    id: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formatedBarcodes: ''
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        //transform array to nice printed string
        this.formatedBarcodes = (this.modelValue || []).join(', ')
      }
    }
  },
  methods: {
    setBarcodes(value) {
      //if last character is space or comma, wait for additional user input before setting the value
      if (isWaitingForInput(value)) {
        //remove all characters except numbers, commas, dashes or spaces
        this.formatedBarcodes = clearBarcodeString(value)
        return
      }
      this.$emit('update:modelValue', parseBarcodeAsArray(value))
    }
  }
}
</script>
