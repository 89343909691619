<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-row :gutter="20">
      <el-col :md="10">
        <el-row :gutter="20">
          <el-col :md="24">
            <!-- Locations -->
            <el-form-item
              :prop="locationName"
              :label="
                $t(
                  'pages.products.edit.branch_customizations.properties.available_in.label'
                )
              "
            >
              <available-in
                :id="locationName"
                :model-value="{
                  locations: form.branches,
                  branch_groups: form.branch_groups
                }"
                :resources="availableResources"
                :show-items-limit="2"
                :hide-available-nowhere="true"
                :hide-available-everywhere="true"
                :send-to-id="true"
                @[locationName]="handleAvailableInInput"
              />
            </el-form-item>
          </el-col>
          <el-col :md="9">
            <!-- Name -->
            <el-form-item
              prop="customName"
              :label="
                $t(
                  'pages.products.edit.form.sections.branch_customizations.product_name.label'
                )
              "
            >
              <el-input
                id="customName"
                v-model="form.name"
                data-testid="customName"
                :placeholder="
                  $t(
                    'pages.products.edit.form.sections.branch_customizations.product_name.label'
                  )
                "
                data-lpignore="true"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                maxlength="512"
              />
            </el-form-item>
          </el-col>
          <el-col :md="15">
            <!-- Short description (max 100 chars) -->
            <el-form-item
              prop="summary"
              :label="
                $t(
                  'pages.products.edit.form.sections.branch_customizations.summary.label'
                )
              "
            >
              <el-input
                id="summary"
                v-model="form.summary"
                :placeholder="
                  $t(
                    'pages.products.edit.form.sections.branch_customizations.summary.label'
                  )
                "
                data-lpignore="true"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                resize="horisontal"
              />
            </el-form-item>
          </el-col>
          <el-col :md="24">
            <!-- Long description (max 1000 chars) -->
            <el-form-item
              prop="description"
              :label="
                $t(
                  'pages.products.edit.form.sections.branch_customizations.description.label'
                )
              "
            >
              <el-input
                id="description"
                v-model="form.description"
                type="textarea"
                :rows="3"
                :placeholder="
                  $t(
                    'pages.products.edit.form.sections.branch_customizations.description.label'
                  )
                "
                resize="horizontal"
                data-lpignore="true"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="12">
        <el-row :gutter="20">
          <el-col :md="18">
            <!-- Tile colours for Till -->
            <el-form-item
              prop="default_tile_color"
              :label="
                $t(
                  'pages.products.edit.form.sections.branch_customizations.default_tile_color.label'
                )
              "
            >
              <div
                class="default-tile-color-wrap grid gap-4 w-full p-4 rounded border border-gray-400 grid-cols-4"
              >
                <button
                  v-for="(color, colorIndex) in availableColors"
                  :key="color"
                  :data-testid="'custom-tile-color-' + colorIndex"
                  :class="{ active: form.default_tile_color === color }"
                  :style="{ background: color }"
                  class="tile-color w-fill h-full rounded border border-gray-400 relative"
                  native-type="button"
                  @click.prevent="onSelectedColor(color)"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :md="6" class="lg:mt-24">
            <!-- Delete -->
            <el-button
              plain
              type="text-icon"
              icon="Delete"
              data-testid="branch-customization-delete"
              class="-ml-4"
              :title="$t('common.interactions.buttons.remove')"
              @click="$emit('delete', index)"
            />

            <!-- Add -->
            <el-button
              v-if="isLast"
              plain
              type="primary-icon"
              icon="Plus"
              :title="$t('common.interactions.buttons.add')"
              @click="$emit('add')"
            />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <hr v-show="!isLast" class="my-6" />
  </el-form>
</template>

<script>
import AvailableIn from '@/components/available-in'
export default {
  components: { AvailableIn },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    usedBranchesIds: {
      type: Array,
      default: () => []
    },
    usedBranchGroupsIds: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    },
    isLast: {
      type: Boolean,
      required: false
    },
    isNew: {
      type: Boolean,
      default: true
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      branchError: false,
      branchGroupError: false,
      availableColors: [
        '#e67482',
        '#f7e7d6',
        '#f7ed73',
        '#d0eeb2',
        '#c7e4f9',
        '#79c1f6',
        '#b86dfa',
        '#232e3d'
      ]
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    locationName() {
      return `locations-${this.index}`
    },
    rules() {
      return {
        [this.locationName]: [
          {
            validator: this.validateLocation,
            trigger: 'blur'
          }
        ],
        name: [
          {
            validator: this.validateCustomization,
            trigger: 'blur'
          }
        ],
        summary: [
          {
            validator: this.validateCustomization,
            trigger: 'blur'
          }
        ],
        description: [
          {
            validator: this.validateCustomization,
            trigger: 'blur'
          }
        ],
        default_tile_color: [
          {
            validator: this.validateCustomization,
            trigger: 'blur'
          }
        ]
      }
    },
    availableBranches() {
      return (
        this.resources?.branches?.filter(({ id }) => {
          return (
            !this.usedBranchesIds.includes(id) ||
            this.form?.branches?.includes(id)
          )
        }) || []
      )
    },
    availableBranchGroups() {
      return (
        this.resources?.branchGroups?.filter(({ id }) => {
          return (
            !this.usedBranchGroupsIds.includes(id) ||
            this.form?.branch_groups?.includes(id)
          )
        }) || []
      )
    },
    availableResources() {
      return {
        branches: this.availableBranches,
        branchGroups: this.availableBranchGroups
      }
    },
    dayTranslations() {
      return {
        monday: this.$t('common.days.short.monday'),
        tuesday: this.$t('common.days.short.tuesday'),
        wednesday: this.$t('common.days.short.wednesday'),
        thursday: this.$t('common.days.short.thursday'),
        friday: this.$t('common.days.short.friday'),
        saturday: this.$t('common.days.short.saturday'),
        sunday: this.$t('common.days.short.sunday')
      }
    }
  },
  methods: {
    onSelectedColor(color) {
      if (this.form.default_tile_color === color) {
        // Remove tile color if same is clicked
        this.form.default_tile_color = null
      } else {
        // Set color
        this.form.default_tile_color = color
      }
    },
    handleAvailableInInput(available) {
      // Check branches
      const availableBranchesId = []
      this.availableBranches.forEach((branch) =>
        availableBranchesId.push(branch.id)
      )
      const repeatedBranches =
        available?.locations?.filter(
          (value) => !availableBranchesId.includes(value)
        ) || []
      this.branchError = !!repeatedBranches.length
      this.form.branches = available.locations

      // Check branch groups
      const availableBranchGroupsId = []
      this.availableBranchGroups.forEach((branchGroup) =>
        availableBranchGroupsId.push(branchGroup.id)
      )
      const repeatedBranchGroups =
        available?.branch_groups?.filter(
          (value) => !availableBranchGroupsId.includes(value)
        ) || []
      this.branchGroupError = !!repeatedBranchGroups.length
      this.form.branch_groups = available.branch_groups
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },
    validateCustomization(_, value, callback) {
      if (
        this.form.name ||
        this.form.summary ||
        this.form.description ||
        this.form.default_tile_color
      )
        return callback()
      callback(new Error(this.$t('common.forms.rules.field_warnings.required')))
    },
    validateLocation(_, value, callback) {
      if (!this.form.branches?.length && !this.form.branch_groups?.length) {
        return callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      } else if (this.branchError) {
        return callback(
          new Error(
            this.$t('common.forms.rules.field_warnings.branch_repeated')
          )
        )
      } else if (this.branchGroupError) {
        return callback(
          new Error(
            this.$t('common.forms.rules.field_warnings.branch_group_repeated')
          )
        )
      } else {
        return callback()
      }
    }
  }
}
</script>
<style scoped>
.default-tile-color-wrap {
  height: 190px;
}

.default-tile-color-wrap .tile-color:hover {
  border-color: var(--border-color-hover);
}

.default-tile-color-wrap .tile-color:active {
  border-color: var(--border-color-focus);
}

.default-tile-color-wrap .tile-color.active::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  border: 3px solid var(--primary-color);
}
</style>
