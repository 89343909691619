<template>
  <th-wrapper
    body-class="p-0"
    collapsable
    header-class="bg-th-primary-light pb-5 pt-5"
    collapsed-by-default
    @collapsed-changed="handleCollapsedChanged"
  >
    <!-- Title -->
    <template #title>
      <div class="flex items-center">
        <th-popover
          class="mr-3"
          placement="top-start"
          :text="
            $t('pages.products.edit.form.sections.checkout_questions.tooltip')
          "
        />
        <span>
          <span>
            {{
              $t('pages.products.edit.form.sections.checkout_questions.label')
            }}
          </span>
          <span v-if="checkoutQuestionsLength" class="ml-1">
            ({{ checkoutQuestionsLength }})
          </span>
        </span>
      </div>
    </template>

    <!-- Body -->
    <el-form ref="checkoutQuestionForm" class="p-0">
      <div class="th-table-responsive shadow-th-light">
        <table class="th-table">
          <thead>
            <tr class="h-12">
              <th class="font-bold">
                <label for="checkout-questions-select">
                  {{
                    $t(
                      'pages.products.edit.form.sections.checkout_questions.title'
                    )
                  }}
                </label>
              </th>

              <th class="w-20" />
            </tr>
          </thead>

          <tbody>
            <tr class="border-b align-middle">
              <!-- Product UUID -->
              <td class="py-4">
                <el-form-item class="mb-0">
                  <el-select
                    id="checkout-questions-select"
                    value=""
                    class="w-full"
                    :placeholder="
                      $t(
                        'pages.products.edit.form.sections.checkout_questions.placeholder'
                      )
                    "
                    @change="addCheckoutQuestion"
                  >
                    <el-option
                      v-for="{ name, id } in questions"
                      :key="id"
                      :label="name"
                      :value="id"
                    />
                  </el-select>
                </el-form-item>
              </td>
            </tr>
            <tr
              v-for="(id, index) of checkoutQuestions"
              :key="id"
              class="border-b align-middle"
            >
              <td>
                <span>{{ getQuestionName(id) }}</span>
              </td>
              <td class="w-0">
                <el-button
                  :aria-label="
                    $t(
                      'pages.products.edit.form.sections.checkout_questions.remove_checkout_question'
                    )
                  "
                  plain
                  icon="Delete"
                  class="el-button--text-icon"
                  @click="removeCheckoutQuestion(index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-form>
  </th-wrapper>
</template>

<script>
export default {
  name: 'EditProductCheckoutQuestions',
  props: {
    modelValue: {
      type: [Array, null],
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      checkoutQuestions: []
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue || []
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    questions() {
      return this.resources.productServiceQuestions?.reduce(
        (questions, { name, id }) => {
          if (!this.checkoutQuestions?.includes(id)) {
            questions.push({ name, id })
          }
          return questions
        },
        []
      )
    },
    checkoutQuestionsLength() {
      return this.checkoutQuestions?.length
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        if (value) {
          this.checkoutQuestions = value
        }
      }
    }
  },
  methods: {
    addCheckoutQuestion(checkoutQuestion) {
      if (!checkoutQuestion) return
      this.form = [...this.checkoutQuestions, checkoutQuestion]
    },
    removeCheckoutQuestion(index) {
      this.checkoutQuestions.splice(index, 1)
      this.form = this.checkoutQuestions
    },
    getQuestionName(questionId) {
      const question = this.resources.productServiceQuestions.find(
        ({ id }) => id === questionId
      )
      return question?.name || '-'
    },
    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.checkout_questions.label',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>
