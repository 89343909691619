<template>
  <th-wrapper
    class="product-prices"
    collapsable
    collapsed-by-default
    :title="$t('pages.products.edit.form.sections.prices.title')"
    @collapsed-changed="handleCollapsedChanged"
  >
    <div>
      <!-- Default prices -->
      <default-prices
        v-model="form.prices.default_prices"
        :tax="tax"
        :is-service-product="isServiceProduct"
      />

      <!-- Scaled prices -->
      <scaled-prices
        ref="scaledPrices"
        v-model="form.prices.scaled_prices"
        :tax="tax"
        :is-service-product="isServiceProduct"
      />
    </div>
  </th-wrapper>
</template>

<script>
import DefaultPrices from './prices/default-prices'
import ScaledPrices from './prices/scaled-prices'

export default {
  components: {
    DefaultPrices,
    ScaledPrices
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    resources: {
      type: Object,
      default: () => {}
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    tax() {
      return (
        this.resources.taxes.find((t) => t.id === this.form.tax) ||
        this.resources.taxes[0]
      )
    }
  },

  methods: {
    addScaledPrice() {
      this.$refs.scaledPrices.add()
    },

    async validate() {
      return this.$refs.scaledPrices.validate()
    },

    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.prices.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>

<style scoped>
.product-prices :deep(.el-form-item) {
  margin-bottom: 1rem;
}
</style>
