<template>
  <el-container class="variants-template-modal">
    <el-header class="h-auto p-8 pb-0">
      <h2 class="m-0">
        {{
          $t(
            'pages.products.edit.form.sections.variants.modal.save_template.title'
          )
        }}
      </h2>
    </el-header>

    <el-main class="px-8 pb-4">
      <el-form ref="form" :rules="rules" :model="form">
        <el-form-item
          :label="
            $t(
              'pages.products.edit.form.sections.variants.modal.save_template.template_name.label'
            )
          "
          prop="name"
          class="mb-4"
        >
          <el-input
            id="name"
            v-model="form.name"
            :placeholder="
              $t(
                'pages.products.edit.form.sections.variants.modal.save_template.template_name.placeholder'
              )
            "
          />
        </el-form-item>
      </el-form>

      <div>
        <div v-for="(option, index) in options" :key="index" class="mb-2">
          <div class="mb-2 text-sm text-gray-700">{{ option.name }}</div>
          <el-tag
            v-for="(value, valueIndex) in option.values"
            :key="valueIndex"
            class="mr-2 mb-2"
          >
            {{ value }}
          </el-tag>
        </div>
      </div>
    </el-main>

    <el-footer class="flex justify-end items-center border-gray-300">
      <el-button plain @click="handleCancel">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>

      <el-button type="primary" @click="handleSave">
        {{ $t('common.interactions.buttons.save') }}
      </el-button>
    </el-footer>
  </el-container>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  props: {
    options: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      form: {
        name: ''
      },
      rules: {
        name: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required')
        }
      }
    }
  },

  methods: {
    handleCancel() {
      this.$emit('cancel')
    },

    async handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          return this.create()
        } else {
          return this.$message({
            type: 'warning',
            message: this.$t(
              'pages.products.edit.form.warnings.invalid_inputs.contents'
            )
          })
        }
      })
    },

    async create() {
      try {
        const inst = th.productTemplates()

        // FROM
        // [
        //   {
        //     name: 'Color',
        //     values: ['Red', 'Green']
        //   },
        //   {
        //     name: 'Size',
        //     values: ['Large', 'Small']
        //   }
        // ]
        // TO
        // {
        //   'Color': ['Red', 'Green'],
        //   'Size': ['Large', 'Small']
        // }
        const options = {}
        this.options.forEach((o) => {
          options[o.name] = o.values
        })

        await inst.create({
          name: this.form.name,
          option_template: options
        })

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.create.single', {
            resource: this.$t('common.resource.product_template.singular')
          })
        })

        this.$emit('cancel')
      } catch (err) {
        // Show error
        const errorMessage = this.$t('common.error.action.create.single', {
          resource: this.$t('common.resource.product_template.singular')
        })
        this.$logException(err, {
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped>
.variants-template-modal {
  max-height: 80vh;
}
</style>
