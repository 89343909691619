<template>
  <th-wrapper
    header-class="bg-th-primary-light pb-4"
    collapsable
    body-class="p-0"
    :disabled="form.name === null || form.name === ''"
    :title="title"
    :subtitle="$t('pages.products.edit.form.sections.variants.subtitle')"
    :info="$t('pages.products.edit.form.sections.variants.info')"
    @collapsed-changed="handleCollapsedChanged"
  >
    <!-- Center actions -->
    <template v-if="isNew" #center>
      <!-- Automatic mode -->
      <el-checkbox v-model="automaticMode" class="mr-4">
        {{
          $t('pages.products.edit.form.sections.variants.automatic_mode.label')
        }}
      </el-checkbox>
    </template>

    <!-- Actions -->
    <template v-if="isNew" #actions>
      <!-- More -->
      <el-popover trigger="click">
        <div class="-m-1">
          <!-- Load from template -->
          <el-button
            class="text-left block w-full m-0 py-2 px-5 text-sm text-th-primary hover:text-th-secondary"
            text
            :disabled="!isNew"
            @click="handleLoadTemplate"
          >
            <span>
              {{
                $t(
                  'pages.products.edit.form.sections.variants.actions.load_from_template'
                )
              }}
            </span>
          </el-button>

          <!-- Save as template -->
          <el-button
            class="text-left block w-full m-0 py-2 px-5 text-sm text-th-primary hover:text-th-secondary"
            text
            @click="handleSaveAsTemplate"
          >
            <span>
              {{
                $t(
                  'pages.products.edit.form.sections.variants.actions.save_as_template'
                )
              }}
            </span>
          </el-button>

          <!-- Remove -->
          <el-button
            class="text-left block w-full m-0 py-2 px-5 text-sm text-th-primary hover:text-th-secondary"
            text
            @click="handleRemoveAll"
          >
            <span>
              {{
                $t(
                  'pages.products.edit.form.sections.variants.actions.remove_all'
                )
              }}
            </span>
          </el-button>
        </div>
        <template #reference>
          <el-button
            :aria-label="$t('nav.main.items.audits.actions')"
            text
            class="text-lg p-2 mr-2 text-th-primary-blue"
            icon="More"
          />
        </template>
      </el-popover>
    </template>

    <!-- Variants editor -->
    <template #header-additional>
      <variants-editor
        v-if="form.options"
        ref="variantsEditor"
        v-model="form.options"
        :resources="resources"
        :automatic-mode="automaticMode"
        @change="variantOptionsChanged"
        @manual-apply="variantOptionsApply"
      />
    </template>

    <!-- Body -->
    <div>
      <div class="th-table-responsive shadow-th-light">
        <table class="th-table">
          <thead>
            <tr>
              <table-header-cell
                v-for="cell in headerCells"
                :key="cell.key"
                class="min-w-field"
                is-sortable
                v-bind="cell"
                @click="setSortingOptions(cell.key)"
              />

              <th class="text-right" style="width: 1%">
                <el-dropdown
                  v-if="isNew"
                  :hide-on-click="false"
                  @command="toggleAdditionalParameter"
                >
                  <el-icon class="text-th-secondary text-lg px-2"
                    ><CirclePlus
                  /></el-icon>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-for="item in additionalParameters"
                        :key="item.key"
                        :class="{
                          'text-th-secondary': item.selected
                        }"
                        :command="item"
                      >
                        <el-checkbox
                          v-model="item.selected"
                          @change="toggleAdditionalParameter(item)"
                        >
                          {{ item.label }}
                        </el-checkbox>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </th>
            </tr>
          </thead>

          <tbody>
            <variants-table-row
              v-for="item in childrenPage"
              :key="item._id"
              :model-value="item"
              :children="form.children"
              :options="form.options"
              :resources="resources"
              :additional-parameters="additionalParameters"
              :auto-generate-id="autoGenerateId"
              @delete="deleteVariantRow(item._id)"
              @update:modelValue="$emit('update-variant', $event)"
            />
          </tbody>
        </table>

        <!-- Table no data -->
        <div
          v-if="!form.children || form.children.length === 0"
          class="py-5 px-8 text-gray-500"
        >
          {{ $t('pages.products.edit.form.sections.variants.table.no_data') }}
        </div>

        <!-- Pagination -->
        <th-pagination
          v-if="form.children && form.children.length > 0"
          :page-size="pageSizes.inline"
          :total="form.children.length"
          :current-page="page"
          @current-change="(v) => (page = v)"
          @size-change="changePageSize($event)"
        />
      </div>
    </div>

    <!-- Load template -->
    <modal
      v-model="isModalLoadOpen"
      adaptive
      name="variant-load-templates"
      :min-width="400"
      height="auto"
    >
      <variants-load-template
        :product-templates="resources.product_templates"
        @cancel="isModalLoadOpen = false"
        @apply="handleTemplateApply"
      />
    </modal>

    <!-- Save template -->
    <modal
      v-model="isModalSaveOpen"
      adaptive
      name="variant-save-templates"
      :min-width="400"
      height="auto"
    >
      <variants-save-template
        :options="form.options"
        @cancel="isModalSaveOpen = false"
      />
    </modal>
  </th-wrapper>
</template>

<script>
import * as uuid from 'uuid'
import safeGet from 'just-safe-get'
import omit from 'just-omit'
import * as helpers from '@/utils/products'
import VariantsEditor from './variants/variants-editor.vue'
import VariantsTableRow from './variants/variants-table-row.vue'
import VariantsLoadTemplate from './variants/variants-load-template.vue'
import VariantsSaveTemplate from './variants/variants-save-template.vue'
import TableHeaderCell from '@/components/table/table-header-cell'
import { mapGetters } from 'vuex'
import { compareRow } from '@/components/datatable/helpers/sort'

export default {
  components: {
    VariantsEditor,
    VariantsTableRow,
    VariantsLoadTemplate,
    VariantsSaveTemplate,
    TableHeaderCell
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },

    resources: {
      type: Object,
      default: () => ({})
    },

    isNew: {
      type: Boolean,
      default: false
    },

    autoGenerateId: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      headerCollapsed: true,
      automaticMode: this.isNew,
      sortedChildren: [],
      page: 1,
      isModalLoadOpen: false,
      isModalSaveOpen: false,
      additionalParameters: {
        custom_id: {
          label: this.$t(
            'pages.products.edit.form.sections.standard.custom_id.label'
          ),
          key: 'custom_id',
          selected: false
        },
        price: {
          label: this.$t(
            'pages.products.edit.form.sections.standard.gross_price.label'
          ),
          key: 'price',
          selected: true
        },
        barcodes: {
          label: this.$t('pages.products.properties.barcodes.label'),
          key: 'barcodes',
          selected: true
        },
        product_group: {
          label: this.$t(
            'pages.products.edit.form.sections.standard.product_group.label'
          ),
          key: 'product_group',
          selected: false
        },
        tax: {
          label: this.$t(
            'pages.products.edit.form.sections.standard.tax.label'
          ),
          key: 'tax',
          selected: false
        },
        account: {
          label: this.$t(
            'pages.products.edit.form.sections.standard.revenue_account.label'
          ),
          key: 'account',
          selected: false
        },
        initial_stock: {
          label: this.$t(
            'pages.products.edit.form.sections.standard.initial_stock.label'
          ),
          key: 'initial_stock',
          selected: true
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      localConfiguration: 'Config/getLocalConfiguration',
      pageSizes: 'Config/getPageSizes'
    }),

    sortingOptions: {
      get() {
        return {
          sortBy: 'attributes',
          order: 'ascending',
          ...safeGet(
            this.localConfiguration,
            'settings.tableSortingOptions',
            {}
          )
        }
      },
      set(value) {
        this.$store.dispatch('Config/setLocalConfigurationValue', {
          path: 'settings.tableSortingOptions',
          value
        })
      }
    },

    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    title() {
      return `${this.$t('pages.products.edit.form.sections.variants.title')}${
        this.form.children.length > 0 ? ` (${this.form.children.length})` : ''
      }`
    },

    childrenPage() {
      return this.sortedChildren.slice(
        (this.page - 1) * this.pageSizes.inline,
        this.page * this.pageSizes.inline
      )
    },

    attributesInName() {
      return (
        safeGet(
          this.clientAccountConfiguration,
          'ui_configurations.dashboard.products.attributes_in_name'
        ) !== false
      )
    },

    enabledParamaters() {
      if (!this.isNew) return ['price', 'barcodes']
      return Object.keys(this.additionalParameters).filter(
        (k) => this.additionalParameters[k].selected === true
      )
    },

    headerCells() {
      const { sortBy, order } = this.sortingOptions
      return this.enabledParamaters.reduce(
        (cells, key) => {
          cells.push({
            key,
            label: this.additionalParameters[key].label,
            ...this.sortCaretOptions(sortBy, order, key)
          })
          return cells
        },
        [
          {
            key: 'attributes',
            label: this.$t('pages.products.all.headers.attributes'),
            style: 'width: 1%',
            ...this.sortCaretOptions(sortBy, order, 'attributes')
          },
          {
            key: 'product_name',
            label: this.$t(
              'pages.products.edit.form.sections.standard.product_name.label'
            ),
            ...this.sortCaretOptions(sortBy, order, 'product_name')
          }
        ]
      )
    },

    defaultPrices() {
      return safeGet(this.form, 'prices.default_prices') || []
    },

    hasOneBranch() {
      return this.resources?.branches?.length === 1
    },

    showInitialStock() {
      return this.hasOneBranch && !this.isServiceProduct
    }
  },

  watch: {
    'modelValue.children': {
      deep: true,
      handler() {
        this.sortChildren()
      }
    },
    sortingOptions() {
      this.sortChildren()
    }
  },

  mounted() {
    const ommitables = [
      !this.hasOneBranch ? 'initial_stock' : undefined, // In case we have more than one branch, don't show initial_stock
      this.autoGenerateId ? 'custom_id' : undefined // In case autoGenerateId is set, don't show custom_id
    ]
    this.additionalParameters = omit(this.additionalParameters, ommitables)
    this.sortChildren()
  },

  methods: {
    sortCaretOptions(sortBy, order, key) {
      return {
        isAscending: sortBy === key && order === 'ascending',
        isDecending: sortBy === key && order === 'descending'
      }
    },
    sortChildren() {
      const { sortBy, order } = this.sortingOptions
      this.sortedChildren = [...this.form.children]
      const { prop, sortType, formatter } = this.sortOptionMapper(sortBy)
      const info = {
        prop,
        order,
        column: { formatter }
      }

      this.sortedChildren.sort((a, b) => compareRow(a, b, info, sortType))
    },

    sortOptionMapper(key) {
      return (
        {
          attributes: {
            prop: 'attributes',
            formatter: ({ attributes = [] }) =>
              Object.values(attributes).join('')
          },
          product_name: { prop: 'name' },
          custom_id: { prop: 'custom_id' },
          price: {
            prop: 'prices.default_prices.0.amount.gross',
            sortType: 'number'
          },
          barcodes: { prop: 'barcodes.0' },
          product_group: { prop: 'product_group' },
          tax: {
            prop: 'tax',
            formatter: ({ tax }) => this.getResourceName('taxes', tax)
          },
          account: {
            prop: 'account',
            formatter: ({ account }) =>
              this.getResourceName('accounts', account)
          },
          initial_stock: { prop: 'initial_stock', sortType: 'number' }
        }[key] || {}
      )
    },
    setSortingOptions(key) {
      this.sortingOptions = {
        sortBy: key,
        order: this.calculateOrder(key)
      }
    },

    getResourceName(resourceName, resourceId) {
      const { fa_account_number = '', name = '' } = this.resources[
        resourceName
      ].find(({ id }) => id === resourceId)
      return `${fa_account_number} - ${name}`
    },

    calculateOrder(key) {
      const { sortBy, order } = this.sortingOptions
      if (sortBy === key) {
        return order === 'ascending' ? 'descending' : 'ascending'
      }
      return order
    },

    addRow() {
      this.form.children.unshift({
        name: '',
        custom_id: '',
        attributes: {}
      })
    },

    toggleHeaderCollapse() {
      this.headerCollapsed = !this.headerCollapsed
    },

    toggleAdditionalParameter(parameter) {
      parameter.selected = !parameter.selected
    },

    deleteVariantRow(variantRowId) {
      this.form.children = this.form.children.filter(
        (c) => c._id !== variantRowId
      )
    },

    variantOptionsApply() {
      // Clear children in automatic mode
      // Manual mode will add and clear options
      if (this.automaticMode) this.form.children = []

      this.$ampli.eventWithBaseProps(
        'productVariantApplyAttributesButtonClick',
        {
          product_variants_applied_attributes_json: JSON.stringify(
            this.form.options
          )
        }
      )

      const permutations = helpers.makeOptionsPermutations(this.form.options)

      // Create product children from attributes combinations
      permutations.forEach((permutation) => {
        // Check if permutation has values
        if (Object.keys(permutation).length > 0) {
          // If the user configures that the attributes are part of the variant name
          let attributesName = this.attributesInName
            ? Object.keys(permutation)
                .map((p) => permutation[p])
                .join(' ')
            : ''

          const name = `${this.form.name || ''} ${attributesName}`.trim()

          const newVariant = {
            _id: uuid.v4(),
            name,
            attributes: permutation,
            barcodes: null,
            account: this.form.account,
            tax: this.form.tax,
            product_group: this.form.product_group,
            prices: {
              default_prices: this.$deepClone(this.defaultPrices)
            }
          }

          this.form.children.push(newVariant)
        }
      })

      // Clear options on manual mode
      if (!this.automaticMode) {
        this.form.options = [{ name: '', values: [] }]
      }

      this.goToLastPage()
    },

    variantOptionsChanged() {
      if (this.automaticMode) this.variantOptionsApply()
    },

    handleRemoveAll() {
      this.$confirm(
        this.$t(
          'pages.products.edit.form.sections.variants.modal.remove_all.text'
        ),
        this.$t(
          'pages.products.edit.form.sections.variants.modal.remove_all.title'
        ),
        {
          confirmButtonText: this.$t('common.interactions.buttons.yes'),
          cancelButtonText: this.$t('common.interactions.buttons.no'),
          type: 'warning'
        }
      ).then(() => {
        this.form.options = []
        this.form.children = []
      })
    },

    handleLoadTemplate() {
      this.isModalLoadOpen = true
    },

    handleTemplateApply(template) {
      this.isModalLoadOpen = false
      this.form.options = Object.keys(template.option_template).map((key) => {
        return {
          name: key,
          values: template.option_template[key]
        }
      })
      this.variantOptionsChanged()
    },

    handleSaveAsTemplate() {
      this.isModalSaveOpen = true
    },

    goToLastPage() {
      this.page = Math.ceil(this.form.children.length / this.pageSizes.inline)
    },

    changePageSize(value) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'pageSizes.inline',
        value
      })
    },
    async validate() {
      return await this.$refs.variantsEditor.validate()
    },

    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.variants.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>

<style scoped>
.th-table tbody {
  max-height: 360px;
  overflow: auto;
}
</style>
