<template>
  <section class="w-full">
    <!-- Header -->
    <el-row :gutter="20">
      <el-col :xs="10" :sm="10" :md="8" :lg="7">
        <th-input-title
          :title="
            $t('pages.products.edit.form.sections.additional.attrtibutes.title')
          "
        />
      </el-col>
      <el-col :xs="10" :sm="10" :md="8" :lg="7">
        <th-input-title :title="$t('common.headers.value.title')" />
      </el-col>
    </el-row>

    <!-- Attributes -->
    <el-row
      v-for="(item, index) in attributes"
      :key="index"
      :gutter="20"
      class="mb-6 last:mb-0"
    >
      <el-form
        :ref="`form-${index}`"
        :model="item"
        :rules="getRules(item)"
        class="w-full flex"
      >
        <el-col :xs="10" :sm="10" :md="8" :lg="7">
          <!-- Key -->
          <el-form-item prop="key">
            <el-input
              id="key"
              :ref="'attribute-key-' + index"
              v-model="item.key"
              :data-testid="'attribute-key-' + index"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.additional.attributes.name.placeholder'
                )
              "
              @input="(v) => handlKeyChange(v, index)"
            />
          </el-form-item>
        </el-col>

        <el-col :xs="10" :sm="10" :md="8" :lg="7">
          <!-- Value -->
          <el-form-item prop="value">
            <el-input
              id="value"
              v-model="item.value"
              :data-testid="'attribute-value-' + index"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.additional.value.placeholder'
                )
              "
              @input="(v) => handleValueChange(v, index)"
            />
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <!-- Delete -->
          <el-button
            v-if="index !== attributes.length - 1"
            :data-testid="'attribute-delete-' + index"
            type="text-icon"
            icon="Delete"
            :title="$t('common.interactions.buttons.remove')"
            @click="removeItem(index)"
          />

          <!-- Add -->
          <el-button
            v-if="index === attributes.length - 1"
            class="el-button--primary-icon"
            plain
            icon="Plus"
            :title="
              $t('pages.products.edit.form.sections.additional.attributes.add')
            "
            @click="addNew(false)"
          />
        </el-col>
      </el-form>
    </el-row>
  </section>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },

  computed: {
    attributes: {
      get: function () {
        return this.modelValue
      },
      set: function (modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },

  mounted() {
    // Make sure at least one attribute is added
    if (this.attributes.length === 0) {
      this.addNew(true)
    }
  },

  methods: {
    getRules(attribute) {
      return {
        key: [
          {
            required: !!attribute.value,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          },
          {
            validator: this.attributeKeyValidator,
            trigger: 'change'
          }
        ],
        value: [
          {
            required: !!attribute.key,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    },

    attributeKeyValidator(_, value, callback) {
      // Check if attribute Key is already used (case insensitive and white-space removed)
      const itemsWithSameKey = this.attributes.filter(
        ({ key = '' }) =>
          key.trim().toLowerCase() === value.trim().toLowerCase()
      )
      if (itemsWithSameKey.length > 1) {
        callback(
          new Error(
            this.$t(
              'pages.products.edit.form.sections.variants.attribute.already_exists'
            )
          )
        )
      } else {
        callback()
      }
    },

    addNew(ignoreFocus) {
      this.attributes.push({ key: '', value: '' })
      if (ignoreFocus) return

      // Focus on the next attribute
      this.$nextTick(() => {
        this.$refs['attribute-key-' + (this.attributes.length - 1)][0].focus()
      })
    },

    handlKeyChange(v, index) {
      this.attributes[index].key = v
    },

    handleValueChange(v, index) {
      this.attributes[index].value = v
    },

    removeItem(index) {
      this.attributes.splice(index, 1)
      if (this.attributes.length === 0) this.addNew(false)
    },

    async validate() {
      // Validate all attributes
      let valid = true
      for (const key in this.$refs) {
        if (key.includes('form') && this.$refs[key].length > 0) {
          if (!(await this.validateAttribute(key))) valid = false
        }
      }
      return valid
    },
    validateAttribute(key) {
      return new Promise((resolve) => {
        this.$refs[key][0].validate(resolve)
      })
    }
  }
}
</script>
