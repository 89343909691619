<template>
  <th-wrapper
    collapsable
    data-testid="variant-stock-box"
    header-class="bg-th-primary-light pt-5 pb-5"
    body-class="p-0"
    :title="
      $t('pages.products.edit.form.sections.variant_stock.title', [
        product.name
      ])
    "
    :info="$t('pages.products.edit.form.sections.variant_stock.tooltip')"
    @collapsed-changed="handleCollapsedChanged"
  >
    <!-- Center actions -->
    <template #center>
      <!-- Go to Stock Management -->
      <router-link
        :to="{
          name: 'ims-stock-manager',
          query: { product: productId, tab: 'goods-book-in' }
        }"
        :disabled="disableStock"
        :event="disableStock ? '' : 'click'"
      >
        <el-tooltip
          effect="dark"
          placement="top-start"
          :content="disableStockInfo"
          :disabled="!disableStock"
        >
          <div>
            <el-button
              class="py-2"
              plain
              type="primary"
              :disabled="disableStock"
            >
              <div class="flex items-center">
                <svgicon
                  :src="require(`@/assets/icons/th-icon-inventory.svg`)"
                  class="mr-2 fill-current h-5 w-5"
                />
                <span>
                  {{
                    $t(
                      'pages.products.edit.form.sections.variant_stock.add_stock.title'
                    )
                  }}
                </span>
              </div>
            </el-button>
          </div></el-tooltip
        >
      </router-link>
    </template>

    <!-- Body -->
    <div>
      <div class="th-table-responsive shadow-th-light">
        <th-table
          :data="tableData"
          :columns="columns"
          configuration-name="settings.headerFilters.product-variant-stock"
          :no-data-text="
            $t('pages.products.edit.form.sections.variant_stock.table.no_data')
          "
        >
          <template #buttons="row">
            <!-- Go to slot management -->
            <router-link
              :to="{
                name: 'ims-stock-manager',
                query: {
                  location: safeGet(row, 'item.location.id'),
                  product: productId,
                  tab: 'goods-book-in'
                }
              }"
              :disabled="disableStock"
              :event="disableStock ? '' : 'click'"
            >
              <el-tooltip
                effect="dark"
                placement="top-start"
                :content="disableStockInfo"
                :disabled="!disableStock"
              >
                <div>
                  <el-button
                    class="h-8 w-8 p-0"
                    type="primary"
                    plain
                    :disabled="disableStock"
                  >
                    <svgicon
                      :src="require(`@/assets/icons/th-icon-inventory.svg`)"
                      class="fill-current"
                    />
                  </el-button></div
              ></el-tooltip>
            </router-link>
          </template>
        </th-table>
      </div>
    </div>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import ThTable from '@/components/table'

export default {
  components: {
    ThTable
  },

  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      safeGet,
      response: null,
      loading: false,
      columns: [
        {
          label: this.$t('pages.products.detail_view.location'),
          key: 'location',
          visible: true,
          formatter: (value) => value.name || '--'
        },
        {
          label: this.$t('pages.products.detail_view.stock'),
          key: 'qty',
          visible: true,
          class: 'text-right',
          formatter: (value) => value.qty || '--'
        },
        {
          label: this.$t('pages.products.detail_view.price'),
          key: 'price',
          visible: true,
          class: 'text-right',
          formatter: (value) => value.value || '–'
        }
      ]
    }
  },

  computed: {
    productId() {
      return this.$route.params.id
    },

    pageData() {
      return this.tableData?.slice(
        (this.page - 1) * this.pageSizes.inline,
        this.page * this.pageSizes.inline
      )
    },

    defaultCurrency() {
      return this.$store.getters['Config/getCurrentDefaultCurrency']
    },

    tableData() {
      return (
        this.response?.all_stock.map((child) => {
          return {
            ...child,
            qty: this.product.stockable !== false ? child.qty : '–',
            price: this.getPrice(child.location.id),
            name: child.location.name,
            barcode: this.product.barcode
          }
        }) ?? []
      )
    },

    disableStock() {
      return !this.product.stockable
    },

    disableStockInfo() {
      return this.$t(
        'pages.products.edit.form.sections.variant_stock.not_stockable.info'
      )
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.fetch()
    }
  },

  methods: {
    async fetch() {
      this.loading = true

      try {
        const { data } = await th
          .products()
          .getDetails(this.$route.params.id, { original_product: true })
        this.response = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },

    getPrice(branchId) {
      const result = {
        default: false,
        value: null
      }

      if (!this.product.prices) return result

      const {
        branch_prices: branchPrices,
        default_prices: defaultPrices
      } = this.product.prices

      if (Array.isArray(branchPrices) && branchPrices.length) {
        const branchPrice = branchPrices.find((p) => p.branch === branchId)
        if (branchPrice) {
          const grossPrice = this.getGrossPrice(
            branchPrice && branchPrice.prices,
            this.defaultCurrency
          )

          if (grossPrice) {
            result.value = grossPrice
            return result
          }
        }
      }

      if (Array.isArray(defaultPrices) && defaultPrices.length) {
        const grossPrice = this.getGrossPrice(
          defaultPrices,
          this.defaultCurrency
        )

        if (grossPrice) {
          result.default = true
          result.value = grossPrice
          return result
        }
      }

      return result
    },

    getGrossPrice(priceObject, currency) {
      const prices = priceObject.find((p) => p.currency === currency) || {}
      const grossPrice = prices.amount && prices.amount.gross
      return this.$formatCurrency(grossPrice, currency)
    },

    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.variant_stock.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>
