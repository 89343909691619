<template>
  <div
    class="barcode-container w-full border rounded border-dashed border-color-hover h-20 flex flex-col justify-center items-center"
  >
    <img v-if="showBarcodeImage" class="h-full" :src="imageSrc" />
    <div
      v-if="!showBarcodeImage"
      class="w-full flex flex-col justify-center items-center text-th-light-gray"
    >
      <svgicon
        class="placeholder-icon"
        :src="require('@/assets/icons/th-icon-barcode.svg')"
        :style="{
          height: '30px',
          width: '150px'
        }"
        :fill="false"
      />
      <div class="leading-4">
        {{
          $t('pages.products.edit.form.sections.standard.barcode_image.title')
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { textToBase64Barcode } from '@/components/barcodes/helpers'

export default {
  name: 'BarcodeViewer',
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageSrc: null
    }
  },
  computed: {
    code128() {
      return String(this.modelValue)
    },
    showBarcodeImage() {
      return !!this.code128
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      /**
       * Update the bar code when props changed.
       */
      handler() {
        if (this.$el) {
          this.generate()
        }
      }
    }
  },

  mounted() {
    this.generate()
  },

  methods: {
    generate() {
      if (this.showBarcodeImage) {
        this.imageSrc = textToBase64Barcode(this.code128)
      }
    }
  }
}
</script>

<style scoped>
.barcode-container {
  border-color: var(--border-color-hover);
  color: var(--border-color-hover);
}
.placeholder-icon {
  stroke: var(--disabled-color-light);
}
</style>
