<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    :data-testid="'composed-row-' + index"
  >
    <el-row :gutter="20">
      <!-- Item -->
      <el-col :md="8">
        <el-form-item class="mb-0 sm:mb-2 xs:mb-2" prop="product">
          <remote-search-select
            v-model="form.product"
            class="w-full"
            popper-append-to-body
            resource="products"
            :modify-query="modifyQuery"
            :result-filter="resultFilter"
            :computed-fields="['custom_id', 'name']"
            :placeholder="
              $t(
                'pages.products.edit.form.sections.composed.product.placeholder'
              )
            "
            @resource-set="setProduct"
          />
        </el-form-item>
      </el-col>

      <!-- Quantity -->
      <el-col :md="4" :sm="12" :xs="12">
        <el-form-item class="mb-0 sm:mb-2 xs:mb-2" prop="quantity">
          <th-number-input
            v-model="form.quantity"
            :locale="$i18n.locale"
            :precision="3"
            :placeholder="
              $t(
                'pages.products.edit.form.sections.composed.quantity.placeholder'
              )
            "
          />
        </el-form-item>
      </el-col>

      <!-- Purchase price -->
      <el-col :lg="4" :md="4" :sm="12" :xs="12">
        <el-form-item class="mb-0 sm:mb-2 xs:mb-2" prop="purchase_price">
          <th-currency-input
            :model-value="purchasePriceWithQuantity"
            disabled
          />
        </el-form-item>
      </el-col>

      <!-- Gross price -->
      <el-col :lg="4" :md="4" :sm="12" :xs="12">
        <el-form-item class="mb-0 xs:mb-2" prop="gross_price">
          <th-currency-input :model-value="grossPriceWithQuantity" disabled />
        </el-form-item>
      </el-col>

      <el-col :lg="3" :md="3" :sm="12" :xs="12" class="flex items-center">
        <!-- Delete -->
        <el-button
          plain
          icon="Delete"
          class="-ml-3 el-button--text-icon"
          :title="$t('common.interactions.buttons.remove')"
          @click="handleDelete(index)"
        />

        <!-- Add -->
        <el-button
          v-if="isLast"
          plain
          class="el-button--primary-icon"
          icon="Plus"
          :title="$t('common.interactions.buttons.add')"
          @click="handleAdd"
        />
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import safeGet from 'just-safe-get'
import { productType } from '@/utils/products'
import Decimal from 'decimal.js-light'
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      required: true
    },
    isLast: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      rules: {
        product: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!value || this.form.product) {
                callback()
              } else {
                callback(
                  new Error(
                    this.$t('common.forms.rules.field_warnings.required')
                  )
                )
              }
            }
          }
        ],
        quantity: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!value || value > 0) {
                callback()
              } else {
                callback(
                  new Error(
                    this.$t(
                      'pages.safe_management.booking.form.field_warnings.transfer_value_zero'
                    )
                  )
                )
              }
            }
          }
        ]
      },
      product: null
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    purchasePriceWithQuantity() {
      // Purchase price * Quantity
      this.setPrice('purchase_price')
      this.$emit('recalculate')
      return this.form.total_purchase_price
    },

    grossPriceWithQuantity() {
      // Gross price * Quantity
      this.setPrice('gross_price')
      this.$emit('recalculate')
      return this.form.total_gross_price
    }
  },

  methods: {
    setPrice(field) {
      const propMap = {
        purchase_price: 'prices.default_prices.0.purchase_price',
        gross_price: 'prices.default_prices.0.amount.gross'
      }
      const price = safeGet(this.product, propMap[field]) || 0

      this.form['total_' + field] = new Decimal(price)
        .mul(this.form.quantity || 0)
        .toNumber()
    },
    setProduct(product) {
      this.product = product
    },

    handleAdd() {
      this.$emit('add')
    },

    handleDelete() {
      this.$emit('delete', this.index)
    },

    modifyQuery(q) {
      return {
        query: {
          limit: 50,
          deleted: false,
          q,
          exclude_system_products: true,
          type: [
            'product',
            'composed_product',
            'voucher',
            'linked',
            'linked_product',
            'variant',
            'variant_product'
          ]
        }
      }
    },

    resultFilter(results) {
      const productId = this.$route.params.id
      return results.filter((r) => {
        // Remove self, variant (main) product product
        return r.id !== productId && r.type !== productType.VARIANT_PRODUCT
      })
    },

    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
