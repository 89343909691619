<template>
  <div class="product-options pt-6">
    <div>
      <el-form
        v-for="(attribute, key) in attributes"
        :key="key"
        :model="attribute"
      >
        <el-row :gutter="20" class="mb-4">
          <el-col :sm="24" :md="8" :lg="6">
            <!-- Name -->
            <el-form
              :ref="`attribute-form-${key}`"
              :model="attribute"
              :rules="getRules(attribute)"
              @submit.prevent
            >
              <el-form-item
                prop="name"
                :label="
                  $t(
                    'pages.products.edit.form.sections.variants.attribute.label'
                  )
                "
              >
                <el-autocomplete
                  :ref="'attribute-name-input-' + key"
                  v-model="attribute.name"
                  data-testid="attribute-name-input"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.variants.attribute.placeholder'
                    )
                  "
                  class="w-full"
                  :fetch-suggestions="getProductTemplateSuggestions"
                  @change="attribute.name = capitalizeFirstLetter($event)"
                  @blur="handleNameChange($event.target.value, key)"
                  @select="handleSuggestionSelect($event, key)"
                >
                  <template #default="{ item }">
                    <div class="flex">
                      <div class="truncate w-24" v-text="item.attribute" />
                      <div
                        class="text-xs opacity-75 truncate"
                        v-text="item.template_name"
                      />
                    </div>
                  </template>
                </el-autocomplete>
              </el-form-item>
            </el-form>
          </el-col>

          <el-col :sm="24" :md="16" :lg="18">
            <!-- Values -->
            <variants-editor-values
              v-model="attribute.values"
              :disabled="!attribute.name && attribute.values.length === 0"
              :attribute-name="attribute.name"
              @change="handleValuesChange($event, key)"
              @delete="deleteAttribute(attribute, key)"
            />
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="flex">
      <!-- Add attribute -->
      <el-button text class="-mt-4" icon="Plus" @click="addNewAttribute">
        {{ $t('pages.products.edit.form.sections.variants.attribute.add') }}
      </el-button>

      <div class="flex-grow" />

      <!-- Apply attribtes (Manual apply) -->
      <el-button
        v-if="!automaticMode"
        type="primary"
        plain
        class="-mt-4"
        :disabled="disableApplyAttributesButton"
        @click="manualApply"
      >
        {{ $t('pages.products.edit.form.sections.variants.attribute.apply') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils/strings'
import VariantsEditorValues from './variants-editor-values.vue'

export default {
  components: {
    VariantsEditorValues
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    resources: {
      type: Object,
      default: () => ({})
    },

    automaticMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      capitalizeFirstLetter
    }
  },

  computed: {
    attributes: {
      get() {
        return this.modelValue
      },

      set(val) {
        this.$emit('update:modelValue', val)
        this.$emit('change', val)
      }
    },

    isNew() {
      return !this.$route.params.id
    },

    // Extract template options
    productTemplateSuggestions() {
      const suggestions = []
      this.resources.product_templates.forEach((item) => {
        if (!item.option_template) return
        Object.entries(item.option_template).forEach((value) => {
          suggestions.push({
            template_name: item.name || '-',
            attribute: value[0],
            values: value[1]
          })
        })
      })
      return suggestions
    },
    disableApplyAttributesButton() {
      // if attributes have no values or name, disable 'apply attributes' button
      return this.attributes.some(({ name, values }) => !name || !values.length)
    },

    showVariantsEditorValues() {
      return this.attribute.name || this.attribute.values.length !== 0
    }
  },

  methods: {
    getRules(item) {
      return {
        name: [
          {
            required: item.values.length > 0,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          },
          {
            validator: this.attributeNameValidator,
            trigger: 'change'
          }
        ]
      }
    },
    attributeNameValidator(_, value, callback) {
      // Check if attribute name is already used (case insensitive and white-space removed)
      const itemsWithSameName = this.attributes.filter(
        ({ name = '' }) =>
          name.trim().toLowerCase() === value.trim().toLowerCase()
      )
      if (itemsWithSameName.length > 1) {
        callback(
          new Error(
            this.$t(
              'pages.products.edit.form.sections.variants.attribute.already_exists'
            )
          )
        )
      } else {
        callback()
      }
    },
    manualApply() {
      this.$emit('manual-apply')
    },

    handleValuesChange(data, key) {
      const temp = [...this.attributes]
      temp[key].values = data
      this.attributes = temp
    },

    handleNameChange(newName, key) {
      if (key) {
        const temp = [...this.attributes]
        temp[key].name = newName
        this.attributes = temp
      }
    },

    addNewAttribute() {
      this.$ampli.eventWithBaseProps('productVariantAddAttributeButtonClick')
      const temp = [...this.attributes]
      temp.push({ name: '', values: [] })
      this.attributes = temp

      // Focus on the next input
      this.$nextTick(() => {
        this.$refs[
          'attribute-name-input-' + (this.attributes.length - 1)
        ][0].focus()
      })
    },

    deleteAttribute(_, key) {
      const temp = [...this.attributes]
      temp.splice(key, 1)
      this.attributes = temp

      this.$nextTick(() => {
        if (this.attributes.length === 0) this.addNewAttribute()
      })
    },

    handleTemplateSelection(template) {
      this.attributes = Object.keys(template.option_template).map((key) => {
        return {
          name: key,
          values: template.option_template[key]
        }
      })
    },

    // Handle template option select
    handleSuggestionSelect(attribute, key) {
      const temp = [...this.attributes]
      temp[key].name = attribute.attribute
      temp[key].values = attribute.values
      this.attributes = temp
    },

    // Filter template options
    getProductTemplateSuggestions(queryString, cb) {
      const items = this.productTemplateSuggestions
      const results = queryString
        ? items.filter(
            (t) =>
              t.attribute.toLowerCase() === (queryString || '').toLowerCase()
          )
        : items
      cb(results)
    },
    async validate() {
      let valid = true
      for (const key in this.$refs) {
        if (key.includes('attribute-form-') && this.$refs[key].length > 0) {
          if (!(await this.$refs[key][0].validate())) valid = false
        }
      }
      return valid
    }
  }
}
</script>
