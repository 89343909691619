<template>
  <el-row :gutter="20">
    <el-col :span="8">
      <!-- Purchase price -->
      <el-form-item
        prop="purchase_price"
        :label="
          $t('pages.products.edit.form.sections.standard.purchase_price.label')
        "
      >
        <th-currency-input
          id="purchase_price"
          v-model="defaultPrice.purchase_price"
          :disabled="isServiceProduct"
          clearable-is-left
          size="large"
          data-testid="purchase-price"
          @update:modelValue="handlePurchasePrice"
        />
        <!-- Total purchase price -->
        <div
          v-if="
            !isServiceProduct &&
            computedProductTotals &&
            computedProductTotals.totalPurchasePrice
          "
          class="absolute top-100 right-0 text-xs text-th-info-yellow mt-1 cursor-pointer leading-none hover:text-yellow-600"
          @click="setTotalPurchasePrice"
        >
          {{ $t('pages.counting_protocols.subtable.calculated') }}
          {{
            $formatCurrency(
              computedProductTotals.totalPurchasePrice,
              defaultCurrency
            )
          }}
        </div>
      </el-form-item>
    </el-col>
    <el-col :span="8">
      <!-- Gross price -->
      <el-form-item
        :label="
          $t('pages.products.edit.form.sections.standard.gross_price.label')
        "
      >
        <th-currency-input
          v-model="defaultPrice.amount.gross"
          :disabled="isRequestPriceInput"
          clearable-is-left
          size="large"
          data-testid="gross-price"
          @update:modelValue="handleGrossPrice"
        />
        <!-- Total purchase price -->
        <div
          v-if="computedProductTotals && computedProductTotals.totalGrossPrice"
          class="absolute top-100 right-0 text-xs text-th-info-yellow mt-1 cursor-pointer leading-none hover:text-yellow-600"
          @click="setTotalGrossPrice"
        >
          {{ $t('pages.counting_protocols.subtable.calculated') }}
          {{
            $formatCurrency(
              computedProductTotals.totalGrossPrice,
              defaultCurrency
            )
          }}
        </div>
      </el-form-item>
    </el-col>

    <el-col :span="8">
      <!-- Show calculation -->
      <el-form-item label="Show calculation" class="hidden-label">
        <el-button
          type="primary"
          class="w-full"
          plain
          @click="showCalculationModal = true"
        >
          {{
            $t(
              'pages.products.edit.form.sections.standard.show_calculation.label'
            )
          }}
        </el-button>
      </el-form-item>
    </el-col>

    <!-- Calculation modal -->
    <calculation-modal
      v-if="showCalculationModal"
      :model-value="defaultPrice"
      :tax-rate="taxObject.rate"
      :is-service-product="isServiceProduct"
      @close="showCalculationModal = false"
      @confirm="confirmCalculation"
    />
  </el-row>
</template>

<script>
import safeSet from 'just-safe-set'
import * as price from '@/components/prices/price.js'
import CalculationModal from '../calculation-modal.vue'

export default {
  components: { CalculationModal },

  props: {
    modelValue: {
      type: Array,
      required: true
    },
    taxes: {
      type: Array,
      required: true
    },
    tax: {
      type: String,
      default: null
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    },
    isRequestPriceInput: {
      type: Boolean,
      default: false
    },
    computedProductTotals: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      showCalculationModal: false,
      cleanPrice: {
        amount: {
          net: null,
          gross: null
        },
        margin: null,
        currency: this.defaultCurrency
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },

      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    price() {
      return this.form.find(({ currency }) => currency === this.defaultCurrency)
    },

    defaultPrice() {
      return this.price || this.cleanPrice
    },

    defaultCurrency() {
      return this.$store.getters['Config/getCurrentDefaultCurrency']
    },

    taxObject() {
      return this.taxes.find(({ id }) => id === this.tax) || this.taxes[0]
    }
  },

  watch: {
    tax() {
      this.handleGrossPrice()
    }
  },

  mounted() {
    this.cleanPrice.currency = this.defaultCurrency
    this.handleGrossPrice()
  },

  methods: {
    setTotalPurchasePrice() {
      // Set purchase price from compose product total
      safeSet(
        this.defaultPrice,
        'purchase_price',
        this.computedProductTotals.totalPurchasePrice
      )
    },

    setTotalGrossPrice() {
      // Set gross price from compose product total
      safeSet(
        this.defaultPrice,
        'amount.gross',
        this.computedProductTotals.totalGrossPrice
      )
      this.handleGrossPrice()
    },

    confirmCalculation({ purchase_price, cost, margin, net, gross }) {
      this.defaultPrice.amount.gross = gross
      this.defaultPrice.amount.net = net
      this.defaultPrice.margin = margin
      this.defaultPrice.purchase_price = purchase_price
      this.defaultPrice.cost = cost
      this.showCalculationModal = false
    },

    handleGrossPrice() {
      if (this.defaultPrice.amount.gross !== null) {
        this.initPrice()
        // Calculate
        const { net, margin } = price.onGross(
          this.defaultPrice.amount.gross,
          this.defaultPrice,
          this.taxObject.rate
        )
        // Save new price
        this.defaultPrice.amount.net = net
        this.defaultPrice.margin = margin
      } else {
        // Price is null, remove the price
        this.defaultPrice.amount.net = null
        this.defaultPrice.margin = null
      }
    },
    handlePurchasePrice() {
      if (this.defaultPrice.purchase_price !== null) {
        this.initPrice()
        // Calculate
        const { purchasePrice, margin } = price.onPurchasePrice(
          this.defaultPrice.purchase_price,
          this.defaultPrice,
          this.taxObject.rate
        )
        // Save new price
        this.defaultPrice.purchase_price = purchasePrice
        this.defaultPrice.margin = margin
      } else {
        // Price is null, remove the price
        this.defaultPrice.purchase_price = null
        this.defaultPrice.margin = null
      }
    },
    initPrice() {
      // Check if exists
      if (this.price) return

      this.form.push({
        amount: {
          net: null,
          gross: null
        },
        margin: null,
        currency: this.defaultCurrency
      })
    }
  }
}
</script>
