import safeGet from 'just-safe-get'
import { DAYS } from '@utils/constraints'
import { createLabel } from '@/utils/strings'

export function getPriceBookValidityMessage(
  priceBook,
  dateFormatter = (f) => f
) {
  return {
    dateRange: getDateRange(priceBook, dateFormatter),
    hourRange: getTimeRangeLabel(priceBook)
  }
}

export function getTimeRangeLabel(priceBook) {
  const week = safeGet(priceBook, 'constraints.time.scheduled.day_of_week', {})
  if (!week.enabled) return []

  return DAYS.reduce((daysOfWeek, day) => {
    //get a list of all enabled slots
    const timeSlots = week[day].slots.reduce(
      (slots, { start, end, enabled }) => {
        if (enabled) {
          slots.push(createLabel([start, end]))
        }
        return slots
      },
      []
    )
    if (timeSlots.length > 0) {
      daysOfWeek.push({
        day,
        timeSlots: timeSlots
      })
    }
    return daysOfWeek
  }, [])
}

export function getDateRange(priceBook, dateFormatter) {
  const { start, end } = safeGet(
    priceBook,
    'constraints.time.scheduled.date_range',
    {}
  )
  if (start?.enabled && start?.value) {
    const dates = []
    dates.push(dateFormatter(start.value))
    if (end?.enabled && end?.value && end.value !== start.value) {
      dates.push(dateFormatter(end.value))
    }
    return dates.join(' - ')
  }
  return ''
}
