<template>
  <th-wrapper
    body-class="py-4 px-8"
    collapsable
    header-class="bg-th-primary-light pb-5 pt-5"
    collapsed-by-default
    :title="title"
    :info="$t('pages.products.edit.form.sections.linked.hint')"
    @collapsed-changed="updateData"
  >
    <!-- Body -->
    <div class="flex flex-col-reverse">
      <div
        v-for="(item, index) in localLinkedProducts"
        :key="index"
        :class="index === 0 ? 'pb-0' : 'pb-2'"
      >
        <linked-row
          :ref="'form-' + index"
          :model-value="item"
          :index="index"
          :is-last="index === localLinkedProducts.length - 1"
          :used-product-ids="usedProductIds"
          @update:modelValue="handleUpdate(index, $event)"
          @add="handleAdd"
          @delete="handleDelete(index)"
        />
      </div>
    </div>
  </th-wrapper>
</template>

<script>
import LinkedRow from './linked/linked-row'

const getInitialForm = () => ({
  id: null,
  qty: 1.0,
  type: 'product'
})

export default {
  name: 'EditProductLinked',
  components: {
    LinkedRow
  },
  props: {
    modelValue: {
      type: [Array, null],
      default: null
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localLinkedProducts: []
    }
  },
  computed: {
    usedProductIds() {
      return this.localLinkedProducts.map(({ id }) => id)
    },
    linkedProductCount() {
      return this.localLinkedProducts.reduce((count, { id }) => {
        if (id) count++
        return count
      }, 0)
    },
    title() {
      return `${this.$t('pages.products.edit.form.sections.linked.title')}${
        this.linkedProductCount ? ` (${this.linkedProductCount})` : ''
      }`
    }
  },
  watch: {
    modelValue(linkedProducts) {
      this.localLinkedProducts = linkedProducts || []
    }
  },
  methods: {
    updateData(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.linked.title',
          'en'
        ),
        section_collapsed: collapsed
      })
      if (!this.localLinkedProducts.length)
        this.localLinkedProducts.push(getInitialForm())
    },
    async handleUpdate(index, value) {
      this.localLinkedProducts[index] = value
      this.emitLinkedProduct()
    },
    handleAdd() {
      this.localLinkedProducts.push(getInitialForm())
      this.emitLinkedProduct()
    },
    handleDelete(index) {
      this.localLinkedProducts.splice(index, 1)
      if (this.localLinkedProducts.length === 0) this.handleAdd() // Always show at least one line
      this.emitLinkedProduct()
    },
    emitLinkedProduct() {
      this.$emit('update:modelValue', this.localLinkedProducts)
    },
    async validate() {
      // Validate all items rows
      let valid = true
      // We don't want to validate the getInitialForm()
      if (
        this.localLinkedProducts.length === 1 &&
        !this.localLinkedProducts[0].id
      ) {
        return valid
      }
      for (const key in this.$refs) {
        if (key.includes('form') && this.$refs[key].length > 0) {
          if (!(await this.$refs[key][0].validate())) valid = false
        }
      }
      return valid
    }
  }
}
</script>
