<template>
  <el-dialog
    v-model="isVisible"
    :title="$t('pages.products.edit.form.overlays.children.creating.title')"
    width="50vh"
    height="50vh"
  >
    <div :key="childOpertation.showProgress">
      <span
        class="-mt-5"
        v-text="
          $t('pages.products.edit.form.overlays.children.creating.description')
        "
      />

      <el-progress
        class="overlay-children-progress mt-4"
        :text-inside="true"
        :stroke-width="18"
        :percentage="Math.floor(childOpertation.progress * 100)"
        :status="childOpertation.progressStatus"
      />

      <div
        v-if="childOpertation.errors && childOpertation.errors.length"
        class="overlay-children-errors-list"
      >
        <h3
          v-text="$t('pages.products.edit.form.overlays.children.errors.title')"
        />
        <p
          v-text="
            $t('pages.products.edit.form.overlays.children.errors.description')
          "
        />

        <hr class="th-divider" />

        <div
          v-for="(errObject, index) in childOpertation.errors || []"
          :key="index"
        >
          <span class="overlay-children-errors name">
            {{ errObject.product.name || '-' }}
          </span>
          <span class="overlay-children-errors custom_id">
            {{ errObject.product.custom_id || '-' }}
          </span>
          <span class="overlay-children-errors message">
            {{ errObject.err.message || '-' }}
          </span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    childOpertation: {
      type: Object,
      required: true
    }
  },
  computed: {
    isVisible() {
      return this.childOpertation.showProgress
    }
  }
}
</script>
