import deepClone from 'clone-deep'
import { timeRegex } from '../date'
import typeOf from 'just-typeof'

export const constraintsInitialObj = {
  time: {
    scheduled: {
      day_of_week: {
        enabled: false,
        monday: {
          slots: [
            {
              enabled: false,
              start: null,
              end: null
            }
          ]
        },
        tuesday: {
          slots: [
            {
              enabled: false,
              start: null,
              end: null
            }
          ]
        },
        wednesday: {
          slots: [
            {
              enabled: false,
              start: null,
              end: null
            }
          ]
        },
        thursday: {
          slots: [
            {
              enabled: false,
              start: null,
              end: null
            }
          ]
        },
        friday: {
          slots: [
            {
              enabled: false,
              start: null,
              end: null
            }
          ]
        },
        saturday: {
          slots: [
            {
              enabled: false,
              start: null,
              end: null
            }
          ]
        },
        sunday: {
          slots: [
            {
              enabled: false,
              start: null,
              end: null
            }
          ]
        }
      },
      date_range: {
        enabled: false,
        start: {
          enabled: false,
          value: null
        },
        end: {
          enabled: false,
          value: null
        },
        tz: null
      }
    }
  }
}

export const initialSlot = () => ({
  enabled: false,
  start: '00:00',
  end: '23:59'
})

export const getConstraints = (c) => c || deepClone(constraintsInitialObj)

export const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

/**
 *  isValidate a constraints day_of_week object - returns a boolean accordingly
 * @param {object} dayOfWeekObj
 * @returns {boolean}
 */
export function validateConstraints(dayOfWeekObj) {
  const timePeriodsArr = Object.entries(dayOfWeekObj)
    .filter(([key]) => DAYS.includes(key))
    .reduce((acc, [_, { slots }]) => acc.concat(slots), [])

  for (const { start, end } of timePeriodsArr) {
    if (typeOf(start) !== typeOf(end)) {
      return false
    }

    if (
      typeOf(start) === 'string' &&
      (!timeRegex().test(start) ||
        !timeRegex().test(end) ||
        start.replace(':', '') >= end.replace(':', ''))
    ) {
      return false
    }
  }
  return true
}
