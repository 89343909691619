import { computed } from 'vue'
import { useStore } from 'vuex'

/**
 * @param {computed} form - a vue computed property
 */
export default function (form) {
  const store = useStore()
  const defaultCurrency = computed(
    () => store.getters['Config/getCurrentDefaultCurrency']
  )
  const currencyList = computed(
    () => store.getters['Config/getAvailableCurrencies']
  )
  const usedCurrencies = computed(() => {
    return form.value.map((item) => item.currency).filter((item) => item)
  })
  const availableCurrencies = computed(() => {
    return currencyList.value.filter(
      (currency) => !usedCurrencies.value.includes(currency)
    )
  })
  const noCurrencies = computed(() => {
    return availableCurrencies.length !== 0
  })

  return {
    defaultCurrency,
    noCurrencies,
    availableCurrencies,
    usedCurrencies
  }
}
