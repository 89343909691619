<template>
  <el-row :gutter="20" class="xs:mb-4">
    <el-col v-show="currencies.length > 1" :xs="24" :md="3" :sm="4">
      <!-- Currency -->
      <el-form-item
        :class="{ 'mt-5': !isFirst }"
        :label="
          isFirst
            ? $t('pages.products.edit.form.pricing.common.headers.currency')
            : null
        "
      >
        <th-currency-select
          v-model="form.currency"
          data-testid="currency-select"
          add-class="w-full"
          :filtered-currencies="usedCurrencies"
          :allowed-currencies="currencies"
          :disabled="currencies.length < 2"
        />
      </el-form-item>
    </el-col>
    <el-col :xs="24" :md="18" :sm="17">
      <price-row-calculation
        :value="form"
        :tax-rate="tax.rate"
        :is-service-product="isServiceProduct"
        @update="confirmCalculation"
      />
    </el-col>
    <el-col :xs="24" :md="3" :sm="3">
      <el-form-item
        :label="isFirst ? 'Actions' : null"
        :class="['hidden-label', isFirst ? 'xs:-mt-5' : 'mb-0 mt-5']"
      >
        <div class="flex">
          <!-- Delete -->
          <el-button
            v-if="!isLast"
            plain
            class="ml-2 el-button--text-icon"
            icon="Delete"
            :disabled="disableDelete"
            @click="handleDelete"
          />

          <!-- Add -->
          <el-tooltip
            :disabled="canAdd"
            :content="
              $t(
                'pages.products.edit.form.sections.prices.price_row.add_price_disabled'
              )
            "
          >
            <div class="inline-block ml-3">
              <el-button
                v-show="isLast && canAdd"
                data-testid="add-button"
                class="el-button--primary-icon"
                plain
                icon="Plus"
                native-type="submit"
              />
            </div>
          </el-tooltip>
        </div>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
import * as PriceHelper from '@/components/prices/price.js'
import PriceRowCalculation from './price-row-calculation.vue'

export default {
  components: {
    PriceRowCalculation
  },

  props: {
    price: {
      type: Object,
      default: () => {}
    },
    tax: {
      type: Object,
      default: () => {}
    },
    usedCurrencies: {
      type: Array,
      default: () => []
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: true
    },
    disableDelete: {
      type: Boolean,
      default: false
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      currencies: 'Config/getAvailableCurrencies'
    }),
    form: {
      get() {
        return this.price
      },
      set(value) {
        this.$emit('update', value)
      }
    }
  },

  methods: {
    confirmCalculation(newPrice) {
      this.form.amount.net = newPrice.net
      this.form.amount.gross = newPrice.gross
      this.form.margin = newPrice.margin
      this.form.cost = newPrice.cost
      this.form.purchase_price = newPrice.purchase_price
    },
    handleDelete() {
      this.$emit('delete')
    },
    handleChange() {
      // Calculate
      let newPrice = {
        net: null,
        gross: null,
        margin: null
      }
      if (this.form.amount.gross !== null) {
        newPrice = PriceHelper.onGross(
          this.form.amount.gross,
          this.form,
          this.tax.rate
        )
      }
      // Set new price
      this.form.amount.net = newPrice.net
      this.form.amount.gross = newPrice.gross
      this.form.margin = newPrice.margin
    },
    focus() {
      this.$refs['gross-price'].$el.getElementsByTagName('input')[0].focus()
    }
  }
}
</script>
