<template>
  <th-wrapper data-testid="product-standard">
    <!-- Title -->
    <template #title>
      <div class="w-56">{{ title }}</div>
    </template>

    <!-- Center actions -->
    <template #center>
      <!-- Actively usable -->
      <el-checkbox v-model="form.active">
        {{ $t('pages.products.edit.form.sections.standard.active.label') }}
      </el-checkbox>

      <!-- Track stock -->
      <el-checkbox
        v-if="form.type !== productType.COMPOSED_PRODUCT"
        v-model="form.stockable"
        :disabled="form.is_service"
      >
        {{
          $t(
            'pages.products.edit.form.sections.stock.configuration.track_stock'
          )
        }}
      </el-checkbox>

      <!-- Is service product -->
      <el-checkbox
        v-if="form.type !== productType.PURCHASE_ITEM"
        v-model="form.is_service"
      >
        {{ $t('pages.products.edit.form.sections.standard.is_service.label') }}
      </el-checkbox>
    </template>

    <!-- Actions -->
    <template v-if="hasParent" #actions>
      <!-- Navigate to the variant product parent -->
      <el-button type="primary" plain @click="goToParent()">
        <div class="flex items-center">
          <svgicon
            :src="require(`@/assets/icons/th-icon-eye.svg`)"
            :style="{ height: '14px', width: '14px' }"
            class="fill-current mr-2"
          />
          <span>
            {{
              $t(
                'pages.products.edit.form.sections.standard.go_to_parent.label'
              )
            }}
          </span>
        </div>
      </el-button>
    </template>

    <!-- Body -->
    <div class="-mb-5">
      <el-row :gutter="20">
        <el-col :md="12">
          <!-- Name -->
          <el-form-item
            prop="name"
            :label="
              $t(
                'pages.products.edit.form.sections.standard.product_name.label'
              )
            "
          >
            <el-input
              v-model="form.name"
              data-testid="name"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.standard.product_name.placeholder'
                )
              "
              data-lpignore="true"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              maxlength="512"
            />
          </el-form-item>

          <!-- Product number -->
          <el-form-item
            prop="custom_id"
            :label="
              $t('pages.products.edit.form.sections.standard.custom_id.label')
            "
          >
            <el-input
              id="custom_id"
              v-model="form.custom_id"
              :disabled="autoGenerateId"
              data-lpignore="true"
              data-testid="custom_id"
              autocomplete="none"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              :placeholder="
                autoGenerateId
                  ? $t(
                      'pages.products.edit.form.sections.standard.custom_id.placeholder_auto'
                    )
                  : $t(
                      'pages.products.edit.form.sections.standard.custom_id.placeholder'
                    )
              "
            />
          </el-form-item>

          <!-- Product group -->
          <el-form-item
            prop="product_group"
            :label="
              $t(
                'pages.products.edit.form.sections.standard.product_group.label'
              )
            "
          >
            <el-select
              id="product_group"
              ref="productGroupSelect"
              v-model="form.product_group"
              v-cancel-read-only
              clearable
              filterable
              class="w-full"
              data-testid="product_group"
              :placeholder="$t('common.interactions.buttons.select')"
              @change="changeProductGroup"
            >
              <el-option
                v-for="item in resources.product_groups"
                :key="item.id"
                :label="item.computed_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-row v-if="form.type !== productType.PURCHASE_ITEM">
            <el-col>
              <!-- Purchase & Gross price -->
              <product-price
                v-if="resources && resources.taxes"
                v-model="form.prices.default_prices"
                :taxes="resources.taxes"
                :tax="form.tax"
                :is-service-product="isServiceProduct"
                :is-request-price-input="requestInput"
                :computed-product-totals="computedProductTotals"
              />
              <div class="mb-6">
                <!-- Request input -->
                <el-checkbox v-model="requestInput">
                  {{
                    $t('pages.products.edit.form.configuration.request_input')
                  }}
                </el-checkbox>
                <!-- Zero input allowed -->
                <el-checkbox v-if="requestInput" v-model="zeroInputAllowed">
                  {{
                    $t(
                      'pages.products.edit.form.configuration.zero_input_allowed'
                    )
                  }}
                </el-checkbox>
              </div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :md="6">
          <!-- Tax -->
          <el-form-item
            :label="$t('pages.products.edit.form.sections.standard.tax.label')"
            prop="tax"
          >
            <el-select
              v-model="form.tax"
              v-cancel-read-only
              filterable
              clearable
              class="w-full"
              data-testid="tax"
              :placeholder="$t('common.interactions.buttons.select')"
            >
              <el-option
                v-for="item in resources.taxes"
                :key="item.id"
                :label="item.computed_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <!-- Revenue account -->
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.sections.standard.revenue_account.label'
              )
            "
            prop="account"
          >
            <el-select
              v-model="form.account"
              v-cancel-read-only="true"
              filterable
              clearable
              class="w-full"
              data-testid="revenue-account"
              :placeholder="$t('common.interactions.buttons.select')"
            >
              <el-option
                v-for="item in resources.accounts"
                :key="item.id"
                :label="item.computed_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <!-- Barcodes -->
          <el-form-item
            v-if="form.type !== productType.PURCHASE_ITEM"
            prop="barcodes"
            :label="$t('pages.products.properties.barcodes.label')"
          >
            <barcodes-input id="barcodes" v-model="form.barcodes" />
          </el-form-item>

          <!-- External reference -->
          <el-form-item
            v-else
            prop="external_reference_id"
            :label="
              $t(
                'pages.products.edit.form.sections.additional.external_reference_id.label'
              )
            "
          >
            <el-input
              id="external_reference_id"
              v-model="form.external_reference_id"
              data-testid="external-reference-id"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.additional.external_reference_id.placeholder'
                )
              "
            />
          </el-form-item>

          <!-- Initial stock -->
          <el-form-item
            v-if="
              shouldDisplayInitialStock &&
              form.type !== productType.PURCHASE_ITEM
            "
            :label="
              $t(
                'pages.products.edit.form.sections.standard.initial_stock.label'
              )
            "
          >
            <el-input-number
              v-model="form._initialStock"
              data-testid="initial_stock"
              :min="0"
              :disabled="isServiceProduct"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.standard.initial_stock.placeholder'
                )
              "
              class="w-full"
            />
          </el-form-item>
        </el-col>

        <el-col v-if="form.type !== productType.PURCHASE_ITEM" :md="6">
          <!-- Image -->
          <el-form-item :label="$t('common.forms.labels.image')" prop="images">
            <th-image-upload-v2
              v-model="form.images"
              show-long-size-info
              data-testid="images"
              resource="products"
              image-key="1x"
              fill-type="contain"
              height="166px"
              icon="PictureRounded"
              :title="
                $t(
                  'pages.products.edit.form.sections.standard.images.add_image'
                )
              "
            />
          </el-form-item>

          <!-- Barcode Viewer -->
          <el-form-item
            :label="
              $t(
                'pages.products.edit.form.sections.standard.barcode_image.label'
              )
            "
          >
            <barcode-viewer v-model="firstBarcode" />
          </el-form-item>
        </el-col>

        <el-col v-else :md="6">
          <purchase-item
            v-model="form"
            :should-display-initial-stock="shouldDisplayInitialStock"
            :is-service-product="isServiceProduct"
          />
        </el-col>
      </el-row>
    </div>
  </th-wrapper>
</template>

<script>
import cloneDeep from 'clone-deep'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import ProductPrice from './standard/product-price.vue'
import PurchaseItem from './standard/purchase-item.vue'
import BarcodesInput from '@components/inputs/barcodes-input'
import BarcodeViewer from '@/components/barcodes/barcode-viewer'
import { productType, isPurchaseItem } from '@/utils/products'

export default {
  components: {
    ProductPrice,
    PurchaseItem,
    BarcodesInput,
    BarcodeViewer
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    resources: {
      type: Object,
      default: () => {}
    },
    isNew: {
      type: Boolean,
      default: false
    },
    autoGenerateId: {
      type: Boolean,
      default: false
    },
    isServiceProduct: {
      type: Boolean,
      default: false
    },
    computedProductTotals: {
      type: Object,
      default: null
    }
  },

  data: function () {
    return {
      productType: productType,
      productTypes: {
        product: this.$t('pages.products.edit.form.types.product'),
        composed_product: this.$t(
          'pages.products.edit.form.types.composed_product'
        ),
        purchase_item: this.$t('pages.products.edit.form.types.purchase_item'),
        voucher: this.$t('pages.products.edit.form.types.voucher'),
        linked: this.$t('pages.products.edit.form.types.linked'),
        linked_product: this.$t(
          'pages.products.edit.form.types.linked_product'
        ),
        variant: this.$t('pages.products.edit.form.types.variant'),
        variant_product: this.$t(
          'pages.products.edit.form.types.variant_product'
        )
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    zeroInputAllowed: {
      get() {
        return safeGet(
          this.form,
          'configuration.pricing.zero_input_allowed',
          false
        )
      },
      set(value) {
        const configuration = cloneDeep(this.form.configuration || {})
        safeSet(configuration, 'pricing.zero_input_allowed', value)
        this.$emit('update:modelValue', { ...this.form, configuration })
      }
    },
    requestInput: {
      get() {
        return safeGet(this.form, 'configuration.pricing.request_input', false)
      },
      set(value) {
        const configuration = cloneDeep(this.form.configuration || {})
        safeSet(configuration, 'pricing.request_input', value)
        this.$emit('update:modelValue', { ...this.form, configuration })
      }
    },
    title() {
      if (isPurchaseItem(this.form)) {
        return this.productTypes.purchase_item
      }
      return this.productTypes[this.form.type]
    },

    shouldDisplayInitialStock() {
      return (
        this.isNew &&
        this.hasOneBranch &&
        !this.modelValue?.children?.length &&
        this.modelValue.type !== productType.COMPOSED_PRODUCT
      )
    },

    hasOneBranch() {
      return this.resources.branches?.length === 1
    },

    hasParent() {
      return !!this.form.parent
    },

    firstBarcode() {
      return this.form.barcodes?.[0]
    }
  },

  watch: {
    'modelValue.children': {
      deep: true,
      handler() {
        // When we set some initial stock in a variant, we set the parent to stockable
        const isAlreadyStockable = this.modelValue.stockable
        const hasChildWithStock = !!this.modelValue.children?.find(
          (child) => child.initial_stock
        )
        if (!isAlreadyStockable && hasChildWithStock) {
          const newInput = {
            ...this.modelValue,
            stockable: true
          }
          this.$emit('update:modelValue', newInput)
        }
      }
    }
  },

  methods: {
    changeProductGroup(productUUID) {
      // Skip if select is cleared
      if (!productUUID) {
        this.onProductGroupClear()
        return
      }

      // Find product group
      const productGroup = this.resources.product_groups.find(
        (p) => p.id === productUUID
      )

      if (!productGroup) {
        // If product group cannot be found fallback
        this.form.product_group = productUUID
        return
      }

      if (!this.form.takeaway_tax && productGroup.takeaway_tax) {
        this.form.takeaway_tax = productGroup.takeaway_tax
      }

      if (!this.form.takeaway_account && productGroup.takeaway_account) {
        this.form.takeaway_account = productGroup.takeaway_account
      }

      if (!this.form.tax && !this.form.account) {
        // Skip confirm modal if no tax or account set yet
        this.form.product_group = productUUID
        this.form.tax = productGroup.tax
        this.form.account = productGroup.account
        return
      }

      // Tell the user tax and account may be changed
      this.$confirm(
        this.$t(
          'pages.products.edit.form.properties.product_group.action.override.message'
        ),
        this.$t(
          'pages.products.edit.form.properties.product_group.action.override.label'
        ),
        {
          confirmButtonText: this.$t('common.interactions.buttons.ok'),
          cancelButtonText: this.$t('common.interactions.buttons.no'),
          type: 'warning'
        }
      )
        .then(() => {
          this.form.tax = productGroup.tax
          this.form.account = productGroup.account
        })
        .finally(() => {
          this.form.product_group = productUUID
          this.$refs.productGroupSelect.blur()
        })
    },

    goToParent() {
      this.$router.push({
        name: 'products-edit',
        params: { id: this.form.parent }
      })
    },

    onProductGroupClear() {
      if (!this.form.tax && !this.form.account) {
        // If no tax or account is present
        this.form.product_group = null
      }

      // Ask if tax or account is set
      this.$confirm(
        this.$t(
          'pages.products.edit.form.properties.product_group.action.clear.message'
        ),
        this.$t(
          'pages.products.edit.form.properties.product_group.action.clear.label'
        ),
        {
          confirmButtonText: this.$t('common.interactions.buttons.ok'),
          cancelButtonText: this.$t('common.interactions.buttons.no'),
          type: 'warning'
        }
      )
        .then(() => {
          this.form.product_group = null
          this.form.tax = null
          this.form.account = null
        })
        .catch(() => {
          this.form.product_group = null
        })
    }
  }
}
</script>
