<template>
  <th
    :class="{
      'cursor-pointer': isSortable,
      ascending: isAscending,
      descending: isDecending
    }"
    @click="$emit('click')"
  >
    {{ label }}
    <span
      v-if="isSortable"
      class="caret-wrapper inline-flex flex-col items-center align-middle relative"
    >
      <i class="sort-caret descending w-0 h-0 absolute" />
      <i class="sort-caret ascending w-0 h-0 absolute" />
    </span>
  </th>
</template>

<script>
export default {
  name: 'TableHeaderCell',
  props: {
    label: {
      type: String,
      default: ''
    },
    isSortable: {
      type: Boolean,
      default: false
    },
    isAscending: {
      type: Boolean,
      default: false
    },
    isDecending: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
/* Design as in element-ui table component */
.caret-wrapper {
  height: 34px;
  width: 24px;
}
.sort-caret {
  border: 5px solid transparent;
}
.sort-caret.ascending {
  border-bottom-color: var(--border-color-hover);
  top: 5px;
}
.sort-caret.descending {
  border-top-color: var(--border-color-hover);
  bottom: 7px;
}
.ascending .sort-caret.ascending {
  border-bottom-color: var(--border-color-focus);
}

.descending .sort-caret.descending {
  border-top-color: var(--border-color-focus);
}
</style>
