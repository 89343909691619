<template>
  <tr class="border-b align-middle">
    <!-- Attributes -->
    <td>
      <span
        v-for="(attributeValue, attributeKey) in form.attributes"
        :key="attributeKey"
        class="px-2 py-0"
      >
        {{ attributeValue }}
      </span>
    </td>

    <!-- Name -->
    <td>
      <el-form :model="form" :rules="rules">
        <el-form-item prop="name" class="mb-0">
          <el-input
            v-model="form.name"
            :placeholder="
              $t(
                'pages.products.edit.form.sections.standard.product_name.placeholder'
              )
            "
            data-testid="variant-row-name"
            @input="updateVariant('name', $event)"
          />
        </el-form-item>
      </el-form>
    </td>

    <!-- Product nubmer aka Custom ID -->
    <td v-if="!autoGenerateId && additionalParameters['custom_id'].selected">
      <el-form v-if="isNew" :model="form" :rules="rules">
        <el-form-item prop="custom_id" class="mb-0">
          <el-input
            v-model="form.custom_id"
            data-testid="variant-row-custom_id"
            :placeholder="
              $t(
                'pages.products.edit.form.sections.standard.custom_id.placeholder'
              )
            "
          />
        </el-form-item>
      </el-form>
      <span v-else>{{ form.custom_id }}</span>
    </td>

    <!-- Price -->
    <td v-show="additionalParameters.price.selected">
      <el-form-item prop="price" class="mb-0">
        <th-currency-input
          size="large"
          data-testid="variant-row-price"
          clearable-is-left
          :model-value="variantGrossPrice"
          @update:modelValue="updateVariantPrice"
        />
      </el-form-item>
    </td>

    <!-- Barcodes -->
    <td v-show="additionalParameters.barcodes.selected">
      <barcodes-input
        id="barcodes"
        data-testid="variant-row-barcode"
        :model-value="form.barcodes"
        @update:modelValue="updateVariant('barcodes', $event)"
      />
    </td>

    <!-- Product group -->
    <td v-show="isNew && additionalParameters['product_group'].selected">
      <el-select
        v-model="form.product_group"
        v-cancel-read-only
        clearable
        filterable
        class="w-full"
        data-testid="variant-row-product_group"
        :placeholder="$t('common.interactions.buttons.select')"
        @change="changeProductGroup"
      >
        <el-option
          v-for="item in resources.product_groups"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </td>

    <!-- Tax -->
    <td v-show="isNew && additionalParameters['tax'].selected">
      <el-select
        v-model="form.tax"
        v-cancel-read-only
        filterable
        clearable
        class="w-full"
        data-testid="variant-row-tax"
        :placeholder="$t('common.interactions.buttons.select')"
      >
        <el-option
          v-for="item in resources.taxes"
          :key="item.id"
          :label="item.computed_name"
          :value="item.id"
        />
      </el-select>
    </td>

    <!-- Revenue account -->
    <td v-show="isNew && additionalParameters['account'].selected">
      <el-select
        v-model="form.account"
        v-cancel-read-only
        filterable
        clearable
        class="w-full"
        data-testid="variant-row-account"
        :placeholder="$t('common.interactions.buttons.select')"
      >
        <el-option
          v-for="item in resources.accounts"
          :key="item.id"
          :label="item.computed_name"
          :value="item.id"
        />
      </el-select>
    </td>

    <!-- Initial stock -->
    <td v-show="showInitialStock">
      <el-form-item prop="initial_stock" class="mb-0">
        <el-input-number
          v-model="form.initial_stock"
          :min="0"
          :placeholder="
            $t(
              'pages.products.edit.form.sections.standard.initial_stock.placeholder'
            )
          "
          class="w-full"
          data-testid="variant-row-initial_stock"
        />
      </el-form-item>
    </td>

    <!-- Actions -->
    <td class="whitespace-no-wrap text-right">
      <!-- Delete -->
      <el-button
        v-if="!form.id"
        icon="Delete"
        plain
        data-testid="variant-row-delete"
        class="el-button--text-icon"
        @click="deleteChild"
      />

      <!-- Go to child -->
      <el-button
        v-else
        icon="Right"
        class="el-button--text-icon"
        plain
        @click="goToChild"
      />
    </td>
  </tr>
</template>

<script>
import safeGet from 'just-safe-get'
import BarcodesInput from '@components/inputs/barcodes-input'

export default {
  components: { BarcodesInput },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    children: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    additionalParameters: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      required: true
    },
    autoGenerateId: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          }
        ],
        custom_id: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          }
        ]
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },

      set(val) {
        this.$emit('update:modelValue', val)
      }
    },

    isNew() {
      return !this.$route.params.id
    },

    defaultPrice() {
      const defaultPrice = safeGet(this.form, 'prices.default_prices', [])
      return defaultPrice[0]
    },

    variantGrossPrice() {
      return this.defaultPrice?.amount?.gross
    },

    tax() {
      return (
        this.resources.taxes.find((t) => t.id === this.form.tax) ||
        this.resources.taxes[0]
      ).rate
    },

    showInitialStock() {
      return (
        this.isNew &&
        this.additionalParameters['initial_stock']?.selected &&
        this.hasOneBranch
      )
    },

    hasOneBranch() {
      return this.resources?.branches?.length === 1
    }
  },

  methods: {
    deleteChild() {
      this.$emit('delete')
    },

    goToChild() {
      this.$router.push({
        name: 'products-edit',
        params: { id: this.form.id }
      })
    },

    changeProductGroup(productUUID) {
      // Skip if select is cleared
      if (!productUUID) {
        this.form.tax = null
        this.form.account = null
        return
      }

      // Find product group
      const productGroup = this.resources.product_groups.find(
        (p) => p.id === productUUID
      )

      // Set tax and account from group
      if (productGroup) {
        this.form.tax = productGroup.tax
        this.form.account = productGroup.account
      }
    },
    updateVariantPrice(newGrossPrice) {
      const prices = this.form.prices

      if (prices.default_prices[0].amount.gross === newGrossPrice) return

      prices.default_prices[0].amount.gross = newGrossPrice
      this.updateVariant('prices', prices)
    },
    updateVariant(attrName, value) {
      this.form[attrName] = value
      this.$emit('update:modelValue', this.form)
    }
  }
}
</script>
