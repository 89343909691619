<template>
  <th-wrapper collapsable @collapsed-changed="handleCollapsedChanged">
    <!-- Title -->
    <template #title>
      <div>
        {{ $t('pages.products.edit.form.sections.additional.title') }}
      </div>
    </template>

    <!-- Center actions -->
    <template #center>
      <el-checkbox v-model="form.discountable">
        {{
          $t('pages.products.edit.form.sections.additional.discountable.label')
        }}
      </el-checkbox>
    </template>

    <!-- Body -->
    <div class="-mb-5">
      <el-row :gutter="20">
        <el-col :md="16" :lg="18">
          <el-row :gutter="20">
            <el-col :md="16">
              <!-- Short description (max 100 chars) -->
              <el-form-item
                prop="summary"
                :label="
                  $t(
                    'pages.products.edit.form.sections.additional.summary.label'
                  )
                "
              >
                <el-input
                  id="summary"
                  v-model="form.summary"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.additional.summary.placeholder'
                    )
                  "
                  data-lpignore="true"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  resize="horisontal"
                />
              </el-form-item>
            </el-col>

            <el-col :md="8">
              <!-- External reference -->
              <el-form-item
                prop="external_reference_id"
                :label="
                  $t(
                    'pages.products.edit.form.sections.additional.external_reference_id.label'
                  )
                "
              >
                <el-input
                  id="external_reference_id"
                  v-model="form.external_reference_id"
                  data-testid="external-reference-id"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.additional.external_reference_id.placeholder'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Long description (max 1000 chars) -->
          <el-form-item
            prop="description"
            :label="
              $t(
                'pages.products.edit.form.sections.additional.description.label'
              )
            "
          >
            <el-input
              id="description"
              v-model="form.description"
              type="textarea"
              :rows="3"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.additional.description.placeholder'
                )
              "
              resize="horisontal"
              data-lpignore="true"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
            />
          </el-form-item>

          <el-row :gutter="20">
            <el-col :md="16">
              <!-- Manufacturer -->
              <el-form-item
                prop="manufacturer"
                :label="
                  $t(
                    'pages.products.edit.form.sections.additional.manufacturer.label'
                  )
                "
              >
                <el-input
                  id="manufacturer"
                  v-model="manufacturer"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.additional.manufacturer.placeholder'
                    )
                  "
                />
              </el-form-item>
            </el-col>

            <el-col :md="8">
              <!-- SKU -->
              <el-form-item
                prop="sku"
                :label="
                  $t('pages.products.edit.form.sections.additional.sku.label')
                "
              >
                <el-input
                  id="sku"
                  v-model="form.sku"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.additional.sku.placeholder'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :md="16">
              <!-- Supplier new -->
              <el-form-item
                v-if="$isFeatureEnabled('suppliers')"
                prop="productSuppliers"
                :label="$t('common.resource.supplier.singular')"
              >
                <product-suppliers
                  id="productSuppliers"
                  ref="productSuppliers"
                  show-supplier-create-option
                  :propagate-to-variants="propagateToVariants"
                  :product-id="productId"
                  :resources="resources"
                />
              </el-form-item>
              <!-- Supplier old -->
              <el-form-item
                prop="supplier"
                :label="
                  $t(
                    'pages.products.edit.form.sections.additional.supplier.label'
                  )
                "
              >
                <el-input
                  id="supplier"
                  v-model="supplier"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.additional.supplier.placeholder'
                    )
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <!-- Brand -->
              <el-form-item
                prop="brand"
                :label="
                  $t('pages.products.edit.form.sections.additional.brand.label')
                "
              >
                <el-input
                  id="brand"
                  v-model="form.brand"
                  :placeholder="
                    $t(
                      'pages.products.edit.form.sections.additional.brand.placeholder'
                    )
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :md="13" :lg="9">
              <!-- Tags -->
              <el-form-item
                prop="tags"
                :label="
                  $t('pages.products.edit.form.sections.additional.tags.label')
                "
              >
                <product-tags
                  id="tags"
                  ref="productTags"
                  v-model="form.tags"
                  :resources="resources"
                  :allow-create="canCreateTags"
                  @new-tags-created="$emit('new-tags-created')"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Attributes -->
          <el-form-item
            v-if="form.type !== productType.VARIANT_PRODUCT"
            prop="attributes"
          >
            <attributes
              id="attributes"
              ref="attributes"
              v-model="form.attributes"
            />
          </el-form-item>
        </el-col>

        <el-col :md="8" :lg="6">
          <!-- Tile colours for Till -->
          <el-form-item
            prop="default_tile_color"
            :label="
              $t(
                'pages.products.edit.form.sections.additional.default_tile_color.label'
              )
            "
          >
            <div
              class="default-tile-color-wrap grid gap-4 w-full p-4 rounded border border-gray-400 grid-cols-4"
            >
              <button
                v-for="(color, index) in availableColors"
                :key="color"
                :data-testid="'tile-color-' + index"
                :class="{ active: form.default_tile_color === color }"
                :style="{ background: color }"
                class="tile-color w-fill h-full rounded border border-gray-400 relative"
                native-type="button"
                @click.prevent="onSelectedColor(color)"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="9">
          <!-- Locations -->
          <el-form-item
            for="locations"
            :label="
              $t('pages.products.edit.form.properties.available_in.label')
            "
          >
            <available-in
              id="locations"
              :model-value="{
                locations: form.locations,
                branch_groups: form.branch_groups
              }"
              :resources="resources"
              :show-items-limit="2"
              @update:modelValue="handleAvailableInInput"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import Attributes from './additional-information/attributes'
import ProductTags from './additional-information/product-tags'
import ProductSuppliers from './additional-information/product-suppliers'
import AvailableIn from '@/components/available-in'
import { productType } from '@/utils/products'

export default {
  components: {
    Attributes,
    ProductTags,
    ProductSuppliers,
    AvailableIn
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    isNew: {
      type: Boolean,
      default: false
    },
    autoGenerateId: {
      type: Boolean,
      default: false
    },
    productId: {
      type: String,
      default: null
    },
    propagateToVariants: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      productType: productType,
      availableColors: [
        '#e67482',
        '#f7e7d6',
        '#f7ed73',
        '#d0eeb2',
        '#c7e4f9',
        '#79c1f6',
        '#b86dfa',
        '#232e3d'
      ]
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },

    supplier: {
      get() {
        return safeGet(this.form, 'supplier.sku')
      },
      set(value) {
        this.form.supplier = { ...this.form.supplier, sku: value || null }
      }
    },

    manufacturer: {
      get() {
        return safeGet(this.form, 'manufacturer.iln')
      },
      set(value) {
        this.form.manufacturer = {
          ...this.form.manufacturer,
          iln: value || null
        }
      }
    },

    canCreateTags() {
      return this.$checkPermissions({ scopes: ['products:tags:create'] })
    }
  },

  methods: {
    handleAutoGenerateChange(newValue) {
      this.$emit('auto-generate-change', newValue)
    },

    onSelectedColor(color) {
      if (this.form.default_tile_color === color) {
        // Remove tile color if same is clicked
        this.form.default_tile_color = null
      } else {
        // Set color
        this.form.default_tile_color = color
      }
    },
    handleAvailableInInput(value) {
      this.form.locations = value.locations
      this.form.branch_groups = value.branch_groups
    },
    async createNewTags() {
      return this.$refs.productTags.createNewTags()
    },
    async validate() {
      return this.$refs.attributes
        ? await this.$refs.attributes.validate()
        : true
    },
    async saveLinkedSupplier(productId) {
      return this.$refs.productSuppliers?.saveLinkedSupplier
        ? await this.$refs.productSuppliers?.saveLinkedSupplier(productId)
        : true
    },
    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.additional.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>

<style scoped>
.default-tile-color-wrap {
  height: 190px;
}

.default-tile-color-wrap .tile-color:hover {
  border-color: var(--border-color-hover);
}

.default-tile-color-wrap .tile-color:active {
  border-color: var(--border-color-focus);
}

.default-tile-color-wrap .tile-color.active::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  border: 3px solid var(--primary-color);
}
</style>
