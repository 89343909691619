<template>
  <th-wrapper
    collapsable
    collapsed-by-default
    data-testid="branch-customizations"
    :title="$t('pages.products.edit.form.sections.branch-customizations.title')"
    @collapsed-changed="handleCollapsedChanged"
  >
    <branch-customizations-row
      v-for="(customization, index) in localForm"
      ref="customEntries"
      :key="customization.id"
      :value="customization"
      :index="index"
      :used-branches-ids="usedBranchesIds"
      :used-branch-groups-ids="usedBranchGroupsIds"
      :is-last="index === localForm.length - 1"
      :is-new="!isExistingCustomization(customization)"
      :resources="resources"
      @input="updateCustomization(index, $event)"
      @add="addNewCustomization"
      @delete="removeCustomization"
    />
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import cloneDeep from 'clone-deep'
import pick from 'just-pick'
import compare from 'just-compare'
import BranchCustomizationsRow from './customizations/branch-customizations-row'

function generateDefault() {
  return {
    product: null,
    name: null,
    description: null,
    summary: null,
    default_tile_color: null,
    branches: undefined,
    branch_groups: undefined
  }
}

export default {
  components: { BranchCustomizationsRow },

  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      localForm: [],
      deletedCustomizations: []
    }
  },

  computed: {
    usedBranchesIds() {
      let branches = []
      this.localForm.forEach((customization) => {
        branches = branches.concat(customization.branches)
      })
      return branches
    },
    usedBranchGroupsIds() {
      let branch_groups = []
      this.localForm.forEach((customization) => {
        branch_groups = branch_groups.concat(customization.branch_groups)
      })
      return branch_groups
    },
    fields() {
      return Object.keys(generateDefault())
    }
  },

  watch: {
    modelValue() {
      this.localForm = cloneDeep(this.modelValue)
      //if localForm is empty array, we add a new customization placeholder
      if (!this.localForm.length) {
        this.addNewCustomization()
      }
    }
  },
  mounted() {
    this.addNewCustomization()
  },
  methods: {
    isExistingCustomization(customization) {
      return !!customization.id
    },
    addNewCustomization() {
      this.localForm.push(generateDefault())
    },
    async removeCustomization(index) {
      const customization = this.localForm[index]
      if (this.isExistingCustomization(customization)) {
        //if Customization needs to be deleted via the API, we save it temporary in deleteEntries until execution
        this.deletedCustomizations.push(customization)
      }
      this.localForm.splice(index, 1)

      //if localForm is empty array, we add a new customization placeholder
      if (!this.localForm.length) {
        this.addNewCustomization()
      }
    },
    async save(productId) {
      //delete entries
      const deletedCustomizations = this.deletedCustomizations.map(
        this.deleteCustomization
      )
      const savedCustomizations = this.localForm.map(async (customization) => {
        if (
          (!customization.branches?.length &&
            !customization.branch_groups?.length) ||
          (!customization.name &&
            !customization.summary &&
            !customization.description &&
            !customization.default_tile_color)
        ) {
          return
        }
        //update entries
        if (this.isExistingCustomization(customization)) {
          return this.updateCustomization(customization)
        }
        //create entries
        return this.createCustomization(productId, customization)
      })

      return Promise.all([...deletedCustomizations, ...savedCustomizations])
    },
    async createCustomization(productId, customization) {
      try {
        return await th
          .productBranchCustomizations()
          .create({ ...customization, product: productId })
      } catch (error) {
        this.$logException(error)
      }
    },
    async updateCustomization(customization) {
      try {
        return await th.productBranchCustomizations().put(customization.id, {
          ...pick(customization, this.fields),
          product: this.modelValue.id
        })
      } catch (error) {
        this.$logException(error)
      }
    },
    async deleteCustomization(customization) {
      try {
        return await th.productBranchCustomizations().delete(customization.id)
      } catch (error) {
        this.$logException(error)
      }
    },
    getDirtyCustomizations() {
      return this.localForm.filter(
        (customization) => !compare(customization, generateDefault())
      )
    },
    async validate() {
      if (!this.getDirtyCustomizations().length) return true
      const results = await Promise.all(
        this.$refs.customEntries.map((customization) =>
          customization.validate()
        )
      )
      return !results.some((item) => item === false)
    },
    handleCollapsedChanged(collapsed) {
      this.$ampli.eventWithBaseProps('productSectionToggled', {
        section_name: this.$t(
          'pages.products.edit.form.sections.branch-customizations.title',
          'en'
        ),
        section_collapsed: collapsed
      })
    }
  }
}
</script>
