<template>
  <div class="flex items-center" data-testid="product-type-selector">
    <th-popover
      :text="$t('pages.products.edit.common.type_selector.help')"
      class="mr-2"
    />

    <div
      v-for="(nameKey, key) of productTypes"
      :key="key"
      class="item"
      :class="{ active: type === key }"
    >
      <input
        :id="key"
        v-model="type"
        type="radio"
        name="product-type"
        class="hidden"
        :value="key"
        @input="trackTabChange(nameKey)"
      />
      <label
        :for="key"
        class="block cursor-pointer px-4 py-1 text-sm border-transparent"
      >
        {{ $t(nameKey) }}
      </label>
    </div>
  </div>
</template>

<script>
import { productType } from '@/utils/products'

export default {
  props: {
    modelValue: {
      type: String,
      default: productType.PRODUCT
    }
  },

  data() {
    return {
      productTypes: {
        product: 'pages.products.edit.form.types.product',
        variant_product: 'pages.products.edit.form.types.variant_product',
        composed_product: 'pages.products.edit.form.types.composed_product',
        purchase_item: 'pages.products.edit.form.types.purchase_item'
      }
    }
  },

  computed: {
    type: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },

  methods: {
    trackTabChange(key) {
      this.$ampli.eventWithBaseProps('productTypeTabClick', {
        product_type_tab_name: this.$t(key, 'en')
      })
    }
  }
}
</script>

<style scoped>
.item label {
  border-bottom-width: 3px;
  letter-spacing: var(--letter-spacing-titles);
}

.item.active label {
  font-weight: bold;
  color: var(--tab-nav-color);
  border-color: var(--tab-nav-color) !important;
  line-height: var(--el-line-height);
}
</style>
