<template>
  <el-container class="variants-template-modal">
    <el-header class="h-auto p-8 pb-0">
      <h2 class="m-0">
        {{
          $t(
            'pages.products.edit.form.sections.variants.modal.load_template.title'
          )
        }}
      </h2>
    </el-header>

    <el-main class="px-8 pb-4">
      <el-form-item
        :label="
          $t(
            'pages.products.edit.form.sections.variants.modal.load_template.template.label'
          )
        "
        class="mb-4"
      >
        <el-dropdown
          v-if="productTemplates"
          type="primary"
          @command="handleSelectTemplate"
        >
          <el-button type="primary">
            <span>{{ selectedTemplate.name }}</span>
            <el-icon class="el-icon--right"><ArrowDown /></el-icon>
          </el-button>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="template in productTemplates"
                :key="template.id"
                :command="template"
              >
                {{ template.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-form-item>

      <div v-if="selectedTemplate">
        <div
          v-for="(options, optionKey) in selectedTemplate.option_template"
          :key="optionKey"
          class="mb-2"
        >
          <div class="mb-2 text-sm text-gray-700">{{ optionKey }}</div>
          <el-tag
            v-for="(value, valueIndex) in options"
            :key="valueIndex"
            class="mr-2 mb-2"
          >
            {{ value }}
          </el-tag>
        </div>
      </div>
    </el-main>

    <el-footer class="flex justify-end items-center border-gray-300">
      <el-button plain @click="handleCancel">
        {{ $t('common.interactions.buttons.cancel') }}
      </el-button>
      <el-button type="primary" @click="handleApply">
        {{ $t('common.interactions.buttons.apply') }}
      </el-button>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  props: {
    productTemplates: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedTemplate: this.productTemplates[0]
    }
  },

  methods: {
    handleSelectTemplate(newTemplate) {
      this.selectedTemplate = newTemplate
    },

    handleCancel() {
      this.$emit('cancel')
    },

    handleApply() {
      this.$emit('apply', this.selectedTemplate)
    }
  }
}
</script>

<style scoped>
.variants-template-modal {
  max-height: 80vh;
}
</style>
