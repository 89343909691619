<template>
  <el-select
    v-model="tags"
    v-cancel-read-only
    class="w-full"
    filterable
    clearable
    multiple
    default-first-option
    :allow-create="allowCreate"
    :placeholder="
      $t('pages.products.edit.form.sections.additional.tags.placeholder')
    "
    @change="addTags"
  >
    <el-option
      v-for="tag in resources.tags"
      :key="tag.id"
      :label="tag.name"
      :value="tag.id"
    />
  </el-select>
</template>

<script>
import th from '@tillhub/javascript-sdk'

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    allowCreate: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tags: {
      get() {
        return this.modelValue || []
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },

  methods: {
    addTags(tags) {
      const selectedTags = tags
        .map((tag) => tag.trim()) //remove whitespaces
        .filter(
          (tag) =>
            !this.resources.tags.find(
              ({ name }) => name.toLowerCase() === tag.toLowerCase() //remove tags with already used label (case insensitive)
            )
        )
      this.tags = [...new Set(selectedTags)] //remove duplicate tags
    },
    async createNewTags() {
      if (!this.allowCreate) return true
      try {
        let shouldReFetchTags = false //reFetch Tags in resources
        const tags = await this.tags.map(async (tag) => {
          const isNewTag = !this.resources.tags.find(({ id }) => id === tag)
          if (isNewTag) {
            //create a new tag and return the id
            shouldReFetchTags = true
            const { data = {} } = await th.tags().create({ name: tag })
            return data.id
          }
          return tag
        })
        this.tags = await Promise.all(tags)
        if (shouldReFetchTags) this.$emit('new-tags-created')

        return true
      } catch (error) {
        this.$logException(error, { trackError: false })
        return false
      }
    }
  }
}
</script>
